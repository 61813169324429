export const CopyIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 11.1667V13.6667C14.1667 17 12.8334 18.3334 9.50008 18.3334H6.33341C3.00008 18.3334 1.66675 17 1.66675 13.6667V10.5C1.66675 7.16671 3.00008 5.83337 6.33341 5.83337H8.83341"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1666 11.1667H11.4999C9.49992 11.1667 8.83325 10.5 8.83325 8.50004V5.83337L14.1666 11.1667Z"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66675 1.66663H13.0001"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 4.16663C5.83325 2.78329 6.94992 1.66663 8.33325 1.66663H10.5166"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 6.66663V11.825C18.3334 13.1166 17.2834 14.1666 15.9917 14.1666"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 6.66663H15.8333C13.9583 6.66663 13.3333 6.04163 13.3333 4.16663V1.66663L18.3333 6.66663Z"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
