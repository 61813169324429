import { SvgIcon } from "@mui/material";

export const SendIcon = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      onClick={onClick}
      {...props}
    >
      <path
        d="m8.633 7.373 9.905-3.301c4.445-1.482 6.86.945 5.39 5.39l-3.302 9.905c-2.216 6.661-5.856 6.661-8.073 0l-.98-2.94-2.94-.98c-6.662-2.217-6.662-5.845 0-8.074m3.162 8.552 4.177-4.188"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#7F56D9"
      />
    </SvgIcon>
  );
};
