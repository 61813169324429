export const getRegistrationFields = (userData) => [
  {
    type: "text",
    name: "first_name",
    label: "First Name",
  },
  {
    type: "text",
    name: "last_name",
    label: "Last Name",
  },
  {
    type: "password",
    name: "password",
    label: "Password",
  },
  {
    type: "password",
    name: "confirm_password",
    label: "Confirm Password",
  },
  {
    type: "text",
    name: "email",
    label: "Email",
  },
];

export const DEFAULT_USER_DATA = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  is_confirmed: false,
};

export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;
