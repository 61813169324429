import { useEffect } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';

const SelectButton = styled(Button)(
  ({selected})=> ({
    width: '220px',
    marginBottom: '20px',
    background: selected ? '#2984FF' : '#F1F5F9',
    color: selected ? '#fff' : '#163B65'
  })
)

export function SelectMethod({
  open,
  handleClose,
  selectedMethod,
  setSelectedMethod,
  handleSave,
  selected
}) {

  useEffect(() => {
    setSelectedMethod(selected.methods[0])
  }, [selected])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth >
      <DialogTitle>
        Compare Groups
      </DialogTitle>
      <DialogContent sx={{display: 'flex'}}>
        <Box sx={{width: '50%', marginTop: '5px'}}>
          {selected.methods.map(item => (
            <SelectButton
              key={item.value}
              variant="contained"
              onClick={() => setSelectedMethod(item)}
              selected={selectedMethod.name === item.name}
            >
              {item.name}
            </SelectButton>
          ))}
        </Box>
        <Box sx={{width: '50%'}}>
          <Typography variant="h5">{selectedMethod?.name}</Typography>
          <Typography variant="body1">{selectedMethod?.description}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size={'large'} color='info' onClick={handleClose} >Cancel</Button>
        <Button variant="contained" color="secondary" onClick={handleSave} >Done</Button>
      </DialogActions>
    </Dialog>
  )
}