import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

export const PageTitle = ({ title }) => {
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.setItem('session_token', '');
    navigate('/');
  }

  return (
    <Fragment>
      <Typography
        color="textPrimary"
        variant="h4"
        flex="0 1 auto"
      >
        {title}
      </Typography>
      <div style={{width: '130px', position: 'absolute', right: '30px', top: '15px'}}>
        <Button variant="outlined" color="secondary" onClick={() => logOut()}>Log out</Button>
      </div>
    </Fragment>
  )
}