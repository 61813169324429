import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect } from "react";

import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { GenieIcon } from "../../../components/genie-icon";

const DragableVaribleWrapper = styled(Box)(({ selected }) => ({
  width: "155px",
  height: "35px",
  background: selected ? "#2984FF " : "#fff",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "0 15px 0 15px",
  margin: "12px 0",
  cursor: "pointer",
}));

const VaribleName = styled(Typography)(({ selected }) => ({
  fontSize: "14px",
  fontWeight: 400,
  color: selected ? "#fff" : "#3B4250",
  maxWidth: "80%",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginLeft: "10px",
}));

export function DragableVarible({
  varible,
  selected,
  handleItemSelection,
  deleteVarible,
}) {
  const [, drag, preview] = useDrag(
    () => ({
      type: "varible",
      item: { varible, deleteVarible: deleteVarible },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [varible]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = selected?.find(
    (item) => item.variable_name === varible.variable_name
  );

  return (
    <DragableVaribleWrapper
      ref={drag}
      selected={!!select}
      onClick={() => handleItemSelection(varible)}
    >
      <GenieIcon
        type={varible.variable_type}
        iconWidth="20px"
        iconHeight="17px"
        file_type={".jpeg"}
      />
      <VaribleName selected={!!select}>{varible.variable_name}</VaribleName>
    </DragableVaribleWrapper>
  );
}
