import { Box, ListItem, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NavbarItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "isEditing",
})(({ active, isEditing }) => ({
  color: active || isEditing ? "#101828" : "#525866",
  fontWeight: 400,
  maxWidth: "100%",
  width: "100%",
  fontSize: "14px",
  padding: "8px 12px",
  borderRadius: "8px",
  background: active || isEditing ? "#F6F8FA" : "#ffff",
  gap: "8px",
  lineHeight: "20px",
  cursor: "pointer",
  transition: "0.3s",
  overflow: "hidden",
  height: "36px",
  minHeight: "36px",

  "&:hover": {
    background: "#F6F8FA",
  },

  "& svg path": {
    transition: "0.3s",
  },

  "& p": {
    maxWidth: "100%",
    width: "100%",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    fontWeight: 400,
  },
}));

export const StylesInput = styled(TextField)(() => ({
  width: "100%",
  background: "#F6F8FA",

  "& .MuiOutlinedInput-input": {
    padding: "0px !important",
    height: "35px",
    color: "#101828 !important",
    fontSize: "14px !important",
    fontWeight: 400,
    border: "none",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
}));

export const IconWrapper = styled(Box)(() => ({
  position: "absolute",
  right: "10px",
  height: "24px",

  "& svg": {
    width: "20px",
  },

  "& :first-of-type": {
    marginRight: "3px",
  },
}));

export const ActiveChart = styled(Box)(() => ({
  position: "absolute",
  width: "4px",
  height: "20px",
  background: "#7F56D9",
  borderRadius: "0px 4px 4px 0px",
  left: 0,
}));

export const PurpleBox = styled(Box)(() => ({
  width: "12px",
  height: "12px",
  minWidth: "12px",
  borderRadius: "50%",
  backgroundColor: "#6E3FF3",
  mr: "4px",
}));

export const LongText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }) => ({
  position: "relative",
  width: active ? "118px" : "162px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",

  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: "0px",
    width: "20px",
    height: "28px",
    background: `linear-gradient(to right, transparent, #041823)`,
    pointerEvents: "none",
  },
}));

export const IconShadowWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isHover" && prop !== "isChat" && prop !== "isEdit",
})(({ isHover, isChat, isEdit }) => ({
  right: 0,
  top: "0px",
  position: "absolute",
  backgroundColor: "#F6F8FA",
  width: isChat ? "70px" : "30px",
  height: "36px",
  display: "flex",
  gap: isEdit ? "2px" : "7px",
  alignItems: "center",
  justifyContent: "center",
  opacity: isHover ? 1 : 0,
  transition: "0.3s",

  "&::after": {
    content: '""',
    position: "absolute",
    right: isChat ? "60px" : "30px",
    bottom: "0px",
    width: isChat ? "55px" : "35px",
    height: "36px",
    background: `linear-gradient(to right, transparent, #F6F8FA)`,
    pointerEvents: "none",
  },
}));
