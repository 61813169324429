import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GenieIcon } from '../../../components/genie-icon';

import {
  Box,
  Typography,
  TextField
} from '@mui/material';
import { styled } from '@mui/system';

const VariblesContainer = styled(Box)(
  () => ({
    background: '#F1F5F9',
    border: '1px solid #EAEDF2',
    borderRadius: '12px',
    padding: '16px',
    width: '222px',
    maxHeight: '470px',
    overflow: 'hidden'
  })
)

const ListContainer = styled(Box)(
  () => ({
    overflowY: 'auto',
    maxHeight: '400px',
    marginTop: '5px',
  })
)

const VaribleWrapper = styled(Box)(
  () => ({
    height: '35px',
    background: '#fff',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 15px 0 15px',
    margin: '12px 0',
    cursor: 'pointer'
  })
)

const VaribleName = styled(Typography)(
  () => ({
    fontSize: '14px',
    fontWeight: 400,
    color: '#3B4250',
    maxWidth: '80%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: '10px'
  })
)

export function VaribleList({
  addItemToExpression,
  newVariableName,
  setNewVariableName
}) {
  const [varibles, setVaribles] = useState([]);
  const variblesData = useSelector(state => state.datasetsReducer.datasetVarTypes);

  useEffect(() => {
    if(!variblesData) return
    setVaribles(Object.keys(variblesData))
  }, [variblesData]);

  const addVariableToExpression = (variable) => {
    const expressionItem = {
      type: 'variable',
      value: variable.variable_name
    };
    addItemToExpression(expressionItem);
  }

  return (
    <VariblesContainer>
      <Typography margin={'0 0 16px 0'} fontSize={15} color={'#474551'} fontWeight={600}>Variable</Typography>
      <TextField
        fullWidth
        value={newVariableName}
        onChange={e => setNewVariableName(e.target.value)}
        color="secondary"
        size="small"
        label="New Variable Name"
      />
      <ListContainer >
        {varibles.map((varible, index) => (
          <DragableVarible
            key={'varible'+index}
            varible={variblesData[varible]}
            onClick={() => addVariableToExpression(variblesData[varible])}
          />
        ))}
      </ListContainer>
    </VariblesContainer>
  )
}

const DragableVarible = ({varible, onClick}) => {
  return (
    <VaribleWrapper onClick={onClick}>
      <GenieIcon type='dragable' iconWidth='20px' iconHeight="17px" />
      <VaribleName>{varible.variable_name}</VaribleName>
    </VaribleWrapper>
  )
}