import { useEffect, useState } from "react";

import { AnalyseGraph } from "../AnalyseGraph";

import { Box, Grid } from "@mui/material";

export const AnalyseGraphsContent = ({ data, analysisType, independent = false, currentTab }) => {
  const [tabs, setTabs] = useState([]);
  const compareTabTypes = ["dependent", "compare_groups", "survival", "multivariate_single"];

  useEffect(() => {
    if (data) {
      if (compareTabTypes.includes(analysisType)) setTabs(createCompareGroupsTabs(data, currentTab));

      if (analysisType === "correlations") setTabs(createCorrelationsTabs(data));

      if (analysisType === "mixed_multivariate" || analysisType === "multivariate")
        setTabs(createMultivariateGraphs(data, independent));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, independent, currentTab]);

  return (
    !!tabs.length && (
      <Grid
        sx={{
          p: "12px",
          maxHeight: "550px",
          overflowY: "auto",
          backgroundColor: "#F9FAFB",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>{tabs}</Box>
      </Grid>
    )
  );
};

const createMultivariateGraphs = (data, independent) => {
  let multivariateTabs = [];
  if (data?.all) {
    let keys = Object.keys(data.all.all_models);
    if (independent) {
      multivariateTabs.push(<AnalyseGraph data={data.all.main_independents} key={keys[0]} />);
    } else {
      keys.forEach((key, index) => {
        multivariateTabs.push(<AnalyseGraph data={data.all.all_models[key]} key={index} />);
      });
    }
  } else {
    let keys = Object.keys(data);
    keys.forEach((key, index) => {
      let keys2 = Object.keys(data[key].all_models);
      if (independent) {
        multivariateTabs.push(<AnalyseGraph data={data[key].main_independents} key={index} />);
      } else {
        keys2.forEach((key2, index2) => {
          multivariateTabs.push(<AnalyseGraph data={data[key].all_models[key2]} key={index2} />);
        });
      }
    });
  }
  return multivariateTabs;
};

const createCorrelationsTabs = (data) => {
  let tabs = [];
  if (data?.null) {
    return null;
  } else {
    let keys = Object.keys(data);
    keys.forEach((key) => {
      let keys2 = Object.keys(data[key]);
      keys2.forEach((key2, index) => {
        tabs.push(
          <AnalyseGraph
            key={index}
            data={key2 !== "heatmap" ? data[key][key2] : { heatmap: data[key].heatmap }}
          />
        );
      });
    });
  }
  return tabs;
};

const createCompareGroupsTabs = (data, tab) => {
  let tabs = [];
  if (data?.null) {
    return <AnalyseGraph data={tab !== "heatmap" ? data.all[tab] : { heatmap: data.all.heatmap }} />;
  } else {
    let keys = Object.keys(data);

    keys.forEach((key, index) => {
      tabs.push(
        <AnalyseGraph
          key={index}
          data={tab !== "heatmap" ? data[key][tab] : { heatmap: data[key].heatmap }}
        />
      );
    });
  }
  return tabs;
};
