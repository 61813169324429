import { Box, Typography, List, ListItem, ListItemButton} from "@mui/material";
import { styled } from '@mui/system';

const FunctionListContainer = styled(Box)(
  () => ({
    border: '1px solid #EAEDF2',
    borderRadius: '12px',
    padding: '16px',
  })
)

const functions = [
  {name: 'square', parameters: 1},
  {name: 'log', parameters: 1},
  {name: 'ln', parameters: 1},
  {name: 'sqrt', parameters: 1},
  {name: 'sum', parameters: 20},
  {name: 'min', parameters: 20},
  {name: 'max', parameters: 20},
  {name: 'mean', parameters: 20}
]

export function FunctionsList({addItemToExpression}) {

  const addFunctionToExpression = (item) => {
    const expressionItem = {
      type: 'function',
      value: item.name,
      parameters: [],
      parametersLength: item.parameters
    };
    addItemToExpression(expressionItem);
  }

  return(
    <FunctionListContainer>
      <Typography fontSize={15} color={'#474551'} fontWeight={600}>Functions</Typography>
      <List sx={{maxHeight: '273px', overflow: 'auto'}}>
        {functions.map(item => (
          <ListItem key={item.name} disablePadding onClick={() => addFunctionToExpression(item)}>
            <ListItemButton><Typography>{item.name}</Typography></ListItemButton>
          </ListItem>
        ))}
      </List>
    </FunctionListContainer>
  )
}