import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DataSetWrapper = styled(Stack)(() => ({
  marginTop: "auto",
  padding: "16px",
  background: "#F6F8FA",
  borderRadius: "12px",
  flexDirection: "column",
  alignItems: "center",
  gap: "12px",
  height: "fit-content",
  textAlign: "center",

  "& .MuiTypography-root": {
    fontWeight: 500,
    lineHeight: "20px",
  },
}));
