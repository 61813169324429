import { Box, SvgIcon } from "@mui/material";
import * as React from "react";
export const UploadIcon = (props) => (
  <Box
    sx={{
      height: "20px",
      width: "20px",
      p: "10px",
      background: "#219653",
      boxShadow: "0px 4px 20px rgba(33, 150, 83, 0.20)",
      borderRadius: "8px",
    }}
  >
    <SvgIcon
      style={{ width: "20px", height: "20px", fill: "#219653" }}
      viewBox="0 0 20 20"
      fill="#219653"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.667 13.333 10 10m0 0 3.334 3.333M10 10v7.5m6.667-3.548a4.583 4.583 0 0 0-2.917-8.12.52.52 0 0 1-.445-.25 6.25 6.25 0 1 0-9.816 7.58"
        stroke="#F9FAFB"
        strokeWidth={1.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  </Box>
);
