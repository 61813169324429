import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { setExportDialog } from "actions/chats";
import { GenieIcon } from "components/genie-icon";
import { EXPORT_TYPES } from "reducer/chats";
import { setLoading } from "actions/user";
import { toast } from "react-toastify";
import { getExportGraphsV3, getExportTablesV3 } from "services/analyses";
import { downloadFile, gaClickCallback } from "helpers/common";

const FileTypeSelection = styled(Grid)(({ selected }) => ({
  width: "77px",
  height: "84px",
  border: selected ? "1px solid #7F56D9" : "1px solid rgba(0, 0, 0, 0.1)",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "0 7px 7px 0",
  cursor: "pointer",
}));

export function ExportAnalyseContentDialog() {
  const [selectedFileType, setSelectedFileType] = useState("pdf");
  const dispatch = useDispatch();
  const { openedExportDialog, exportAnalysis_id } = useSelector((state) => state.chatsReducer) || {};

  const fileTypes = [
    { name: "PDF", icon: "pdf", value: "pdf" },
    ...(openedExportDialog === EXPORT_TYPES[1] ? [{ name: "PPT", icon: "ppt", value: "pptx" }] : []),
    { name: "DOCX", icon: "doc", value: "docx" },
    ...(openedExportDialog === EXPORT_TYPES[0] ? [{ name: "XLSX", icon: "exel", value: "xlsx" }] : []),
  ];

  const fetchFunc = {
    [EXPORT_TYPES[0]]: getExportTablesV3,
    [EXPORT_TYPES[1]]: getExportGraphsV3,
  };

  const fileName = {
    [EXPORT_TYPES[0]]: "table",
    [EXPORT_TYPES[1]]: "graph",
  };

  const fetchBody = {
    [EXPORT_TYPES[0]]: {},
    [EXPORT_TYPES[1]]: {
      file_configuration: {
        width: 1000,
        height: 1000,
        resolution: 200,
      },
      split_levels: [],
      primary_names: [],
      secondary_names: [],
      follow_up_name: "string",
      heatmap: false,
      number_of_columns: 1,
    },
  };

  const dialogTitle = {
    [EXPORT_TYPES[0]]: "Export Tables",
    [EXPORT_TYPES[1]]: "Export Graphs",
  };

  const closeDialog = () => {
    setSelectedFileType("pdf");
    dispatch(setExportDialog("", null));
  };

  const onExport = () => {
    dispatch(setLoading(true));

    fetchFunc[openedExportDialog]({
      analysis_id: exportAnalysis_id,
      file_type: selectedFileType,
      file_name: fileName[openedExportDialog],
      ...fetchBody[openedExportDialog],
    })
      .then((res) => {
        closeDialog();
        downloadFile(res.data, fileName[openedExportDialog]);
      })
      .catch((err) => {
        toast.error(err.response.data.detail[0].msg);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <Dialog open={!!openedExportDialog} onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>{dialogTitle[openedExportDialog]}</DialogTitle>
      <DialogContent sx={{ overflowY: "visible" }}>
        <Typography fontSize={"18px"} color="#3B3946" marginBottom={"20px"}>
          Select File Type
        </Typography>
        <Grid container>
          {fileTypes.map((item) => (
            <FileTypeSelection
              key={item.value}
              item
              selected={item.value === selectedFileType}
              onClick={() => setSelectedFileType(item.value)}
            >
              <GenieIcon type={item.icon} iconWidth="20px" />
              <Typography fontSize={"12px"} fontWeight={300}>
                {item.name}
              </Typography>
            </FileTypeSelection>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={closeDialog}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            gaClickCallback({
              label: dialogTitle[openedExportDialog],
              callback: () => onExport(),
            })
          }
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}
