import { useEffect, useState } from "react";

export const useSetFileTrack = ({ dataset_id, token }) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (!dataset_id || !token) return;

    const url = `${process.env.REACT_APP_DATA_SET_SOCKET_URL_}/${dataset_id}/?token=${token}`;

    const socket = new WebSocket(url);

    socket.onopen = () => {
      setResponse(null);
      setLoading(true);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      console.log("onmessage event", event);
      if (data) {
        socket.close();
        setResponse(data);
        setLoading(false);
      }
    };

    socket.onerror = (event) => {
      console.log("onerror event", event);
    };

    socket.onclose = () => {
      setLoading(false);
    };

    return () => {
      socket.close();
      setResponse(null);
    };
  }, [dataset_id, token]);

  return { loading, response };
};
