import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { VaribleSelection } from "./variblesSelection";
import { roundNumbers } from "../../helpers/roundNumbers";
import { fetchFrequencyByNames } from "../../actions/datasets";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";

export const FrequencyTable = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { frequency, datasetVarTypes } = useSelector(
    (state) => state.datasetsReducer
  );

  const [frequencyTablesName, setFrequencyTablesName] = useState([]);
  const [loadingState, setLoadingState] = useState({});

  useEffect(() => {
    const loadingObj = {};
    frequencyTablesName.forEach((table_name) => {
      loadingObj[table_name] = false;
    });
    setLoadingState(loadingObj);
  }, [frequencyTablesName]);

  useEffect(() => {
    setFrequencyTablesName(Object.keys(datasetVarTypes));
  }, [datasetVarTypes]);

  const getHeaders = (data) => {
    const headers = Object.keys(data);
    return headers;
  };

  const handleGetFrequencyTable = useCallback(
    (name) => {
      if (frequency[name]) return;
      if (loadingState[name]) return;

      setLoadingState((prevState) => ({
        ...prevState,
        [name]: true,
      }));

      dispatch(
        fetchFrequencyByNames(id, name, () => {
          setLoadingState((prevState) => ({
            ...prevState,
            [name]: false,
          }));
        })
      );
    },
    [dispatch, frequency, id, loadingState]
  );

  return (
    !!frequencyTablesName.length && (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Box>
            {frequencyTablesName.map((table_name, index) => (
              <Accordion
                key={index}
                defaultExpanded={!index}
                sx={{ boxShadow: "none" }}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() =>
                    !frequency[table_name] &&
                    handleGetFrequencyTable(table_name)
                  }
                >
                  <Typography>
                    <b>{table_name}</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    {loadingState[table_name] ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <CircularProgress color="secondary" />
                      </Box>
                    ) : (
                      <Table sx={{ minWidth: 900 }} aria-label="simple table">
                        <TableHead>
                          {frequency[table_name] && (
                            <TableRow>
                              {getHeaders(frequency[table_name][0]).map(
                                (header) => (
                                  <TableCell key={header}>
                                    <b>{header}</b>
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                          )}
                        </TableHead>
                        <TableBody>
                          {frequency[table_name] &&
                            frequency[table_name].map((item, index) => (
                              <TableRow key={index}>
                                {getHeaders(frequency[table_name][0]).map(
                                  (header) => (
                                    <TableCell
                                      key={header}
                                      component="th"
                                      scope="row"
                                    >
                                      {roundNumbers(item[header])}
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>

        <Grid item xs={2}>
          <VaribleSelection headers={frequencyTablesName} />
        </Grid>
      </Grid>
    )
  );
};
