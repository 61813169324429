import { useEffect, useState } from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  IconButton,
} from "@mui/material";
import { roundNumbers } from "../../helpers/roundNumbers";
import { Tooltip } from "@mui/material";
import { CopyIcon } from "../../Icons/CopyIcon";

export const AnalyseTable = ({ data }) => {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    let keys = Object.keys(data[0]);
    setHeaders(keys);
  }, [data]);

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "calc(100vh - 320px)" }}
      >
        <Table stickyHeader sx={{ minWidth: 900 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={`header:${header}`} sx={{ minWidth: "150px" }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow hover key={index}>
                {headers.map((header) =>
                  header !== "Conclusion" ? (
                    <TableCell
                      sx={
                        row["p value"] < 0.05
                          ? { fontWeight: "600 !important" }
                          : {}
                      }
                      key={header + index}
                      align="left"
                    >
                      {roundNumbers(row[header])}
                    </TableCell>
                  ) : (
                    <Tooltip
                      followCursor
                      enterDelay={500}
                      title={row.Conclusion}
                      key={header + index}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(row.Conclusion);
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          "& svg": {
                            opacity: 0,
                            transition: "0.2s",
                          },
                          ":hover": {
                            "& svg": {
                              opacity: 1,
                            },
                          },
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box maxWidth="80%">{row[header]}</Box>

                          <IconButton
                            onClick={() => {
                              copyTextToClipboard(row[header]);
                            }}
                          >
                            <CopyIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </Tooltip>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
