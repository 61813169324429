import { handleUserName } from "helpers/getUserName";
import { Linkify } from "helpers/linkify";

import { MessageItem, MessageText } from "../MessageList/styles";

import gptLogo from "images/short-logo.png";

import { ChatAvatar, UserAvatar } from "../AnalyseItem/styled";

export const SimpleMessage = ({ text, error_text, identity, userData }) => {
  const isUser = identity === "user";

  return (
    <MessageItem identity={isUser}>
      {isUser ? (
        <UserAvatar>{handleUserName(identity, userData.first_name, userData.last_name)}</UserAvatar>
      ) : (
        <ChatAvatar alt="Chat avatar" src={gptLogo} />
      )}

      {text && (
        <MessageText isUser={isUser}>
          <Linkify>{text}</Linkify>
        </MessageText>
      )}
      {error_text && (
        <MessageText isUser={isUser} error_text={!!error_text}>
          {error_text}
        </MessageText>
      )}
    </MessageItem>
  );
};
