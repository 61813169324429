import React from "react";
import { GraphWrapper } from "../styled";
import { AnalyseGraphsContent } from "../../AnalyseGraphsContent";
import { Button, Stack } from "@mui/material";
import { DownloadAnalyseIcon } from "Icons/DownloadAnalyseIcon";
import { setExportDialog } from "actions/chats";
import { EXPORT_TYPES } from "reducer/chats";
import { useDispatch } from "react-redux";
//import { EditIcon } from "Icons/EditIcon";

export const GraphBlock = ({ currentAnalyse, independent, graphProps }) => {
  const dispatch = useDispatch();

  return (
    <Stack direction="row" gap="40px">
      <GraphWrapper>
        <AnalyseGraphsContent
          data={currentAnalyse?.summary?.images}
          analysisType={currentAnalyse.analysis_type}
          independent={independent}
          {...graphProps}
        />
      </GraphWrapper>
      <Stack width="140px" minWidth="140px" gap="24px" direction="column" py="24px">
        <Button
          onClick={() => dispatch(setExportDialog(EXPORT_TYPES[1], currentAnalyse.id))}
          startIcon={<DownloadAnalyseIcon />}
          variant="contained"
        >
          Download
        </Button>
        {/*<Button startIcon={<EditIcon />} variant="contained">
          Edit Graph
        </Button>*/}
      </Stack>
    </Stack>
  );
};
