import TablePagination from '@mui/material/TablePagination';

export const Pagination = ({filters, changeFilters, count = 0}) => {

  const handleChangePage = (event, newPage) => {
    const newFilters = { ...filters, page: newPage, offset: newPage * filters.limit };
    changeFilters(newFilters)
  };

  const handleChangeRowsPerPage = (event) => {
    const newFilters = { ...filters, page: 0, limit: parseInt(event.target.value, 10), offset: 0 };
    changeFilters(newFilters)
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={filters.page}
      onPageChange={handleChangePage}
      rowsPerPage={filters.limit}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};