export const getSettingsField = () => [
  {
    type: "text",
    disabled: false,
    name: "first_name",
    label: "First Name",
  },
  {
    type: "text",
    disabled: false,
    name: "last_name",
    label: "Last Name",
  },
  {
    type: "text",
    disabled: true,
    name: "email",
    label: "Email",
  },
  {
    type: "number",
    disabled: false,
    name: "number_of_roundings",
    label: "Number Of Decimals",
  },
  {
    type: "number",
    disabled: false,
    name: "significance_level",
    label: "Significance Level",
  },
  {
    type: "number",
    disabled: false,
    name: "significance_level_normality",
    label: "Significance Level Normality",
  },
];
