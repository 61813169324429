import {
  Button,
  DialogActions,
  Dialog,
  Typography,
  DialogTitle,
} from '@mui/material';

export function DeleteDialog({open, handleClose, handleDelete, text}) {

  const deleteItem = () => {
    handleDelete(open);
    handleClose();
  }

  return (
    <Dialog open={!!open} onClose={handleClose} maxWidth="xs" >
      <DialogTitle>
        <Typography textAlign={'center'}>Delete {text}</Typography>
      </DialogTitle>
      <DialogActions>
        <Button variant="contained" color='info' onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={deleteItem} >Delete</Button>
      </DialogActions>
    </Dialog>
  )
}