import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography, Box, List, ListItem, FormControlLabel, Checkbox } from '@mui/material';

import { setSelectedVaribles } from '../../actions/datasets';

const VaribleSelectionWrapper = styled(Box)(
  () => ({
    paddingTop: '24px',
    paddingBottom: '24px',
    background: '#fff',
    borderRadius: '12px',
  })
);

const SelectAllContainer = styled(Box)(
  () => ({
    height: '46px',
    background: '#F6F7F9',
    borderRadius: '8px',
    paddingLeft: '16px',
    marginTop: '30px',
    margin: ' 30px 24px 10px 8px',
    display: 'flex',
    alignItems: 'center'
  })
)

const FormControl = styled(FormControlLabel)(
  () => ({
    '& .MuiTypography-root': {
      maxWidth:'100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    width: '100%'
  })
)

export const VaribleSelection = ({headers}) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const selectedVaribles = useSelector(state => state.datasetsReducer.selectedVaribles);
  
  const handleChange = header => event => {
    let varibles = [...selectedVaribles];
    if(event.target.checked) {
      varibles.push(header);
    } else {
      const index = varibles.findIndex(item => item === header);
      varibles.splice(index, 1);
    }
    dispatch(setSelectedVaribles(varibles));
  }

  const handleChangeSelectAll = event => {
    if(event.target.checked) {
      setSelectAll(true)
      dispatch(setSelectedVaribles(headers));
    } else {
      setSelectAll(false)
      dispatch(setSelectedVaribles([]));
    }
  }

  return (
    <VaribleSelectionWrapper>
      <Typography variant='h5' sx={{margin: '0 24px 0 24px'}} >Varibles</Typography>
      <SelectAllContainer>
        <FormControl
          label={'Select All'}
          checked={selectAll}
          onChange={handleChangeSelectAll}
          control={<Checkbox color="secondary"/>}
        />
      </SelectAllContainer>
      <Box sx={{padding: '0 24px'}}>
        <List disablePadding>
          {headers.map((header, index) => (
            <ListItem key={index} disablePadding>
              <FormControl
                label={header}
                checked={!!selectedVaribles.find(item => item === header)}
                onChange={handleChange(header)}
                control={<Checkbox color="secondary"/>}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </VaribleSelectionWrapper>
  );
};
