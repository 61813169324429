import { SvgIcon } from "@mui/material";
import * as React from "react";
export const UploadDatasetIcon = (props) => (
  <SvgIcon
    style={{
      width: "190px",
      height: "170px",
      fill: "none",
    }}
    viewBox="0 0 190 170"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m97.306.37-6.182 1.59-76.167 19.582-6.183 1.59c-3 .774-5.57 2.704-7.145 5.366a11.64 11.64 0 0 0-1.257 8.83L27.18 140.965a11.67 11.67 0 0 0 5.383 7.123 11.74 11.74 0 0 0 8.859 1.253l.016-.004 88.499-22.752.016-.005a11.7 11.7 0 0 0 7.146-5.366 11.64 11.64 0 0 0 1.256-8.831L111.547 8.747a11.67 11.67 0 0 0-5.383-7.124A11.74 11.74 0 0 0 97.306.371"
        fill="#F2F2F2"
      />
      <path
        d="m98.146 3.62-7.323 1.882-73.886 18.996-7.323 1.882a8.33 8.33 0 0 0-5.089 3.822 8.3 8.3 0 0 0-.894 6.289l26.808 103.634a8.3 8.3 0 0 0 3.833 5.073 8.36 8.36 0 0 0 6.308.892l.016-.004 88.499-22.752.017-.004a8.33 8.33 0 0 0 5.088-3.822 8.28 8.28 0 0 0 .895-6.288L108.287 9.585a8.3 8.3 0 0 0-3.833-5.073 8.36 8.36 0 0 0-6.308-.892"
        fill="#fff"
      />
      <path
        d="m92.617 37.18-44.77 11.51c-.5.128-1.03.053-1.475-.209a1.94 1.94 0 0 1-.688-2.658 1.95 1.95 0 0 1 1.19-.893l44.77-11.51c.5-.128 1.03-.053 1.475.209a1.94 1.94 0 0 1 .688 2.657c-.263.443-.69.765-1.19.893m9.418 4.345L49.488 55.033a1.95 1.95 0 0 1-2.372-1.395 1.94 1.94 0 0 1 .209-1.471 1.94 1.94 0 0 1 1.19-.893l52.547-13.51a1.95 1.95 0 0 1 1.476.208 1.94 1.94 0 0 1 .688 2.658 1.95 1.95 0 0 1-1.191.894M99.97 65.608 55.2 77.118a1.96 1.96 0 0 1-2.041-.713 1.94 1.94 0 0 1 1.07-3.046l44.77-11.51a1.95 1.95 0 0 1 2.371 1.395 1.93 1.93 0 0 1-.209 1.47 1.95 1.95 0 0 1-1.19.894m9.417 4.344-52.546 13.51a1.95 1.95 0 0 1-2.372-1.395 1.94 1.94 0 0 1 .715-2.036 2 2 0 0 1 .684-.329l52.547-13.509a1.95 1.95 0 0 1 2.371 1.395 1.937 1.937 0 0 1-1.399 2.364m-2.064 24.085-44.77 11.51a1.95 1.95 0 0 1-2.37-1.396 1.94 1.94 0 0 1 .208-1.47 1.95 1.95 0 0 1 1.19-.894l44.769-11.51a1.95 1.95 0 0 1 1.476.209 1.94 1.94 0 0 1 .687 2.658c-.262.443-.69.764-1.19.893m9.419 4.344L64.196 111.89a1.94 1.94 0 0 1-1.477-.207 1.93 1.93 0 0 1-.897-1.187 1.937 1.937 0 0 1 1.401-2.365l52.547-13.51a1.945 1.945 0 0 1 2.372 1.395 1.944 1.944 0 0 1-1.399 2.364"
        fill="#F2F2F2"
      />
      <path
        d="M40.205 60.49 24.36 64.565a.937.937 0 0 1-1.137-.669l-3.637-14.06a.93.93 0 0 1 .67-1.133L36.1 44.63a.937.937 0 0 1 1.138.669l3.637 14.06a.93.93 0 0 1-.671 1.133m7.354 28.428-15.843 4.073a.937.937 0 0 1-1.138-.67L26.94 78.265a.93.93 0 0 1 .671-1.134l15.844-4.073a.94.94 0 0 1 1.137.67l3.637 14.059a.93.93 0 0 1-.67 1.133m7.353 28.428-15.844 4.073a.93.93 0 0 1-1.137-.669l-3.637-14.059a.93.93 0 0 1 .67-1.134l15.845-4.073a.93.93 0 0 1 1.137.669l3.637 14.059a.93.93 0 0 1-.671 1.134M159.051 26.64H67.624a11.72 11.72 0 0 0-8.264 3.419 11.65 11.65 0 0 0-3.428 8.238v107.025a11.65 11.65 0 0 0 3.428 8.239 11.72 11.72 0 0 0 8.264 3.417h91.427a11.72 11.72 0 0 0 8.264-3.417 11.66 11.66 0 0 0 3.429-8.239V38.297a11.65 11.65 0 0 0-3.429-8.238 11.72 11.72 0 0 0-8.264-3.418"
        fill="#E6E6E6"
      />
      <path
        d="M159.051 29.996H67.623a8.35 8.35 0 0 0-5.885 2.434 8.3 8.3 0 0 0-2.441 5.867v107.025a8.3 8.3 0 0 0 2.441 5.867 8.35 8.35 0 0 0 5.885 2.434h91.428a8.35 8.35 0 0 0 5.885-2.434 8.3 8.3 0 0 0 2.441-5.867V38.297a8.3 8.3 0 0 0-2.441-5.867 8.35 8.35 0 0 0-5.885-2.434"
        fill="#fff"
      />
      <path
        d="M168.656 170c11.788 0 21.344-9.526 21.344-21.278s-9.556-21.278-21.344-21.278-21.344 9.527-21.344 21.278c0 11.752 9.556 21.278 21.344 21.278"
        fill="#7F56D9"
      />
      <path
        d="M178.865 145.947h-7.424v-7.401c0-.736-.294-1.442-.816-1.963a2.79 2.79 0 0 0-3.937 0 2.77 2.77 0 0 0-.815 1.963v7.401h-7.424a2.8 2.8 0 0 0-1.969.813 2.77 2.77 0 0 0 0 3.925c.523.52 1.231.812 1.969.812h7.424v7.401c0 .737.293 1.442.815 1.963a2.79 2.79 0 0 0 3.937 0 2.77 2.77 0 0 0 .816-1.963v-7.401h7.424a2.773 2.773 0 1 0 0-5.55"
        fill="#fff"
      />
      <path
        d="M145.291 88.807H99.057a1.95 1.95 0 0 1-1.379-.568 1.94 1.94 0 0 1-.422-2.117 1.94 1.94 0 0 1 1.055-1.05c.236-.098.49-.148.746-.148h46.234a1.95 1.95 0 0 1 1.945 1.941 1.95 1.95 0 0 1-1.945 1.941m8.032 6.552H99.057a1.95 1.95 0 0 1-1.801-1.198 1.937 1.937 0 0 1 1.055-2.537c.236-.098.49-.148.746-.148h54.266a1.95 1.95 0 0 1 1.947 1.941 1.95 1.95 0 0 1-1.947 1.941m-8.032 22.808H99.057c-.256 0-.51-.05-.746-.147a1.933 1.933 0 0 1-.632-3.167 1.95 1.95 0 0 1 1.378-.568h46.234a1.94 1.94 0 1 1 0 3.882m8.032 6.551H99.057a1.9 1.9 0 0 1-.746-.147 1.94 1.94 0 0 1-1.055-2.537 1.93 1.93 0 0 1 1.055-1.051c.236-.097.49-.147.746-.147h54.266a1.96 1.96 0 0 1 1.378.568 1.93 1.93 0 0 1 .571 1.373 1.94 1.94 0 0 1-1.949 1.941M88.7 98.331H72.338a.936.936 0 0 1-.934-.93V82.88a.93.93 0 0 1 .934-.93H88.7a.936.936 0 0 1 .933.93V97.4a.93.93 0 0 1-.933.93m0 29.36H72.338a.933.933 0 0 1-.934-.931V112.24a.93.93 0 0 1 .934-.931H88.7a.937.937 0 0 1 .933.931v14.519a.93.93 0 0 1-.933.931"
        fill="#E6E6E6"
      />
      <path
        d="M145.342 56.124h-34.086a1.95 1.95 0 0 1-1.377-.568 1.94 1.94 0 0 1 0-2.745 1.95 1.95 0 0 1 1.377-.569h34.086a1.95 1.95 0 0 1 1.377.569 1.938 1.938 0 0 1-1.377 3.313m8.032 6.552h-42.118a1.95 1.95 0 0 1-1.947-1.942 1.93 1.93 0 0 1 .57-1.372 1.94 1.94 0 0 1 1.377-.569h42.118a1.95 1.95 0 0 1 1.377.569 1.94 1.94 0 0 1 0 2.745 1.95 1.95 0 0 1-1.377.569"
        fill="#CCC"
      />
      <path
        d="M103.712 70.615H72.287a.936.936 0 0 1-.934-.931v-24.45a.93.93 0 0 1 .934-.93h31.425a.936.936 0 0 1 .934.93v24.45a.934.934 0 0 1-.934.93"
        fill="#7F56D9"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h190v170H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
