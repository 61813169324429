import { HeaderLinks } from "./header.constants";

import { ListContainer, StyledListItem } from "./styles";

import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { Logo } from "../../../../components/logo";
import { gaClickCallback } from "helpers/common";

export const Header = ({ setOpenLogin, setOpenRegister }) => {
  const handleUserRedirect = (route) => {
    const element = document.getElementById(route);

    if (element)
      element?.scrollIntoView({
        behavior: "smooth",
      });
  };

  return (
    <Grid container p="30px 80px" flexWrap="nowrap" alignItems="center" justifyContent="center" gap="40px">
      <Logo />
      <Grid container justifyContent="space-between">
        <ListContainer>
          {HeaderLinks.map(({ title, route }, index) => (
            <StyledListItem onClick={() => handleUserRedirect(route)} key={index}>
              {title}
            </StyledListItem>
          ))}
        </ListContainer>

        <ListContainer sx={{ gap: "24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              gaClickCallback({
                label: "Login",
                callback: () => setOpenLogin(true),
              });
            }}
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              gaClickCallback({
                label: "Sign up",
                callback: () => setOpenRegister(true),
              });
            }}
          >
            Sign up
          </Button>
        </ListContainer>
      </Grid>
    </Grid>
  );
};
