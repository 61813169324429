import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setLoading } from "../../../../../../actions/user";
import { DataTable } from "../../../../../dataset/dataTable";
import { fetchSummary } from "../../../../../../actions/datasets";

import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";

export const DatasetTable = ({ id, name }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(fetchSummary(id));
  }, [id, dispatch]);

  return (
    <Box>
      <Typography variant="h4" fontSize="22px" gutterBottom>
        {name}
      </Typography>
      <DataTable datasetId={id} />
    </Box>
  );
};
