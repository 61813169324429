import { SvgIcon } from "@mui/material";
import React from "react";

export const SettingIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "18px", height: "18px", fill: "none", marginRight: "8px" }}
      viewBox="0 0 0.4 0.4"
      className="icon glyph"
    >
      <g fill="#475467" fillRule="evenodd" clipRule="evenodd">
        <path d="M.2.125a.075.075 0 1 0 0 .15.075.075 0 0 0 0-.15M.163.2a.038.038 0 1 1 .075 0 .038.038 0 0 1-.075 0" />
        <path d="M.188 0a.044.044 0 0 0-.044.044v.004L.132.053.129.05a.044.044 0 0 0-.062 0L.049.068a.044.044 0 0 0 0 .062l.003.003-.005.012H.043A.044.044 0 0 0 0 .188v.025c0 .024.02.044.044.044h.004l.005.012L.05.272a.044.044 0 0 0 0 .062l.018.018a.044.044 0 0 0 .062 0L.133.349l.012.005v.004c0 .024.02.044.044.044h.025A.044.044 0 0 0 .258.358V.354L.27.349l.003.003a.044.044 0 0 0 .062 0L.353.334a.044.044 0 0 0 0-.062L.35.269.355.257h.004A.044.044 0 0 0 .4.213V.188A.044.044 0 0 0 .356.144H.352L.347.132.35.129a.044.044 0 0 0 0-.062L.332.049a.044.044 0 0 0-.062 0L.267.052.255.047V.043A.044.044 0 0 0 .213 0zM.182.044.188.038h.025l.006.006v.017a.02.02 0 0 0 .014.018q.016.004.03.012A.02.02 0 0 0 .286.088L.298.076a.01.01 0 0 1 .009 0l.018.018a.01.01 0 0 1 0 .009L.313.115A.02.02 0 0 0 .31.138a.1.1 0 0 1 .012.03.02.02 0 0 0 .018.014h.017l.006.006v.025L.357.219H.34a.02.02 0 0 0-.018.014L.31.263a.02.02 0 0 0 .003.023l.012.012a.01.01 0 0 1 0 .009L.307.325a.01.01 0 0 1-.009 0L.286.313A.02.02 0 0 0 .263.31a.1.1 0 0 1-.03.012.02.02 0 0 0-.014.018v.017L.213.363H.188L.182.357V.34A.02.02 0 0 0 .168.322.1.1 0 0 1 .138.31a.02.02 0 0 0-.023.003L.103.325a.01.01 0 0 1-.009 0L.076.307a.01.01 0 0 1 0-.009L.088.286A.02.02 0 0 0 .091.263a.1.1 0 0 1-.012-.03.02.02 0 0 0-.018-.014H.044L.038.213V.188L.044.182h.017A.02.02 0 0 0 .079.168l.012-.03A.02.02 0 0 0 .088.115L.076.103a.01.01 0 0 1 0-.009L.094.076a.01.01 0 0 1 .009 0l.012.012a.02.02 0 0 0 .023.003.1.1 0 0 1 .03-.012.02.02 0 0 0 .014-.018z" />
      </g>
    </SvgIcon>
  );
};
