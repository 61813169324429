import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getDatasets = (filters) => {
  const sessionToken = localStorage.getItem("session_token");
  const keys = Object.keys(filters);
  let filter_params = "";
  keys.forEach((key) => {
    if (filters[key] || filters[key] === 0) {
      filter_params += `${key}=${filters[key]}&`;
    }
  });

  return axios.get(`${API_URL}/datasets/?${filter_params}`, {
    headers: {
      Authorization: "Bearer " + sessionToken,
    },
  });
};

export const renameDataset = (id, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.patch(`${API_URL}/datasets/${id}/`, data);
};

export const getDatasetNames = (filters) => {
  const sessionToken = localStorage.getItem("session_token");
  const keys = Object.keys(filters);

  let filter_params = "";
  keys.forEach((key) => {
    if (filters[key] || filters[key] === 0) {
      filter_params += `${key}=${filters[key]}&`;
    }
  });

  return axios.get(`${API_URL}/datasets/names/?${filter_params}`, {
    headers: {
      Authorization: "Bearer " + sessionToken,
    },
  });
};

export const sendDatasets = ({ id, datasetName, datasetFile }) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  let formData = new FormData();
  formData.append("file", datasetFile);

  return axios.post(`${API_URL}/datasets/upload/?project_id=${id}&dataset_name=${datasetName}`, formData);
};

export const createDatasetId = (filters) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;
  const keys = Object.keys(filters);
  let filter_params = "";
  keys.forEach((key) => {
    if (filters[key] || filters[key] === 0) {
      filter_params += `${key}=${filters[key]}&`;
    }
  });

  return axios.post(`${API_URL}/datasets/v2/create_dataset_id/?${filter_params}`);
};

export const updateDatasetConfiguration = (unique_id, configuration) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/datasets/update_file_configuration/?unique_id=${unique_id}`, configuration);
};

export const createDataset = (unique_id, configuration, variable_types) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/datasets/save/?unique_id=${unique_id}`, {
    configuration,
    variable_types,
  });
};

export const getDatasetData = (id, filters = {}) => {
  const { offset = 0, limit = 25 } = filters;
  const start_row = offset;
  const end_row = offset + limit;

  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.get(`${API_URL}/datasets/${id}/summary/?start_row=${start_row || 1}&end_row=${end_row}`);
};

export const getDatasetSummary = (id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.get(`${API_URL}/datasets/${id}/summary/tables`);
};

export const getDatasetFrequency = (id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.get(`${API_URL}/datasets/${id}/summary/frequency`);
};

export const getFrequencyByNames = (id, s3_names) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/datasets/v2/create_freq_tables/?dataset_id=${id}`, {
    s3_names,
  });
};

export const updateVariable = (data, id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/datasets/update_variable_attributes/?dataset_id=${id}`, [data]);
};

export const deleteDataset = (id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.delete(`${API_URL}/datasets/v2/delete/${id}`);
};

export const calculateNewVariable = (id, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/datasets/${id}/calculate_new_variable`, data);
};

export const saveNewVariable = (id, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/datasets/${id}/add_new_variable`, data);
};
