import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { UploadIcon } from "../../Icons/UploadIcon";

const FileUploaderWrapper = styled("div")(() => ({
  width: "100%",
}));

const FileName = styled("div")(() => ({
  textAlign: "center",
  fontSize: "16px",
  color: "#163B65",
  marginBottom: "15px",
}));

const FileUploaderInput = styled(Box)(({ hoveredInput, error }) => ({
  width: "100%",
  height: "160px",
  border: error ? "1px solid #d32f2f" : "1px solid #EAECF0",
  backgroundColor: hoveredInput ? "#F8FAFF" : "#F9FAFB",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  color: "#8593ad",
}));

export function UploadFile({
  label,
  placeholder,
  value,
  onChange,
  error,
  accept = "*",
  name = "datasetFile",
  labelElement = null,
  absoluteErrorLabel = false,
}) {
  const [hoveredInput, setHovered] = useState(false);

  const preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const dt = event.dataTransfer;
    const e = { target: { files: dt.files } };
    onChange(e);
    event.stopPropagation();
  };

  return (
    <FileUploaderWrapper>
      {!labelElement && <FileName>{value ? `${label} ${value?.name}` : label}</FileName>}
      <FileUploaderInput
        onClick={() => document.getElementById("upload_input").click()}
        onDrop={handleDrop}
        onDragEnter={() => {
          setHovered(true);
        }}
        onDragOver={preventDefault}
        onDragLeave={() => {
          setHovered(false);
        }}
        hoveredInput={hoveredInput}
        error={error}
      >
        <UploadIcon />
        <br />
        {value ? `Uploaded ${value?.name}` : labelElement ? labelElement : placeholder}
        <input name={name} id="upload_input" type="file" accept={accept} hidden onChange={onChange} />
      </FileUploaderInput>
      {!!error ? (
        <Typography variant="error">{error}</Typography>
      ) : (
        <>{!absoluteErrorLabel && <div style={{ height: "17px" }}></div>}</>
      )}
    </FileUploaderWrapper>
  );
}
