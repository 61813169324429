import { Fragment, useState } from "react";
import { SketchPicker } from "react-color";
import { styled } from "@mui/system";
import { Box, Grid, Menu } from "@mui/material";

const ColorBlock = styled(Box)(
  ({bColor}) => ({
    width: '26px',
    height: '26px',
    borderRadius: '5px',
    border: ' 1px solid #E6EAEE',
    cursor: 'pointer',
    background: bColor
  })
)

const ColorHexBlock = styled(Box)(
  () => ({
    width: 'calc(100%-16px)',
    height: '16px',
    borderRadius: '5px',
    background: '#E6EAEE',
    fontSize: '12px',
    fontWeight: 400,
    color: '#3C475D',
    padding: '5px 8px'
  })
)

export function EditGraphColorPicker({value, onChange, name}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  // const [color, setColor] = useState(value);

  const handleChange = color => {
    const event = {
      target: {
        name: name,
        value: color
      }
    }
    onChange(event);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={2}>
          <ColorBlock bColor={value} onClick={(event) => setAnchorEl(event.currentTarget)} />
        </Grid>
        <Grid item xs={10}>
          <ColorHexBlock>{value}</ColorHexBlock>
        </Grid>
      </Grid>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <SketchPicker color={value} onChange={(color) => handleChange(color.hex)}/>
      </Menu>
    </Fragment>
  );
}