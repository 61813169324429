import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const IconWrapper = styled(Button)(() => ({
  borderRadius: "8px",
  padding: "10px 18px",
  flexWrap: "nowrap",
  alignItems: "center",
  gap: "10px",
  backgroundColor: "#2F80ED",
  color: "white",
  fontSize: 14,
  fontWeight: "600",
  lineHeight: "20px",

  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",

  svg: {
    color: "#fff",
  },

  "&:hover": {
    backgroundColor: "#2F80ED",
  },
}));
