import React from "react";
import { Logo } from "../../logo";
import { BackgroundIcon, HeaderWrapper, Subtitle, Title } from "../styles";
import { Box, IconButton, Stack } from "@mui/material";
import { CrossIcon } from "../../../Icons/CrossIcon";
import BackgroundImage from "../../../images/background-login.png";

export const HeaderBlock = ({ title, subTitle, onClose }) => {
  return (
    <Stack position="relative" mb="-25px">
      <BackgroundIcon>
        <Box component="img" src={BackgroundImage} />
      </BackgroundIcon>

      <IconButton disableRipple sx={{ position: "absolute", right: "0px", top: "0px" }} onClick={onClose}>
        <CrossIcon />
      </IconButton>

      <HeaderWrapper>
        <Logo />
        <Title variant="h4">{title}</Title>
        <Subtitle>{subTitle}</Subtitle>
      </HeaderWrapper>
    </Stack>
  );
};
