import { TabsSet } from '../../components/tabs-set';
import { AnalysesTable } from './analysesTable';
import { DatasetsTable } from './datasetsTable';
import { ProjectsTable } from './projectsTable';

const tabs = [
  {
    name: 'Projects',
    component: <ProjectsTable />
  },
  {
    name: 'Datasets',
    component: <DatasetsTable />
  },
  {
    name: 'Analyses',
    component: <AnalysesTable />
  }
];

export const OwerviewTabs = () => {

  return (
    <>
      <TabsSet tabs={tabs} search={true} />
    </>
  );
};