import { GOOGLE_FORM_REPORT_LINK } from "constants/chat.constants";
//import { CrownIcon } from "../../Icons/CrownIcon";
import ReportIcon from "@mui/icons-material/Report";
import { IconWrapper } from "./styles";

export const ReportButton = () => {
  return (
    <a href={GOOGLE_FORM_REPORT_LINK} alt="report error link" target="_blank" rel="noreferrer">
      <IconWrapper>
        <ReportIcon />
        {/*<CrownIcon />*/}
        Report Error
      </IconWrapper>
    </a>
  );
};

//{
//  /*<a href={GOOGLE_FORM_REPORT_LINK} alt="report error link" target="_blank" rel="noreferrer">
//  <IconWrapper>
//    <ReportIcon />
//    Go Premium
//  </IconWrapper>
//</a>*/
//}
