import { AccordionSummary, Avatar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AnalysesWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  overflow: "hidden",
  gap: "12px",
}));

export const GraphWrapper = styled(Box)(() => ({
  "& > :first-of-type": {
    //height: "100%",
  },
  width: "calc(100% - 180px)",

  //height: "100%",
  "& img": {
    maxWidth: "60%",
  },
}));

export const TableWrapper = styled(Box)(() => ({
  "& > :first-of-type": {
    height: "100%",
  },
}));

export const TableContainer = styled(Box)(() => ({
  height: "441px",
  overflowY: "auto",
  borderRadius: "12px",
  border: "1px solid #EAECF0",
}));

export const AccordionContent = styled(AccordionSummary)(() => ({
  height: "50px",
  padding: "0px 24px",

  "& p": {
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#5E5873",
  },
}));

export const FullScreenBtnWrapper = styled(Box)(() => ({
  alignSelf: "flex-end",
  marginTop: "11px",
}));

export const UserAvatar = styled(Avatar)(() => ({
  width: "44px",
  height: "44px",
  backgroundColor: "#383838",
  fontSize: "18px",

  ".MuiAvatar-img": {
    objectFit: "contain",
  },
}));

export const ChatAvatar = styled(Avatar)(() => ({
  width: "44px",
  height: "44px",
  borderRadius: 0,
  backgroundColor: "tranparent",

  ".MuiAvatar-img": {
    objectFit: "contain",
  },
}));

export const AccordionWrapper = styled(Box)(() => ({
  maxHeight: "400px",
  overflowY: "auto",
  overflowX: "hidden",
}));
