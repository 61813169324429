import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Field = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: "19px",
  },

  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },

  "& .MuiInputBase-input": {
    color: "#101828",
    borderRadius: "8px",
    position: "relative",
    //backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: error ? "1px solid #d32f2f" : "1px solid #ced4da",
    fontSize: "16px",
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },

    "&::placeholder": {
      fontSize: "15px",
      fontWeight: 400,
    },
  },
}));

export function TextField({
  disabled = false,
  label,
  name = "",
  value,
  onChange,
  type = "text",
  error = "",
  multilineRows,
  labelSx = {},
  absoluteErrorLabel = false,
  labelIcon = null,
  ...props
}) {
  return (
    <FormControl variant="standard" fullWidth error={!!error}>
      <InputLabel
        shrink
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          color: "#344054",
          ...labelSx,
        }}
      >
        {label}
        {!!labelIcon && labelIcon}
      </InputLabel>
      <Field
        disabled={disabled}
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        error={!!error}
        multiline={!!multilineRows}
        rows={multilineRows}
        {...props}
      />
      {!!error ? (
        <Typography variant="error" mt="4px">
          {error}
        </Typography>
      ) : (
        <>{!absoluteErrorLabel && <div style={{ height: "21px" }}></div>}</>
      )}
    </FormControl>
  );
}
