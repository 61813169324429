import { TabsSet } from '../../components/tabs-set';
import { AnalysesTable } from '../owerview/analysesTable';
import { DatasetsTable } from '../owerview/datasetsTable';


const tabs = [
  {
    name: 'Datasets',
    component: <DatasetsTable />
  },
  {
    name: 'Analyses',
    component: <AnalysesTable />
  }
];

export const ProjectTabs = () => {

  return (
    <>
      <TabsSet tabs={tabs} serach={true}/>
    </>
  );
};