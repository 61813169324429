import { SvgIcon } from "@mui/material";
import React from "react";

export const ResidentsIcon = () => {
  return (
    <SvgIcon
      style={{ width: "22px", height: "22px", fill: "none" }}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8c0 3.87-3.13 7-7 7-.32 0-.63-.02-.93-.07A6.986 6.986 0 0 1 1 8c0-3.87 3.13-7 7-7 3.55 0 6.48 2.64 6.93 6.07.05.3.07.61.07.93"
        stroke="#475467"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14c0 3.87-3.13 7-7 7-3.55 0-6.48-2.64-6.93-6.07.3.05.61.07.93.07 3.87 0 7-3.13 7-7 0-.32-.02-.63-.07-.93C18.36 7.52 21 10.45 21 14m-7.85-.88 1.31 1.29M16 11l-1-1m-4 6-1-1"
        stroke="#475467"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
