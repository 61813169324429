import { filterDatasetByStatus } from "helpers/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDatasets } from "services/datasets";

export const useDatasetSummary = () => {
  const [datasetState, setDatasetState] = useState({
    list: [],
    loading: false,
    search: "",
  });

  const handleFetch = useCallback(() => {
    setDatasetState((prev) => ({ ...prev, loading: true }));
    getDatasets({ limit: 50 })
      .then((res) => {
        setDatasetState((prev) => ({
          ...prev,
          list: filterDatasetByStatus(res?.data?.records) ?? [],
        }));
      })
      .finally(() => {
        setDatasetState((prev) => ({ ...prev, loading: false }));
      });
  }, []);

  const onDatasetSearch = (event) => {
    const { value } = event.target || {};
    setDatasetState((prev) => ({ ...prev, search: value }));
  };

  const datasetList = useMemo(
    () =>
      datasetState.list.filter((item) =>
        item?.name?.toLowerCase().includes(datasetState.search.toLowerCase())
      ),
    [datasetState.list, datasetState.search]
  );

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { onDatasetSearch, datasetList, ...datasetState };
};
