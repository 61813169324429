import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { TextField } from "./inputs/textInput";
import { UploadFile } from "./inputs/uploadFile";
import { saveProject, clearErrors } from "../actions/projects";
import { useSetFileTrack } from "../hooks/useSetFileTrack";
import { setLoading } from "../actions/user";
import { SOCKET_STATUS } from "./createDataset/constants";
import { toast } from "react-toastify";
import { fetchDatasets } from "../actions/datasets";

export function CreateProject({ open, handleClose }) {
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [datasetName, setDatasetName] = useState("");
  const [datasetFile, setDatasetFile] = useState("");
  const [datasetId, setDatasetId] = useState(null);

  const { loading, response } = useSetFileTrack({
    dataset_id: datasetId,
    token: localStorage.getItem("session_token"),
  });
  const errors = useSelector((state) => state.projectsReducer.errors);

  const handleChangeFile = (event) => {
    setDatasetFile(event.target.files[0]);
  };

  useEffect(() => {
    if (loading) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (response) {
      switch (response.event) {
        case SOCKET_STATUS.READY:
          dispatch(fetchDatasets());
          setDatasetId(null);
          break;
        case SOCKET_STATUS.ERROR:
          toast.error("Failed to upload Dataset. Please, try again");
          setDatasetId(null);
          break;
        default:
          break;
      }
    }
  }, [dispatch, response]);

  const onClose = () => {
    setProjectName("");
    setProjectDescription("");
    setDatasetName("");
    setDatasetFile("");
    dispatch(clearErrors());
    handleClose();
  };

  const handleSubmit = () => {
    dispatch(
      saveProject(
        {
          projectName,
          projectDescription,
          dataset_name: datasetName,
          datasetFile,
        },
        () => onClose(),
        setDatasetId
      )
    );
  };

  const checkError = (field) => {
    let error = "";
    errors.forEach((err) => {
      if (err.loc[1] === field) {
        error = err.msg;
      }
    });
    return error;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent>
        <TextField
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          error={checkError("project_name")}
        />
        <TextField
          label="Project Description"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
          multilineRows={4}
          error={checkError("project_description")}
        />
        <TextField
          label="Dataset Name"
          value={datasetName}
          onChange={(e) => setDatasetName(e.target.value)}
        />
        <UploadFile
          label={`Upload Dataset:`}
          placeholder="Click to Upload Dataset"
          value={datasetFile}
          onChange={handleChangeFile}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
