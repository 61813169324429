import React from "react";
import { DatasetItemContent, DatasetItemName, DatasetItemWrapper, SelectDatasetBtn } from "./styles";
import { Stack } from "@mui/material";
import StyledTooltip from "components/styledTooltip";
import { PauseIcon } from "Icons/PauseIcon";
import { gaClickCallback } from "helpers/common";

export const DatasetItem = ({ dataset, onDatasetSelect }) => {
  const { name, number_of_rows, number_of_columns } = dataset || {};

  return (
    <DatasetItemWrapper>
      <Stack p="0 20px 20px" alignItems="center">
        <StyledTooltip title={name} placement="top" arrow sx={{ minWidth: "90px", textAlign: "center" }}>
          <DatasetItemName noWrap variant="h4">
            {name}
          </DatasetItemName>
        </StyledTooltip>
      </Stack>
      <DatasetItemContent direction="row" justifyContent="space-between">
        <Stack direction="row" gap="12px" alignItems="center" width="49%">
          <PauseIcon />
          <DatasetItemName noWrap variant="h4">
            {number_of_columns}
          </DatasetItemName>
        </Stack>
        <Stack direction="row" gap="12px" alignItems="center" width="49%">
          <PauseIcon styles={{ transform: "rotate(90deg)" }} />
          <DatasetItemName noWrap variant="h4">
            {number_of_rows}
          </DatasetItemName>
        </Stack>
      </DatasetItemContent>
      <Stack px="20px">
        <SelectDatasetBtn
          variant="contained"
          onClick={() =>
            gaClickCallback({
              label: "Dataset Select Item",
              callback: () => onDatasetSelect(dataset),
            })
          }
        >
          Select
        </SelectDatasetBtn>
      </Stack>
    </DatasetItemWrapper>
  );
};
