import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PageTitle } from "../../components/pageTitle";
import { OwerviewStats } from "./OwerviewStats";
import { DatasetsTable } from "./datasetsTable";

import { fetchDatasets } from "../../actions/datasets";
import { Box } from "@mui/material";

export default function Datasets() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDatasets());
  }, [dispatch]);

  return (
    <Box>
      <PageTitle title="Datasets" />
      <OwerviewStats only={"datasets"} />
      <DatasetsTable />
    </Box>
  );
}
