export const CHAT_SUGGESTIONS = [
  {
    title: "Describe Data",
    command: "Please describe variables [Variable 1], [Variable 2],..",
    example: "",
  },
  {
    title: "Variable Group Comparison",
    command: "Compare groups of the variable [Variable] concerning [Variable 1], [Variable 2]...",
    example: "compare sex concerning age, ethnicity, and residence",
  },
  {
    title: "Investigate Relationships Between Different Variables",
    command: "Find the link between [Variable 1] and [Variable 2], [Variable 3], [Variable 4].",
    example: "finding the link between age and sex, ethnicity, height, and weight",
  },
  {
    title: "Correlation Analysis",
    command: "Please correlate [Variable 1], [Variable 2], [Variable 3].",
    example: "correlating age, weight, and height",
  },
  {
    title: "Build Regression Model",
    command:
      "Build a regression model to associate [Exposure Variables] with [Outcome Variables], adjusting for [Covariates].",
    example:
      "building a regression model to associate drug1, drug2, and drug3 with outcome1, outcome2, adjusting for sex and ethnicity",
  },
  {
    title: "Phrase Methods",
    command: "Which methods were use for analysis?",
    example: "",
  },
];

export const GOOGLE_FORM_REPORT_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSeRkOG6BYGWm7GplaEb6KcBlbIDnO1FBbgheTIDS5CUDpKkbw/viewform";
