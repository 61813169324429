import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const UserNavbarWrapper = styled(Grid)(() => ({
  display: "flex",
  gap: "24px",
  alignItems: "center",
  width: "100%",
  justifyContent: "flex-end",
  padding: "27.5px 50px",
  borderBottom: "1px #EAECF0 solid",
}));
