import { useState } from "react";
import { useSelector } from "react-redux";

import { UserInfoBar } from "../../../../../../components/userInfoBar";
import { UserSettings } from "../../../../../../components/userSettings";
import { ReportButton } from "../../../../../../components/buttons/updait-btn";

import { UserNavbarWrapper } from "./styles";

export const UserNavbar = () => {
  const { data } = useSelector((state) => state.userReducer);
  const [showSettings, setShowSettings] = useState(false);

  return (
    <>
      <UserNavbarWrapper>
        <ReportButton />
        <UserInfoBar
          setShowSettings={setShowSettings}
          first_name={data.first_name}
          last_name={data.last_name}
          role="Admin"
        />
      </UserNavbarWrapper>
      {showSettings && <UserSettings open={showSettings} onClose={setShowSettings} userData={data} />}
    </>
  );
};
