import { SvgIcon } from "@mui/material";
import React from "react";

export const ZoomIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px", fill: "none" }}
      viewBox="0 0 20 20"
    >
      <path
        d="m17.5 17.5-5-5m1.667-4.167a5.833 5.833 0 1 1-11.667 0 5.833 5.833 0 0 1 11.667 0"
        stroke="#475467"
        strokeWidth={1.667}
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
    </SvgIcon>
  );
};
