import { ContentBlock } from "./components/ContentBlock";

import { cl } from "./styles";

import { Dialog } from "@mui/material";
import { useContext } from "react";
import { PopupContext } from "App";
import { POPUPS } from "hooks/usePopups";

export default function TermsOfUsePopup() {
  const { setClosePopup, popupsState } = useContext(PopupContext);

  const onClose = () => setClosePopup(POPUPS.TERMS_OF_USE);

  return (
    <Dialog
      open={popupsState[POPUPS.TERMS_OF_USE]}
      onClose={onClose}
      sx={{ zIndex: 10001 }}
      PaperProps={{ sx: cl.dialogWrapper }}
    >
      <ContentBlock {...{ handleClose: onClose }} />
    </Dialog>
  );
}
