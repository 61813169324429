import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ChatNavbar } from "./components/chatNavbar";
import { ChatContent } from "./components/chatContent";
import { CreateDataset } from "../../components/createDataset";

import { fetchChats } from "../../actions/chats";
import { fetchDatasetNames } from "../../actions/datasets";

import { ChatWrapper } from "./styles";

export const GPT = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchChats());
    dispatch(fetchDatasetNames());
  }, [dispatch]);

  return (
    <ChatWrapper>
      <ChatNavbar />
      <CreateDataset />
      <ChatContent />
    </ChatWrapper>
  );
};
