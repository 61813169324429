import { Dialog, DialogContent, Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const GreenSpan = styled("span")(() => ({
  color: "#6941C6",
  marginLeft: "5px",
  cursor: "pointer",

  "&:hover": {
    textDecoration: "underline",
  },
}));

export const ErrorText = styled(Grid)(() => ({
  position: "absolute",
  fontSize: "11px",
  margin: "3px 0 0 0px",
  color: "#e74c3c",
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "28px",
  padding: "26px",
  color: "#474551",

  button: {
    width: "100%",
    padding: "12px 16px",
    fontSize: "16px",
  },
}));

export const TextInput = styled(TextField)(() => ({
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#1CB4AE",
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  padding: "24px",
  "& .MuiPaper-root": {
    borderRadius: "12px",
    boxShadow: "none",
  },

  "& .MuiDialogContent-root": {
    padding: "24px",
  },
}));
