import { ABOUT_US_BLOCK_DATA } from "constants/common.constants";

import AboutUsImage from "images/aboutUs.png";

import { Box, Stack, Typography } from "@mui/material";

export const About = () => {
  return (
    <Stack
      direction="row"
      p="40px 80px 80px"
      alignItems="center"
      gap="64px"
      justifyContent="space-between"
      width="100%"
      id="about"
    >
      <Stack gap="12px" width="60%">
        <Typography variant="caption">{ABOUT_US_BLOCK_DATA.captureText}</Typography>
        <Typography variant="h2" mb="20px">
          {ABOUT_US_BLOCK_DATA.title}
        </Typography>
        <Typography variant="h5" fontWeight={700}>
          {ABOUT_US_BLOCK_DATA.subtitle}
        </Typography>
        <Stack gap="32px">
          {ABOUT_US_BLOCK_DATA.description.map((item, index) => (
            <Typography key={index} lineHeight="28px" fontWeight="500" color="#475467" fontSize="18px">
              {item}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <Stack width="40%">
        <Box
          component="img"
          ml="auto"
          height="100%"
          width="100%"
          //maxHeight="654px"
          //maxWidth="576px"
          sx={{ borderRadius: "8px" }}
          src={AboutUsImage}
          alt="about-us-image"
        />
      </Stack>
    </Stack>
  );
};
