import { MessageList } from "../MessageList";
import { ChatInput } from "components/inputs/chatInput";

import { ActiveChatWrapper } from "./styles";
import { SuggestionsListScreen } from "../MessageList/components/SuggestionsListScreen";
import { ExportAnalyseContentDialog } from "../ExportAnalyseContentDialog";
import { useActiveChat } from "./useActiveChat";
import { LoadingScreen } from "components/screens/loadingScreen";
import { SeeMoreAccordion } from "../SeeMoreAccordion";
import { Stack } from "@mui/material";

export const ActiveChat = () => {
  const {
    onChange,
    handleKeyDown,
    handleSetExampleMessage,
    message,
    handleSubmit,
    sendMessage,
    messages,
    id,
    name,
    firstLoading,
    data,
    suggestionsLoading,
    suggestionsList,
    hasAnalysesInMessages,
    onStateChange,
    isExpandedList,
  } = useActiveChat();

  return (
    <>
      {suggestionsLoading ? (
        <LoadingScreen wrapperSx={{ height: "calc(100svh - 100px)" }} />
      ) : (
        <>
          <ExportAnalyseContentDialog />
          <ActiveChatWrapper>
            {!messages.length && !!Object.keys(suggestionsList)?.length && (
              <SuggestionsListScreen {...{ suggestionsList, name, sendMessage }} />
            )}
            {!!messages.length && (
              <MessageList {...{ sendMessage, id, messages, firstLoading, userData: data }} />
            )}

            <Stack gap="10px">
              {hasAnalysesInMessages && (
                <SeeMoreAccordion {...{ suggestionsList, sendMessage, onStateChange, isExpandedList }} />
              )}
              <ChatInput
                label="what do you want to do..."
                name="chat"
                {...{
                  onChange,
                  handleKeyDown,
                  handleSetExampleMessage,
                  message,
                  handleSubmit,
                  hasSeeMore: hasAnalysesInMessages,
                  sendMessage,
                }}
              />
            </Stack>
          </ActiveChatWrapper>
        </>
      )}
    </>
  );
};
