import { useEffect, useState } from "react";

import { AnalyseTable } from "../AnalyseTable";

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { cl } from "../AnalyseGraph/styles";
import { ArrowDown } from "Icons/ArrowDown";

export const MultivariateAccordion = ({ data }) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    setKeys(Object.keys(data));
  }, [data]);

  return (
    <Box>
      {!!keys.length &&
        keys.map((key, index) => (
          <Accordion
            key={index}
            defaultExpanded={!index}
            sx={{
              ...cl.accordion,
              "&.Mui-expanded": {
                margin: "0 0 10px!important",
              },
            }}
            PaperProps={{
              sx: cl.accordionPaper,
            }}
          >
            <AccordionSummary expandIcon={<ArrowDown />} sx={cl.accordionSummary}>
              <Typography>{key}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={cl.accordionDetails}>
              <AnalyseTable data={data[key] || []} />
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};
