import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const login = ({ email, password }) => {
  let formData = new FormData();
  formData.append("username", email);
  formData.append("password", password);
  return axios.post(`${API_URL}/user/login/`, formData);
};

export const createUser = (data) => {
  return axios.post(`${API_URL}/user/create/`, data);
};

export const updateUser = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.patch(`${API_URL}/user/update/`, data);
};

export const createGoogleUser = (cred) => {
  return axios.post(
    `${API_URL}/user/verify-google-login?credential=${cred}`,
    cred
  );
};

export const getProfile = () => {
  const sessionToken = localStorage.getItem("session_token");

  return axios.get(`${API_URL}/user/me/`, {
    headers: {
      Authorization: "Bearer " + sessionToken,
    },
  });
};
