import {
  Slider,
  Grid,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const ValueBlock = styled(Box)(
  () => ({
    height: '16px',
    borderRadius: '5px',
    background: '#E6EAEE',
    fontSize: '12px',
    fontWeight: 400,
    color: '#3C475D',
    padding: '5px 8px',
    marginLeft: '10px'
  })
)


export function EditGraphSlider({value, onChange, min, max, seps, name, step }) {

  const [_value, setValue] = useState(value);

  const handleChange = () => {
    const event = {
      target: {
        name: name,
        value: _value
      }
    }
    onChange(event);
  }
  
  return (
    <Grid container>
      <Grid item xs={9}>
        <Slider
          name={name}
          size='small'
          step={step}
          onChange={(e) => setValue(e.target.value)}
          onMouseUp={handleChange}
          value={_value}
          steps={seps}
          min={min}
          max={max}
          sx={{color: '#2984FF'}}
        />
      </Grid>
      <Grid item xs={3}>
        <ValueBlock>{_value}</ValueBlock>
      </Grid>
    </Grid>
    

  )
}