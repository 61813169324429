import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Box,
  Checkbox,
  Button,
} from "@mui/material";

import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GenieIcon } from "../../components/genie-icon";

const EditButton = styled(Button)(() => ({
  minWidth: "90px",
  color: "#000",
  float: "left",
  position: "absolute",
  right: 70,
  top: 25,
}));

export const AnalyseGraphs = ({
  data,
  treeView,
  handleSelectTreeView,
  handleCloseEdit,
}) => {
  const [graphs, setGraphs] = useState([]);

  useEffect(() => {
    setGraphs(Object.keys(data));
  }, [data]);

  const handleOpenEdit = (graph) => (e) => {
    e.stopPropagation();
    let route;
    const editableGraph = treeView.data.find((item) => item.name === graph);
    if (!editableGraph && graph === "heatmap") {
      route = treeView.route.split("*");
    } else {
      route = editableGraph.route.split("*");
    }
    handleCloseEdit(route);
  };

  return (
    <>
      {graphs.map((graph, index) => (
        <Accordion key={index} defaultExpanded={!index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Checkbox
              checked={
                treeView?.data?.find((item) => item.name === graph)?.selected ||
                treeView.selected
              }
              aria-label={graph}
              onChange={handleSelectTreeView(
                treeView?.data?.find((item) => item.name === graph)?.route ||
                  treeView.route
              )}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
            />
            <Typography lineHeight={2.75}>
              <b>{graph === "null" ? "heatmap" : graph}</b>
            </Typography>
            <EditButton
              sx={{ background: "#E1E0E6" }}
              variant="contained"
              size="small"
              startIcon={
                <GenieIcon
                  iconWidth="15px"
                  iconHeight="15px"
                  type="edit-black"
                />
              }
              onFocus={(event) => event.stopPropagation()}
              onClick={handleOpenEdit(graph)}
            >
              Edit
            </EditButton>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img style={{ maxWidth: "50%" }} src={data[graph]} alt="graph" />
            </Box>
          </AccordionDetails>
          <AccordionActions></AccordionActions>
        </Accordion>
      ))}
    </>
  );
};
