import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataset, fetchFrequencyByNames } from "../../actions/datasets";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from "@mui/material";

import { GenieIcon } from "../../components/genie-icon";
import { Pagination } from "../../components/pagination";
import { roundNumbers } from "../../helpers/roundNumbers";
import { VariableEditDialog } from "./variableEditDialog";
import { gaClickCallback } from "helpers/common";

export const DataTable = ({ datasetId = null }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [headers, setHeaders] = useState([]);
  const [filters, setFilters] = useState({ page: 0, limit: 25, offset: 0 });
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState(null);

  const data = useSelector((state) => state.datasetsReducer.data);
  const count = useSelector((state) => state.datasetsReducer.count);
  const datasetVarTypes = useSelector((state) => state.datasetsReducer.datasetVarTypes);

  useEffect(() => {
    dispatch(fetchDataset(id || datasetId, filters, datasetId && true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters]);

  useEffect(() => {
    if (data.length) setHeaders(Object.keys(data[0]));
  }, [data]);

  const openEditDialog = (variable) => {
    dispatch(fetchFrequencyByNames(id || datasetId, variable.variable_name));
    setSelectedVariable(variable);
    setOpenEdit(true);
  };

  return (
    !!headers.length && (
      <>
        <TableContainer component={Paper} sx={{ height: "calc(100vh - 270px)" }}>
          <Table stickyHeader sx={{ minWidth: 900 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>
                    <Grid container alignItems={"center"} wrap={"nowrap"} spacing={1}>
                      <Grid item>
                        <b>{header}</b>
                      </Grid>
                      <Grid
                        item
                        onClick={() =>
                          gaClickCallback({
                            label: "view data edit icon (pencil icon)",
                            callback: () => openEditDialog(datasetVarTypes[header]),
                          })
                        }
                      >
                        <GenieIcon type="edit" iconWidth="15px" iconHeight="15px" />
                      </Grid>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  {headers.map((header) => (
                    <TableCell key={header} component="th" scope="row">
                      {roundNumbers(item[header])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination filters={filters} changeFilters={(filters) => setFilters(filters)} count={count} />
        {selectedVariable && (
          <VariableEditDialog
            open={openEdit}
            handleClose={() => setOpenEdit(!openEdit)}
            selectedVariable={selectedVariable}
            datasetId={datasetId}
          />
        )}
      </>
    )
  );
};
