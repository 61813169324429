import { useCallback } from "react";
import { useDrop } from "react-dnd";
import { useSelector, useDispatch } from "react-redux";

import { changeOneMakeAnalysesColumn } from "../../../actions/analyses";

import { GenieIcon } from "../../../components/genie-icon";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { DragableVarible } from "./dragableVarible";

const VariblesContainer = styled(Box)(({ multiple }) => ({
  background: "#F1F5F9",
  border: "1px solid #EAEDF2",
  borderRadius: "12px",
  padding: "16px 0",
  width: "222px",
  maxHeight: "214px",
  minHeight: multiple ? "230px" : "135px",
  marginLeft: "34px",
  marginBottom: multiple ? "34px" : "0px",
}));

const ListContainer = styled(Box)(() => ({
  overflowY: "auto",
  marginTop: "5px",
  padding: "0 16px",
  maxHeight: "230px",
}));

const EmtyVaribles = styled(Box)(() => ({
  width: "185px",
  height: "88px",
  border: "0.5px dashed #8593AD",
  borderRadius: "6px",
  marginTop: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",
  textAlign: "center",
  fontFamily: "Prompt, sans-serif",
  color: "#8593AD",
}));

export function AnalysesColumn({ column, setSelected, selected }) {
  const dispatch = useDispatch();

  const varibles = useSelector(
    (state) => state.analysesReducer.makeAnalysesColumns[column.value]
  );

  const handleDrop = useCallback(
    (item) => {
      if (varibles.length && !column.multiple) return;
      if (selected.length > 1 && !column.multiple) return;
      if (varibles.indexOf(item.varible) > -1) return;
      const sameVaribles = varibles.find((item) => {
        return selected.includes(item);
      });
      if (sameVaribles) return;
      let vars = [...varibles];
      if (selected.length > 0) {
        vars = [...vars, ...selected];
      } else {
        vars.push(item.varible);
      }
      setSelected([]);
      dispatch(changeOneMakeAnalysesColumn(column.value, vars));
    },
    [varibles, column, setSelected, selected, dispatch]
  );

  const [, drop] = useDrop({
    accept: "varible",
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const deleteVarible = (varible) => {
    let vars = [...varibles];
    const index = vars.findIndex(
      (item) => item.variable_name === varible.variable_name
    );
    vars.splice(index, 1);
    dispatch(changeOneMakeAnalysesColumn(column.value, vars));
  };

  return (
    <VariblesContainer ref={drop} multiple={column.multiple}>
      <Typography
        marginLeft={"16px"}
        fontSize={15}
        color={"#474551"}
        fontWeight={600}
      >
        {column.name + ` ${varibles.length ? varibles.length : ""}`}
      </Typography>
      <ListContainer>
        {varibles?.length ? (
          varibles.map((varible, index) => (
            <DragableVarible
              key={"listitem" + index}
              varible={varible}
              deleteVarible={deleteVarible}
            />
          ))
        ) : (
          <EmtyVaribles>
            <GenieIcon
              type="drag-mouse-icon"
              iconWidth="24px"
              iconHeight="24px"
            />
            Drag variable
            <br /> in here
          </EmtyVaribles>
        )}
      </ListContainer>
    </VariblesContainer>
  );
}
