import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dialog } from "@mui/material";
import { CreateNewVariableDialogContent } from "./createNewVariableDialogContent";
import { SaveNewVariableDialogContent } from "./saveNewVariableDialogContent";
import {
  calculateNewVariable,
  saveNewVariable,
} from "../../../services/datasets";
import {
  fetchDataset,
  // fetchFrequency,
  fetchSummary,
} from "../../../actions/datasets";

export function NewVariableDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [expression, setExpression] = useState([]);
  const [newVariableName, setNewVariableName] = useState("");
  const [testData, setTestData] = useState({});
  const [newVaribleValues, setNewVaribleValues] = useState({});
  const [showSaveContent, setShowSaveContent] = useState(false);

  const hadleCreate = () => {
    if (!expression.length || !newVariableName) return;
    let expretionString = "";
    expression.forEach((item) => {
      if (item.type === "variable") {
        expretionString += `$${item.value}`;
      } else if (item.type === "function") {
        let functionString = item.value;
        item.parameters.map((item) => (item = `$${item}`));
        functionString += `(${item.parameters.join()})`;
        expretionString += functionString;
      } else {
        expretionString += item.value;
      }
    });

    calculateNewVariable(id, {
      name: newVariableName,
      expression: expretionString,
    }).then((res) => {
      setTestData(res.data.data);
      setNewVaribleValues(res.data.new_variable);
      setShowSaveContent(true);
    });
  };

  const handleSave = () => {
    saveNewVariable(id, {
      variable: { [newVariableName]: newVaribleValues },
    }).then((res) => {
      dispatch(fetchDataset(id, { limit: 25, offset: 0 }));
      // dispatch(fetchFrequency(id))
      dispatch(fetchSummary(id));
      handleCloseDialog();
    });
  };

  const handleCloseDialog = () => {
    setExpression([]);
    setNewVariableName([]);
    setShowSaveContent(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {showSaveContent ? (
        <SaveNewVariableDialogContent
          testData={testData}
          handleSave={handleSave}
          handleClose={handleCloseDialog}
        />
      ) : (
        <CreateNewVariableDialogContent
          hadleCreate={hadleCreate}
          expression={expression}
          setExpression={setExpression}
          newVariableName={newVariableName}
          setNewVariableName={setNewVariableName}
          handleClose={handleCloseDialog}
        />
      )}
    </Dialog>
  );
}
