
import { useSelector, useDispatch } from 'react-redux';
import { UploadDataset } from './uploadDataset';
import { DatasetSettings } from './datasetSettings';

import { openCreateDialog, openSettingsDialog } from '../../actions/datasets';

export function CreateDataset() {
  const dispatch = useDispatch();
  const openedCreateDialog = useSelector(state => state.datasetsReducer.openedCreateDialog);
  const openedSettingsDialog = useSelector(state => state.datasetsReducer.openedSettingsDialog);

  return (
    <>
      <UploadDataset
        open={openedCreateDialog}
        handleClose={() => dispatch(openCreateDialog(!openedCreateDialog))}
      />
      <DatasetSettings
        open={openedSettingsDialog}
        handleClose={() => dispatch(openSettingsDialog(!openedSettingsDialog))}
      />
    </>
  )
}