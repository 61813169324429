import CloseIcon from "@mui/icons-material/Close";

import { cl } from "./styles";

import { Box, IconButton, Stack, Typography } from "@mui/material";

export const ContentBlockHeader = ({ title, titleElement, handleClose }) => {
  return (
    <Box sx={cl.headerWrapper}>
      {!!title && (
        <Typography variant="h5" sx={cl.title}>
          {title}
        </Typography>
      )}
      {!!titleElement && titleElement}

      <Stack direction="row" gap="10px">
        <IconButton sx={{ p: 0 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};
