import AWS from "aws-sdk";

const REGION = process.env.REACT_APP_AWS_REGION;
const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
const S3_ACCESS_KEY = process.env.REACT_APP_AWS_API_KEY;
const S3_SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;

const MULTIPART_CONFIGS = {
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_KEY,
  region: REGION,
  useAccelerateEndpoint: true,
};

export const uploadDatasetFile = async (
  file,
  datasetId,
  project_id,
  userId
) => {
  AWS.config.update({
    accessKeyId: S3_ACCESS_KEY,
    secretAccessKey: S3_SECRET_KEY,
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: `datasets/${userId}/${project_id}/${datasetId}/${file.name}`,
    Body: file,
  };

  const s3 = new AWS.S3();
  return await s3.upload(params).promise();
};

export const initiateMultipartUpload = async (fileKey) => {
  const s3 = new AWS.S3(MULTIPART_CONFIGS);

  const params = {
    Bucket: S3_BUCKET,
    Key: fileKey,
  };

  try {
    const response = await s3.createMultipartUpload(params).promise();
    return response.UploadId;
  } catch (error) {
    console.error("Error initiating multipart upload:", error);
    throw error;
  }
};

export const uploadPart = async (uploadId, partNumber, data, fileKey) => {
  const s3 = new AWS.S3(MULTIPART_CONFIGS);

  const params = {
    Bucket: S3_BUCKET,
    Key: fileKey,
    UploadId: uploadId,
    PartNumber: partNumber,
    Body: data,
  };

  try {
    const response = await s3.uploadPart(params).promise();
    return {
      PartNumber: partNumber,
      ETag: response.ETag,
    };
  } catch (error) {
    console.error("Error uploading part:", error);
    throw error;
  }
};

export const completeMultipartUpload = async (uploadId, parts, fileKey) => {
  const s3 = new AWS.S3(MULTIPART_CONFIGS);

  const params = {
    Bucket: S3_BUCKET,
    Key: fileKey,
    UploadId: uploadId,
    MultipartUpload: {
      Parts: parts,
    },
  };

  return await s3.completeMultipartUpload(params).promise();
};

export const handleLargeFileUpload = (file, datasetId, project_id, userId) => {
  const fileKey = `datasets/${userId}/${project_id}/${datasetId}/${file.name}`;

  initiateMultipartUpload(fileKey)
    .then((uploadId) => {
      const chunkSize = 5 * 1024 * 1024;
      const parts = [];
      let offset = 0;
      let partNumber = 1;

      function processChunk() {
        const chunk = file.slice(offset, offset + chunkSize);
        offset += chunkSize;

        uploadPart(uploadId, partNumber, chunk, fileKey)
          .then((partData) => {
            parts.push(partData);
            partNumber++;

            if (offset < file.size) {
              processChunk();
            } else {
              console.log("parts", parts);
              completeMultipartUpload(uploadId, parts, fileKey);
            }
          })
          .catch((error) => {
            console.error("Error processing chunk:", error);
          });
      }

      processChunk();
    })
    .catch((error) => {
      console.error("Error initiating multipart upload:", error);
    });
};
