import { configureStore } from "@reduxjs/toolkit";
import projectsReducer from "./projects";
import datasetsReducer from "./datasets";
import analysesReducer from "./analyses";
import chatsReducer from "./chats";
import userReducer from "./user";

export const store = configureStore({
  reducer: {
    projectsReducer,
    datasetsReducer,
    analysesReducer,
    chatsReducer,
    userReducer,
  },
});
