import { useEffect, useState } from "react";

import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";

import { cl } from "./styles";
import { ArrowDown } from "Icons/ArrowDown";
export const AnalyseGraph = ({ data }) => {
  const [graphs, setGraphs] = useState([]);

  useEffect(() => {
    setGraphs(Object.keys(data));
  }, [data]);

  return (
    <>
      {graphs.map((graph, index) => (
        <Accordion key={index} defaultExpanded={!index} sx={cl.accordion} PaperProps={cl.accordionPaper}>
          <AccordionSummary expandIcon={<ArrowDown />} sx={cl.accordionSummary}>
            <Typography>{graph === "null" ? "heatmap" : graph}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={cl.accordionDetails}>
            <Box sx={cl.accordionIcon}>
              <img style={{ width: "100%" }} src={data[graph]} alt="graph" />
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
