import { SvgIcon } from "@mui/material";
import React from "react";

export const DimentionIcon = () => {
  return (
    <SvgIcon
      style={{ width: "24px", height: "24px", fill: "none" }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3 8 21m8-18-2 18M3.5 9h18m-19 6h18"
        stroke="#475467"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
