import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ChatContentWrapper = styled(Box)(() => ({
  width: "100%",
  //padding: "32px 24px 43px 43px",
  height: "100vh",
  overflow: "hidden",
  boxSizing: "border-box",
}));
