import { Fragment, useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { roundNumbers } from '../../../helpers/roundNumbers';


export function SaveNewVariableDialogContent({
  testData,
  handleSave, 
  handleClose
}) {

  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState({})

  useEffect(() => {
    setHeaders(Object.keys(testData))
    let _data = {};
    Object.keys(testData).forEach(header =>{
      _data[header] = Object.values(testData[header])
    })
    setData(_data);
  }, [testData])

  return (
    <Fragment>
      <DialogTitle>
        Create a new variable
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header}>
                     {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data[headers[0]]?.map((item, index) => (
                <TableRow key={index}>
                  {headers.map(header => (
                    <TableCell key={header} component="th" scope="row">{roundNumbers(data[header][index])}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color='info' onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Fragment>
  )
}