import { CEO_BLOCK_DATA } from "constants/common.constants";

import CEOImage from "images/CEO.png";

import { Box, Stack, Typography } from "@mui/material";
import { CheckIcon } from "Icons/CheckIcon";

export const Founder = () => {
  return (
    <Stack
      direction="row"
      p="80px"
      alignItems="center"
      gap="60px"
      justifyContent="space-between"
      width="100%"
      id="out_team"
    >
      <Stack width="40%">
        <Box
          component="img"
          ml="auto"
          height="100%"
          width="100%"
          sx={{ borderRadius: "8px" }}
          src={CEOImage}
          alt="about-us-image"
        />
      </Stack>
      <Stack gap="16px" width="55%">
        <Typography variant="h2">{CEO_BLOCK_DATA.title}</Typography>
        <Typography variant="caption">{CEO_BLOCK_DATA.captureText}</Typography>
        <Stack gap="32px">
          {CEO_BLOCK_DATA.description.map((item, index) => (
            <Stack key={index} direction="row" alignItems="center" gap="12px">
              <CheckIcon />

              <Typography lineHeight="28px" fontWeight="400" color="#475467" fontSize="18px">
                {item}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
