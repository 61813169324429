import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GenieIcon } from '../../components/genie-icon';
import { useSelector } from 'react-redux';

const OwerviewStatsIcon = styled('div')(
  (props) => ({
    width: '45px',
    height: '45px',
    border: `1px solid ${props.color}`,
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 33,
    right: 33
  })
);

const LastModifiedName = styled(Typography)(
  () => ({
    fontWeight: 600,
    fontSize: '16px',
    color: '#92A0B9'
  })
)

const LastModifiedNumber = styled(Typography)(
  () => ({
    fontWeight: 600,
    fontSize: '18px',
    color: '#323B4E',
    maxWidth: '140px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'

  })
)

const GridContainer = styled(Grid)(
  () => ({
    paddingTop: '20px',
    flexWrap: 'nowrap',
    width: 'auto'
  })
)

const stats = [
  {
    type: 'projects',
    icon: 'project-orange',
    color: '#F2994A',
  },
  {
    type: 'analyses',
    icon: 'analyses-blue',
    color: '#2D9CDB',
  },
  {
    type: 'datasets',
    icon: 'dataset-green',
    color: '#27AE60',
  }
]

export const OwerviewStats = ({only}) => {
  const navigate = useNavigate()

  const [analyse, setAnalyse] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [project, setProject] = useState([]);

  const analyses = useSelector(state => state.analysesReducer.list);
  const datasets = useSelector(state => state.datasetsReducer.list);
  const projects = useSelector(state => state.projectsReducer.list);

  useEffect(() => {
    findLastModified([...analyses], setAnalyse, 'analyses');
    findLastModified([...datasets], setDataset, 'datasets');
    findLastModified([...projects], setProject, 'projects');
  }, [analyses, datasets, projects])

  const findLastModified = (data, setData, type) => {
    let sortedData = data.sort((a, b) => {
      const t1 = new Date(a?.modified_at || a.created_at).getTime();
      const t2 = new Date(b.modified_at || b.created_at).getTime();
      return t1 < t2 ? 1 : -1;
    })

    sortedData = sortedData.map(item => {
      return {
        ...item,
        data_type: type,
        color: stats.find(stat => stat.type === type).color,
        icon: stats.find(stat => stat.type === type).icon
      };
    })
    
    setData(sortedData);
  }

  const getSorting = (analyse, dataset, project) => {
    let data = []
    if(only === 'projects') {
      data = project
    } else if(only === 'datasets') {
      data = dataset
    } else if(only === 'analyses') {
      data = analyse
    } else {
      data = analyse.concat(dataset, project)
    }

    data.sort((a, b) => {
      const t1 = new Date(a?.modified_at || a.created_at).getTime();
      const t2 = new Date(b?.modified_at || b.created_at).getTime();
      return t1 < t2 ? 1 : -1;
    })
    return data
  }

  return(
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        overflowY: 'auto',
        mt: '30px',
        mb: '35px',
        minWidth: '100%',
        minHeight: '160px',
        '& > :not(style)': {
          m: 1,
          p: '33px',
          width: '215px',
          minWidth: '180px',
          height: '77px',
          backgroundColor: '#fff',
          borderRadius: '12px',
          border: 'none',
          position: 'relative'
        },
      }}
    >
      {getSorting(analyse, dataset, project).map((stat, index) => (
        <Paper key={index} variant="outlined" >
          <OwerviewStatsIcon color={stat.color}>
            <GenieIcon type={stat.icon} iconWidth='18px' iconHeight='18px' />
          </OwerviewStatsIcon>
          <>
            <LastModifiedName>{stat.project_name || stat.name}</LastModifiedName>
            {stat.data_type === 'projects' && (
              <GridContainer container direction={'row'} spacing={2} onClick={() => navigate(`/dashboard/project/${stat.id}`)}>
                <Grid xs="auto" item container spacing={1} alignItems={'center'}>
                  <Grid item><GenieIcon type="dataset-green"/></Grid>
                  <Grid item><LastModifiedNumber>{stat.datasets_count}</LastModifiedNumber></Grid>
                </Grid>
                <Grid xs="auto" item container spacing={1} alignItems={'center'}>
                  <Grid item><GenieIcon type="analyses-blue"/></Grid>
                  <Grid item><LastModifiedNumber>{stat.analyses_count}</LastModifiedNumber></Grid>
                </Grid>
              </GridContainer>
            )}
            {stat.data_type === 'datasets' && (
              <GridContainer container direction={'row'} spacing={2} onClick={() => navigate(`/dashboard/dataset/${stat.id}`)}>
                <Grid xs="auto" item container spacing={1} alignItems={'center'}>
                  <Grid item><GenieIcon type="analyses-blue"/></Grid>
                  <Grid item><LastModifiedNumber>{stat.analyses_count}</LastModifiedNumber></Grid>
                </Grid>
                <Grid xs="auto" item container spacing={1} alignItems={'center'}>
                  <Grid item>col:</Grid>
                  <Grid item><LastModifiedNumber>{stat.number_of_columns}</LastModifiedNumber></Grid>
                </Grid>
                <Grid xs="auto" item container spacing={1} alignItems={'center'}>
                  <Grid item>row:</Grid>
                  <Grid item><LastModifiedNumber>{stat.number_of_rows}</LastModifiedNumber></Grid>
                </Grid>
              </GridContainer>
            )}
            {stat.data_type === 'analyses' && (
              <GridContainer container direction={'row'} spacing={2} onClick={() => navigate(`/dashboard/analyse/${stat.id}#table`)}>
                <Grid item container spacing={1} alignItems={'center'}>
                <Grid item>type:</Grid>
                  <Grid item><LastModifiedNumber>{stat.analysis_type}</LastModifiedNumber></Grid>
                </Grid>
              </GridContainer>
            )}
          </>
        </Paper>
      ))}
    </Box>
  )
};