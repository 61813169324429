export const HeaderLinks = [
  {
    title: "About",
    route: "about",
  },
  {
    title: "Our Team",
    route: "out_team",
  },
  {
    title: "Analysis",
    route: "analysis",
  },
];
