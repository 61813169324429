import { useEffect, useRef } from 'react';
import { BoxPlotChart } from '@sgratzl/chartjs-chart-boxplot';

export const BoxPlot = ({data, variable}) => {

  const chartRef = useRef()

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const myChart = new BoxPlotChart(ctx, {
      type: 'boxplot',
      data: data,
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Chart.js Box Plot Chart',
        },
      },
    });
    return () => myChart.destroy();
  },[])

  return (
    <canvas ref={chartRef} id={`chart${variable.variable_name}`} width="600" maxHeight="325"></canvas>
  )
}
