import { SvgIcon } from "@mui/material";
import React from "react";

export const PauseIcon = ({ styles, ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "44px", height: "44px", fill: "none", ...styles }}
      viewBox="0 0 44 44"
      {...props}
    >
      <rect x={0.5} y={0.5} width={43} height={43} rx={9.5} fill="#fff" />
      <rect x={0.5} y={0.5} width={43} height={43} rx={9.5} stroke="#7F56D9" />
      <path
        d="M16.8 13h-.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C13 14.52 13 15.08 13 16.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C14.52 31 15.08 31 16.2 31h.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C20 29.48 20 28.92 20 27.8V16.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C18.48 13 17.92 13 16.8 13m11 0h-.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C24 14.52 24 15.08 24 16.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C25.52 31 26.08 31 27.2 31h.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C31 29.48 31 28.92 31 27.8V16.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C29.48 13 28.92 13 27.8 13"
        stroke="#7F56D9"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
