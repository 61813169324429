import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { PageTitle } from '../../components/pageTitle';
import { ProjectTabs } from './projectTabs';
import { GenieIcon } from '../../components/genie-icon';

import { fetchProject } from '../../actions/projects';
import { openCreateDialog } from '../../actions/datasets';
import { changeFilters as changeDatasetFilters, clearFilters as clearDatasetFilters } from '../../actions/datasets';
import { changeFilters as changeAnalyseFilters, clearFilters as clearAnalysesFilters } from '../../actions/analyses';


export default function Project() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const project = useSelector(state => state.projectsReducer.selected);
  const datasetFilters = useSelector(state => state.datasetsReducer.filters);
  const analyseFilters = useSelector(state => state.analysesReducer.filters);

  useEffect(() => {
    const _datasetFilters = {...datasetFilters, project_id: id};
    const _analyseFilters = {...analyseFilters, project_id: id};
    dispatch(fetchProject(id));
    dispatch(changeDatasetFilters(_datasetFilters));
    dispatch(changeAnalyseFilters(_analyseFilters));

    return () => {
      dispatch(clearDatasetFilters());
      dispatch(clearAnalysesFilters());
    }
  }, [])

  return (
    <Box>
      <PageTitle title={'Project: ' + project.project_name} />
      <Box sx={{marginTop: '120px', marginBottom: '20px'}}>
        <Button
          onClick={(e) => {e.stopPropagation(); dispatch(openCreateDialog(true))}}
          variant="contained"
          color="secondary"
          sx={{ minWidth: '100px', margin: '0 15px', float: 'right'}}
          size="small" 
          startIcon={<GenieIcon iconWidth='15px' iconHeight='15px' type="plus"/>}
        >
          Add Dataset
        </Button>
      </Box>
      <ProjectTabs />
    </Box>
  )
}