import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddDatasetBtn, EmptyScreenWrapper, ScreenTitle } from "./styles";

import { PlusIcon } from "Icons/PlusIcon";

import { openCreateDialog } from "actions/datasets";
import { TableHeader } from "./components/TableHeader";
import { DatasetsList } from "./components/DatasetsList";
import { useDatasetSummary } from "./useDatasetSummary";
import { gaClickCallback } from "helpers/common";

export const NoneSelectedChatScreen = () => {
  const { data } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const { datasetList, search, onDatasetSearch, loading } = useDatasetSummary();

  return (
    <EmptyScreenWrapper>
      <ScreenTitle variant="h2" mb="24px">
        Welcome back, {data?.first_name}!
      </ScreenTitle>
      <TableHeader {...{ search, onDatasetSearch }} />
      <DatasetsList list={datasetList} {...{ loading }} />
      <AddDatasetBtn
        startIcon={<PlusIcon />}
        variant="contained"
        onClick={() =>
          gaClickCallback({
            label: "Add New Dataset on Welcome back screen",
            callback: () => dispatch(openCreateDialog(true)),
          })
        }
      >
        Add New Dataset
      </AddDatasetBtn>
    </EmptyScreenWrapper>
  );
};
