import {
  SET_CHATS,
  SET_ERRORS,
  SET_ACTIVE_CHAT,
  SET_DEFAULT_ACTIVE_CHAT,
  SET_ACTIVE_CHAT_MESSAGES,
  SET_ACTIVE_DATASET,
  SET_EXPORT_DIALOG,
  RESET_CHATS,
} from "../actions/chats";

export const EXPORT_TYPES = ["tables", "graphs"];

export const initialState = {
  list: [],
  filters: {
    project_id: null,
    limit: 50,
    offset: 0,
    page: 0,
  },
  chatByIdFilters: {
    limit: 25,
    offset: 0,
  },
  data: [],
  count: 0,
  errors: [],
  activeDataset: {},
  activeChat: {
    id: null,
    messages: [],
    name: "",
    dataset_id: null,
    analysis_id: null,
  },
  openedExportDialog: "",
  exportAnalysis_id: null,
};

const chatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS:
      return { ...state, list: action.list, count: action.count };

    case SET_ERRORS:
      return { ...state, errors: action.errors };

    case SET_ACTIVE_CHAT:
      return { ...state, activeChat: action.activeChat };

    case SET_ACTIVE_CHAT_MESSAGES:
      return {
        ...state,
        activeChat: { ...state.activeChat, messages: action.action },
      };

    case SET_DEFAULT_ACTIVE_CHAT:
      return { ...state, activeChat: initialState.activeChat };

    case SET_ACTIVE_DATASET:
      return { ...state, activeDataset: action.activeDataset };

    case SET_EXPORT_DIALOG:
      return {
        ...state,
        openedExportDialog: action.openedExportDialog,
        exportAnalysis_id: action.exportAnalysis_id,
      };

    case RESET_CHATS:
      return { ...initialState };

    default:
      return state;
  }
};

export default chatsReducer;
