import { GA4_ACTION_TYPES, GA4_ACTIONS } from "constants/common.constants";
import ReactGA from "react-ga4";

export const normalizeUppercaseString = (str) => {
  if (str) return `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`;
  return "";
};

export const normalizeSnakeCaseString = (str) => {
  if (!str || typeof str !== "string") return;
  const splitStr = str.split("_");

  return splitStr.map((s) => normalizeUppercaseString(s)).join(" ");
};

export const filterDatasetByStatus = (data) => {
  const statusesFilter = ["in_progress", "failed"];

  if (!data) return [];
  return data.filter((item) => !statusesFilter.includes(item.status));
};

export const downloadFile = (url, fileName = "") => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const gaClickCallback = ({
  type = GA4_ACTION_TYPES.BUTTON,
  action = GA4_ACTIONS.CLICK,
  label = "",
  callback = () => {},
}) => {
  ReactGA.event({
    category: type,
    action,
    label,
  });
  !!callback && callback();
};
