import { SvgIcon } from "@mui/material";
import React from "react";

export const LogOutIcon = ({ ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px", fill: "none" }}
      viewBox="0 0 0.6 0.6"
      className="icon glyph"
      {...props}
    >
      <path
        d="M.375.413v.062a.05.05 0 0 1-.05.05H.15A.05.05 0 0 1 .1.475v-.35a.05.05 0 0 1 .05-.05h.175a.05.05 0 0 1 .05.05v.077M.275.3h.25m0 0L.462.237M.525.3.462.363"
        stroke="#475467"
        strokeWidth=".05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
