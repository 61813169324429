import { useState } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GenieIcon } from '../genie-icon';
import { CreateProject } from '../createProject';
import { CreateDataset } from '../createDataset/index'

const CreateProjectButtonContainer = styled(Button)(
  () => ({
      borderRadius: '8px',
      background: '#F3F5FA',
      position: 'relative',
      height: '50px',
      margin: '10px 0',
      display: 'block',
      width: '100%',
      minWidth: '100%'
  })
);

const CreateProjectButtonLabel = styled('div')(
  () => ({
    width: '70px',
    fontSize: '12px',
    margin: '0px',
    paddingLeft: '10px',
    fontWeight: 600,
    lineHeight: '14px',
    textAlign: 'left'
  })
);

const CreateProjectButton = styled('div')(
  () => ({
    position: 'absolute',
    background: '#2984FF',
    width: '30px',
    height: '30px',
    borderRadius: '9px',
    boxShadow: '0px 4px 4px rgba(100, 161, 94, 0.15)',
    top: '50%',
    right: '12px',
    transform: 'translate( -0%, -50%)'
  })
);

const CreateProjectButtonIcon = styled('div')(
  () => ({
    width: '11px',
    height: '11px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  })
)

export const CreateButton = ({buttonName, open}) => {
  const [openProjectCreate, setOpenProjectCreate] = useState(false);
  return (
    <>
      <CreateProjectButtonContainer onClick={() => setOpenProjectCreate(true)}>
        {open && (<CreateProjectButtonLabel>{buttonName}</CreateProjectButtonLabel>)}
        <CreateProjectButton>
          <CreateProjectButtonIcon>
            <GenieIcon type="plus" iconWidth='11px' iconHeight='11px'/>
          </CreateProjectButtonIcon>
        </CreateProjectButton>
      </CreateProjectButtonContainer>
      <CreateProject open={openProjectCreate} handleClose={() => setOpenProjectCreate(!openProjectCreate)} />
      <CreateDataset />
    </>
  );
};
