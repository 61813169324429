import { ListItem, List } from "@mui/material";
import { styled } from "@mui/system";

export const ListContainer = styled(List)(() => ({
  display: "flex",
  padding: 0,
  gap: "32px",
}));

export const StyledListItem = styled(ListItem)(() => ({
  width: "100%",
  padding: 0,
  textWrap: "nowrap",
  cursor: "pointer !important",
  fontWeight: 600,
  transition: "0.2s",
  color: "#475467",
  fontSize: "16px",
  lineHeight: "24px",

  "&:hover": {
    color: "#6941C6",
    transition: "0.2s",
  },
}));
