import { Fragment, useCallback, useEffect, useState } from "react";

import { TabsSet } from "../../components/tabs-set";
import { useSelector } from "react-redux";
import { AnalyseTable } from "./analyseTable";
import { MultivariateTabels } from "./multivariateTables";
import { ExportTables } from "./exportTables";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Grid,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const AnalyseTablesTabs = () => {
  const [tabs, setTabs] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const data = useSelector(
    (state) => state.analysesReducer.analyse?.summary?.data
  );
  const analysisType = useSelector(
    (state) => state.analysesReducer.analyse?.analysis_type
  );

  const handleSelect = useCallback(
    (route) => (e) => {
      let _selectedTables = [...selectedTables];
      if (e.target.checked) {
        _selectedTables.push(route);
      } else {
        const index = _selectedTables.findIndex((item) => item === route);
        _selectedTables.splice(index, 1);
      }
      setSelectedTables(_selectedTables);
    },
    [selectedTables]
  );

  useEffect(() => {
    if (analysisType === "compare_groups") {
      setTabs(compareGroupsTabs(data));
    }

    if (analysisType === "correlations") {
      setTabs(correlationsTabs(data, handleSelect, selectedTables));
    }

    if (analysisType === "survival") {
      setTabs(survivalTabs(data));
    }

    if (
      analysisType === "mixed_multivariate" ||
      analysisType === "multivariate"
    ) {
      setTabs(multivariateTabs(data, analysisType));
    }
  }, [data, analysisType, selectedTables, handleSelect]);

  return (
    !!tabs.length && (
      <Fragment>
        {analysisType !== "mixed_multivariate" &&
          analysisType !== "multivariate" && (
            <Grid
              container
              justifyContent={"start"}
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Grid item>
                <Button
                  sx={{ background: "#1CB4AE", marginBottom: "40px" }}
                  variant="contained"
                  size="small"
                  onClick={() => setOpenExport(true)}
                >
                  Export
                </Button>
              </Grid>
            </Grid>
          )}
        <TabsSet tabs={tabs} search={false} handleChecked={handleSelect} />
        <ExportTables
          open={openExport}
          handleClose={() => setOpenExport(!openExport)}
          selectedTables={selectedTables}
          mode="all_models"
        />
      </Fragment>
    )
  );
};

const compareGroupsTabs = (data) => {
  let compareGroupsTabs = [];
  if (data?.all) {
    let keys = Object.keys(data?.all?.tables);
    compareGroupsTabs = keys.map((table) => {
      return {
        name: "all:" + table,
        component: <AnalyseTable data={data.all.tables[table]} />,
        checkedRoute: `compare_groups*all*${table}`,
      };
    });
  } else {
    let keys = Object.keys(data);
    keys.forEach((key) => {
      let keys2 = Object.keys(data[key].tables);
      keys2.forEach((key2) => {
        compareGroupsTabs.push({
          name: `${key}:${key2}`,
          component: <AnalyseTable data={data[key].tables[key2]} />,
          checkedRoute: `compare_groups*${key}*${key2}`,
        });
      });
    });
  }
  return compareGroupsTabs;
};

const correlationsTabs = (data, handleSelect, selectedTables) => {
  let correlationsTabs = [];
  if (data?.all) {
    let correlationData = {
      matrix: data.all.matrix,
    };
    let keys = Object.keys(data.all.tables);
    keys.forEach((key) => {
      correlationData[key] = data.all.tables[key];
    });
    correlationsTabs.push({
      name: "all",
      component: (
        <CorelationAcardion
          data={correlationData}
          checkedRoute={`correlations*all`}
          handleSelect={handleSelect}
        />
      ),
    });
  } else {
    let keys = Object.keys(data);
    keys.forEach((key) => {
      let correlationData = {
        matrix: data[key].matrix,
      };
      let keys2 = data[key]?.tables ? Object.keys(data[key]?.tables) : null;
      keys2?.forEach((key2) => {
        correlationData[key2] = data[key].tables[key2];
      });
      correlationsTabs.push({
        name: `${key}`,
        component: (
          <CorelationAcardion
            data={correlationData}
            checkedRoute={`correlations*${key}`}
            handleSelect={handleSelect}
          />
        ),
      });
    });
  }
  return correlationsTabs;
};

const survivalTabs = (data) => {
  let survivalTabs = [];
  if (data?.all) {
    let keys = Object.keys(data.all.tables);
    survivalTabs = keys.map((table) => {
      return {
        name: "all:" + table,
        component: <AnalyseTable data={data.all.tables[table].table} />,
        checkedRoute: `survival*all*${table}`,
      };
    });
  } else {
    let keys = Object.keys(data);
    keys.forEach((key) => {
      let keys2 = Object.keys(data[key].tables);
      keys2.forEach((key2) => {
        survivalTabs.push({
          name: `${key}:${key2}`,
          component: <AnalyseTable data={data[key].tables[key2].table} />,
          checkedRoute: `survival*${key}*${key2}`,
        });
      });
    });
  }
  return survivalTabs;
};

const multivariateTabs = (data, analysisType) => {
  let multivariateTabs = [
    {
      name: "Main Independent Variable",
      component: (
        <MultivariateTabels
          data={data}
          independent={true}
          analysisType={analysisType}
        />
      ),
    },
    {
      name: "All Models",
      component: (
        <MultivariateTabels
          data={data}
          independent={false}
          analysisType={analysisType}
        />
      ),
    },
  ];
  return multivariateTabs;
};

const CorelationAcardion = ({ data, checkedRoute, handleSelect }) => {
  const [keys, setKeys] = useState([]);
  useEffect(() => {
    setKeys(Object.keys(data));
  }, [data]);

  return keys.map((key, index) => (
    <Accordion key={index} defaultExpanded={!index} sx={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {key !== "matrix" && (
          <Checkbox
            onChange={handleSelect(checkedRoute + "*" + key)}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
          />
        )}
        <Typography lineHeight={2.75}>
          <b>{key}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AnalyseTable data={data[key]} />
      </AccordionDetails>
    </Accordion>
  ));
};
