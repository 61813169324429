import { ABOUT_SCREEN_BLOCK_DATA } from "constants/common.constants";

import { cl } from "./styles";
import { SubTitleText, TitleText } from "../styles";

import { Box, Stack } from "@mui/material";
import { AnalysesItem } from "./components/AnalysesItem";
import { AnalysesSlider } from "./components/AnalysesSlider";

export const AboutAnalysesScreen = () => {
  return (
    <Stack
      direction="column"
      gap="100px"
      width="100%"
      sx={{ backgroundColor: "#F9FAFB" }}
      p="102px 80px 80px"
      id="analysis"
    >
      {ABOUT_SCREEN_BLOCK_DATA.map(({ title, img, description }, index) => (
        <Stack
          key={index}
          direction="row"
          gap="70px"
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse" }}
        >
          <Box width={img.length > 1 ? "calc(55% + 48px)" : "55%"} sx={cl.imageContainer}>
            {img.length > 1 ? <AnalysesSlider images={img} /> : <AnalysesItem url={img[0]} alt={title} />}
          </Box>
          <Stack width="40%" gap="4px">
            <TitleText sx={cl.titleText}>{title}</TitleText>
            <SubTitleText sx={cl.descriptionText}>{description}</SubTitleText>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
