import { SubTitleText, TitleText } from "../styles";

import { Box, Button, Stack } from "@mui/material";
import { HERO_VIDEO_URL } from "constants/common.constants";
import { gaClickCallback } from "helpers/common";

export const WelcomeScreen = ({ setOpenRegister }) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      gap="20px"
      height="calc(100svh - 104.5px)"
      px="80px"
    >
      <Stack justifyContent="center" width="40%" gap="24px">
        <TitleText>
          Data to Insights <br /> Faster than Ever
        </TitleText>
        <SubTitleText>Upload your data, type your question Let Genie work its magic for you!</SubTitleText>

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "24px", width: "225px", height: "60px", fontSize: "16px" }}
          onClick={() =>
            gaClickCallback({
              label: "Get Started For Free",
              callback: () => setOpenRegister(true),
            })
          }
        >
          Get Started For Free
        </Button>
      </Stack>
      <Stack
        width="55%"
        justifyContent="center"
        alignItems="end"
        sx={{
          aspectRatio: "16 / 9",
          zIndex: 100,
          maxHeight: "70%",
          height: "100%",
          border: "1px #EAECF0 solid",
          //border: "4px solid black",
          borderRadius: "20px",
          overflow: "hidden",
          "& video": { width: "100%", height: "100%" },
        }}
      >
        <Box
          component="video"
          autoPlay
          muted
          src={HERO_VIDEO_URL}
          alt="hero"
          loop
          onEnded={(e) => {
            e.currentTarget.play();
          }}
        />
      </Stack>
    </Stack>
  );
};
