import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { PageTitle } from "../../components/pageTitle";
import { AnalyseTablesTabs } from "./analyseTableTabs";
import { AnalyseGraphsTabs } from "./analyseGraphsTabs";
import { MultivariateGraphs } from "./multivariateGraphs";
import { EditGraph } from "./editGraph";
import {
  clearAnalyse,
  fetchAnalyse,
  setSelectGraphImage,
} from "../../actions/analyses";
import { Button, Grid, Box, Typography } from "@mui/material";
import { GenieIcon } from "../../components/genie-icon";
import { TabsSet } from "../../components/tabs-set";
import { AnalysesDialogs } from "../dataset/analysesDialog";
import { fetchDataset } from "../../actions/datasets";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AnalyseTable } from "./analyseTable";

export default function Analyse() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableOrGraphs, setTableOrGraphs] = useState("");
  const [openEditGraph, setOpenEditGraph] = useState(false);
  const [editGraphRoute, setEditGraphRoute] = useState(null);
  const [editAnalysis, setEditAnalysis] = useState(false);
  const { id } = useParams();
  const analyse = useSelector((state) => state.analysesReducer.analyse);
  const analysisType = useSelector(
    (state) => state.analysesReducer.analyse?.analysis_type
  );

  useEffect(() => {
    dispatch(fetchAnalyse(id));
    const { hash } = window.location;
    setTableOrGraphs(hash);
    return () => dispatch(clearAnalyse());
  }, [dispatch, id]);

  const handleCloseEditGraph = (graphRoute) => {
    if (graphRoute) setEditGraphRoute(graphRoute);
    if (openEditGraph) dispatch(setSelectGraphImage(""));
    setOpenEditGraph((prev) => !prev);
  };

  const handleEditAnalysis = () => {
    dispatch(fetchDataset(analyse.dataset_id));
    setEditAnalysis(!editAnalysis);
  };

  return (
    <>
      <PageTitle title={analyse.name} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "15px",
          cursor: "pointer",
        }}
        onClick={() => handleEditAnalysis()}
      >
        <ArrowBackIosIcon />
        <Typography>Go Back</Typography>
      </Box>
      <Grid
        container
        justifyContent={"end"}
        spacing={2}
        sx={{ width: "100%", marginBottom: "-30px", marginTop: "50px" }}
      >
        <Grid item>
          <Button
            sx={{ background: "#2D9CDB" }}
            variant="contained"
            size="small"
            startIcon={
              <GenieIcon iconWidth="15px" iconHeight="15px" type="tables" />
            }
            onClick={() => {
              setTableOrGraphs("#table");
              navigate(`/dashboard/analyse/${id}#table`);
            }}
          >
            Tables
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ background: "#9B51E0" }}
            variant="contained"
            size="small"
            startIcon={
              <GenieIcon
                iconWidth="15px"
                iconHeight="15px"
                type="chart-white"
              />
            }
            onClick={() => {
              setTableOrGraphs("#graph");
              navigate(`/dashboard/analyse/${id}#graph`);
            }}
          >
            Graphs
          </Button>
        </Grid>
      </Grid>
      <Box flex="1 1 auto" height="100%" overflow="auto">
        {tableOrGraphs === "#table" && <AnalyseTablesTabs />}
        {tableOrGraphs === "#graph" &&
          analysisType !== "mixed_multivariate" && (
            <AnalyseGraphsTabs handleCloseEdit={handleCloseEditGraph} />
          )}
        {(analysisType === "mixed_multivariate" ||
          analysisType === "multivariate") &&
          tableOrGraphs === "#graph" && (
            <TabsSet
              tabs={[
                {
                  name: "Main Independent Variable",
                  component: (
                    <MultivariateGraphs
                      independent={true}
                      handleCloseEdit={handleCloseEditGraph}
                    />
                  ),
                },
                {
                  name: "All Models",
                  component: (
                    <MultivariateGraphs
                      handleCloseEdit={handleCloseEditGraph}
                    />
                  ),
                },
              ]}
            />
          )}
        {analysisType === "screen_test" && (
          <Box mt={-1}>
            <AnalyseTable data={analyse.summary.data.all} />
          </Box>
        )}
        <EditGraph
          route={editGraphRoute}
          open={openEditGraph}
          handleClose={handleCloseEditGraph}
        />
        {editAnalysis && <AnalysesDialogs isEdit={editAnalysis} />}
      </Box>
    </>
  );
}
