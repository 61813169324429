import { SvgIcon } from "@mui/material";
import React from "react";

export const PencilIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px", fill: "none" }}
      viewBox="0 0 20 20"
    >
      <path
        d="m2.083 17.917 4.624-1.779c.296-.114.444-.17.582-.245q.185-.1.35-.227c.125-.097.237-.209.46-.433l9.4-9.4A2.357 2.357 0 1 0 14.167 2.5l-9.4 9.4c-.224.224-.336.336-.432.46a2.5 2.5 0 0 0-.227.35 5 5 0 0 0-.245.582zm0 0 1.715-4.46c.123-.319.184-.478.29-.551a.42.42 0 0 1 .315-.067c.126.024.247.145.489.386l1.882 1.883c.242.242.363.363.387.489a.42.42 0 0 1-.067.315c-.073.105-.233.167-.552.29z"
        stroke="#475467"
        strokeWidth={1.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
