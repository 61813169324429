import { styled } from '@mui/material/styles';
import { GenieIcon } from '../genie-icon';

const OpenCloseDrawerOpen = styled('div')(
  () => ({
    width: '18px',
    height: '40px',
    backgroundColor: '#B0BFD2',
    borderRadius: '0px 8px 8px 0px',
    position: 'absolute',
    right: '-34px',
    top: '80px',
    zIndex: 1290
  })
);

const OpenCloseDrawerClose = styled('div')(
  () => ({
    width: '18px',
    height: '40px',
    backgroundColor: '#B0BFD2',
    borderRadius: '8px 0px 0px 8px',
    position: 'absolute',
    right: '-16px',
    top: '80px',
    zIndex: 1290
  })
);

const OpenCloseDrawerIcon = styled('div')(
  () => ({
    width: '11px',
    height: '11px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer'
  })
)


export const OpenCloseDrawer = ({open, onClick}) => {
  return (
    open ? (
      <OpenCloseDrawerClose onClick={onClick}>
        <OpenCloseDrawerIcon>
          <GenieIcon type="vector-left" iconWidth='11px' iconHeight='11px'/>
        </OpenCloseDrawerIcon>
      </OpenCloseDrawerClose>
    ) : (
      <OpenCloseDrawerOpen onClick={onClick}>
        <OpenCloseDrawerIcon>
          <GenieIcon type="vector-right" iconWidth='11px' iconHeight='11px'/>
        </OpenCloseDrawerIcon>
      </OpenCloseDrawerOpen>
    )
  );
};