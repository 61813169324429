import { useState } from "react"
import { useDispatch } from 'react-redux';
import { Box, Stepper, Step, StepLabel, Button, Grid} from "@mui/material";
import { PageTitle } from "../../components/pageTitle";
import SelectProject from "./selectProject";
import GoldVariable from "./goldVariable";
import Dog from "./dog";
import { uploadDatasets } from '../../actions/datasets';

const steps = [
  'Select Project',
  'Gold Variable',
  'Dogs',
  'Test Atributes'
]

export default function Collection() {
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [golds, setGolds] = useState([
    [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
    [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
  ]);
  const [dogs, setDogs] = useState([{name: '', shelfs: []}]);
  const [datasetName, setDatasetName] = useState('');

  const handleSave = () => {
    let json = formattedJson(golds, dogs);
    json = JSON.stringify(json);
    let csv = convertToCSV(json);
    const file = new File([csv], "test.csv",{type:"text/csv", lastModified:new Date().getTime()});
    
    dispatch(uploadDatasets({id: projectId, datasetName: datasetName, datasetFile: file }));
  }

  const formattedJson = (golds, dogs) => {
    let json = [];
    let formatedGolds = [];
    let formatedDogs = {};
    golds.forEach(item => {
      item.forEach(item2 => formatedGolds.push(item2 ? 1 : 0));
    })
    dogs.forEach(dog => {
      formatedDogs[dog.name] = []
      dog.shelfs.forEach(item => item.forEach(item2 => formatedDogs[dog.name].push(item2)))
    })
    const dogName = Object.keys(formatedDogs);
    formatedGolds.forEach((gold, index) => {
      let item = {gold: gold}
      dogName.forEach(name => {
        item[name] = formatedDogs[name][index] ? 1 : 0
      })
      json.push(item);
    })
    let headers = {gold: 'gold'}
    dogName.forEach(name => {
      headers[name] = name;
    })
    json.unshift(headers)
    return json;
  }

  const convertToCSV = (objArray) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }
    return str;
  }
  
  return (
    <Box>
      <PageTitle title={'Create Collection'} />
      <Box mt="50px" mb="50px">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      
      {activeStep === 0 && (
        <SelectProject
          projectId={projectId}
          onChange={setProjectId}
          datasetName={datasetName}
          setDatasetName={setDatasetName}
        />
      )}
      {activeStep === 1 && (
        <GoldVariable golds={golds} dogs={dogs} setGolds={setGolds} setDogs={setDogs}/>
      )}
      {activeStep === 2 && (
        <Dog golds={golds} dogs={dogs} setDogs={setDogs}/>
      )}
      <Grid container justifyContent={'space-between'} style={{marginTop: '50px'}}>
        {activeStep !== 0 ? (
          <Button variant="contained" color='info' onClick={() => setActiveStep(activeStep-1)}>Back</Button>
        ) : (
          <div></div>
        )}
        {activeStep !== 3 ? (
          <Button variant="contained" color='secondary' onClick={() => setActiveStep(activeStep+1)}>Next</Button>
        ) : (
          <Button variant="contained" color='secondary' onClick={() => handleSave()}>Create</Button>
        )}
      </Grid>
    </Box>
  )
}