import { useEffect, useState, Fragment } from "react";

import { TabsSet } from "../../components/tabs-set";
import { GraphTreeView } from "./graphTreeView";
import { useSelector } from "react-redux";
import { AnalyseGraphs } from "./analyseGraphs";
import { ExportGraphs } from "./exportGraphs";
import { Grid, Button } from "@mui/material";

export const AnalyseGraphsTabs = ({ handleCloseEdit }) => {
  const [tabs, setTabs] = useState([]);
  const [treeView, setTreeView] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const data = useSelector(
    (state) => state.analysesReducer.analyse?.summary?.images
  );
  const analysisType = useSelector(
    (state) => state.analysesReducer.analyse?.analysis_type
  );

  useEffect(() => {
    if (data) {
      let _treeView = [];
      if (data?.null) {
        _treeView = [
          { name: "all", selected: false, open: true, data: [], route: "all" },
        ];
        let keys = Object.keys(data.all);
        keys.forEach((graph, index) => {
          if (graph === "heatmap") {
            _treeView[0].data[index] = {
              name: graph,
              selected: false,
              open: true,
              data: [],
              route: `all*${graph}`,
              value: data.all[graph],
            };
          } else {
            _treeView[0].data[index] = {
              name: graph,
              selected: false,
              open: true,
              data: [],
              route: `all*${graph}`,
            };
          }

          let keys2 = Object.keys(data.all[graph]);
          graph !== "heatmap" &&
            keys2.forEach((key2, index2) => {
              _treeView[0].data[index].data[index2] = {
                name: key2,
                selected: false,
                route: `all*${graph}*${key2}`,
                value: data.all[graph][key2],
              };
            });
        });
      } else {
        let keys = Object.keys(data);
        keys.forEach((key, index) => {
          _treeView[index] = {
            name: key,
            selected: false,
            open: true,
            data: [],
            route: `${key}`,
          };
          let keys2 = Object.keys(data[key]);
          keys2.forEach((key2, index2) => {
            if (key2 === "heatmap") {
              _treeView[index].data[index2] = {
                name: key2,
                selected: false,
                open: true,
                data: [],
                route: `${key}*${key2}`,
                value: data[key][key2],
              };
            } else {
              _treeView[index].data[index2] = {
                name: key2,
                selected: false,
                open: true,
                data: [],
                route: `${key}*${key2}`,
              };
            }
            let keys3 = Object.keys(data[key][key2]);
            key2 !== "heatmap" &&
              keys3.forEach((key3, index3) => {
                _treeView[index].data[index2].data[index3] = {
                  name: key3,
                  selected: false,
                  route: `${key}*${key2}*${key3}`,
                  value: data[key][key2][key3],
                };
              });
          });
        });
      }
      setTreeView(_treeView);
    }
  }, [data]);

  useEffect(() => {
    if (data && treeView) {
      if (analysisType === "compare_groups" || analysisType === "survival") {
        setTabs(
          createCompareGroupsTabs(
            data,
            treeView,
            handleSelectTreeView,
            handleCloseEdit
          )
        );
      }

      if (analysisType === "correlations") {
        setTabs(
          createCompareGroupsTabs(
            data,
            treeView,
            handleSelectTreeView,
            handleCloseEdit
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, treeView]);

  const handleSelectTreeView = (route) => (event) => {
    let _treeView = [...treeView];
    const routeArr = route.split("*");

    if (routeArr.length === 1) {
      const index = _treeView.findIndex((item) => item.name === routeArr[0]);
      _treeView[index].selected = event.target.checked;
      _treeView[index].data.forEach((item) => {
        item.selected = event.target.checked;
        item.data.forEach((item2) => {
          item2.selected = event.target.checked;
        });
      });
    } else if (routeArr.length === 2) {
      const index = _treeView.findIndex((item) => item.name === routeArr[0]);
      const index2 = _treeView[index].data.findIndex(
        (item) => item.name === routeArr[1]
      );
      _treeView[index].data[index2].selected = event.target.checked;
      _treeView[index].data[index2].data.forEach((item) => {
        item.selected = event.target.checked;
      });
    } else {
      const index = _treeView.findIndex((item) => item.name === routeArr[0]);
      const index2 = _treeView[index].data.findIndex(
        (item) => item.name === routeArr[1]
      );
      const index3 = _treeView[index].data[index2].data.findIndex(
        (item) => item.name === routeArr[2]
      );
      _treeView[index].data[index2].data[index3].selected =
        event.target.checked;
    }
    setTreeView(_treeView);
  };

  return (
    !!tabs.length && (
      <Fragment>
        <Grid
          container
          justifyContent={"start"}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Grid item>
            <Button
              sx={{ background: "#1CB4AE" }}
              variant="contained"
              size="small"
              onClick={() => setOpenExport(true)}
            >
              Export
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          wrap="nowrap"
          style={{ marginTop: "40px", display: "flex" }}
        >
          <Grid item xs={3}>
            <GraphTreeView
              treeView={treeView}
              setTreeView={setTreeView}
              handleSelectTreeView={handleSelectTreeView}
            />
          </Grid>
          <Grid item xs={9}>
            <TabsSet tabs={tabs} search={true} />
          </Grid>
        </Grid>
        <ExportGraphs
          open={openExport}
          handleClose={() => setOpenExport(false)}
          treeView={treeView}
        />
      </Fragment>
    )
  );
};

const createCompareGroupsTabs = (
  data,
  treeView,
  handleSelectTreeView,
  handleCloseEdit
) => {
  let tabs = [];
  if (data?.null) {
    let keys = Object.keys(data.all);
    tabs = keys.map((graph, index) => {
      return {
        name: "all:" + graph,
        component: (
          <AnalyseGraphs
            data={
              graph !== "heatmap"
                ? data.all[graph]
                : { heatmap: data.all.heatmap }
            }
            treeView={treeView[0]?.data[index]}
            handleSelectTreeView={handleSelectTreeView}
            handleCloseEdit={handleCloseEdit}
          />
        ),
      };
    });
  } else {
    let keys = Object.keys(data);
    keys.forEach((key, index) => {
      let keys2 = Object.keys(data[key]);
      keys2.forEach((key2, index2) => {
        tabs.push({
          name: `${key}:${key2}`,
          component: (
            <AnalyseGraphs
              data={
                key2 !== "heatmap"
                  ? data[key][key2]
                  : { heatmap: data[key].heatmap }
              }
              treeView={treeView[index]?.data[index2]}
              handleSelectTreeView={handleSelectTreeView}
              handleCloseEdit={handleCloseEdit}
            />
          ),
        });
      });
    });
  }
  return tabs;
};
