export const analyses = [
  {
    type: "Univariate analysis",
    analyses: [
      {
        name: "Compare Groups",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Perform numerous tests to compare between groups. You can select
            t-test /ANOVA, Mann-Whitney / Kruskal-Willis, or Chi-square /
            Fisher-exact, or allow Genie to choose the appropriate statistical
            test!!
            <br />
            <br />
            For example, if you want to compare numerous indices (weight,
            height, smoking status, salary, well-being scores, etc.) between
            individuals who live in the US, Canada, and Europe, just drag the
            place of living to the "Grouping variables" column and all the rest
            (weight, height, smoking status, etc. ) to the "dependents
            variables" column!
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Grouping",
            value: "dependents",
            multiple: true,
            required: true,
          },
          {
            name: "Dependent",
            value: "independents",
            multiple: true,
            required: true,
          },
          {
            name: "Split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
        ],
        methods: [
          {
            name: "Magic",
            description:
              "Allow Genie choose the appropriate statistical test!! Genie will perform t-test /ANOVA for numeric variables, Mann-Whitney / Kruskal-Willis for ordinal variables and Chi-square / Fisher-exact for nominal variables",
            value: "magic",
          },
          {
            name: "T-test \\ ANOVA",
            description: "Perform t-test /ANOVA for all dependent variables",
            value: "t",
          },
          {
            name: "Mann- Whitney \\ Kruskal Willis",
            description:
              "Perform Mann-Whitney / Kruskal-Willis for all dependent variables",
            value: "mann",
          },
          {
            name: "Chi-Square",
            description:
              "Fisher-exact- Perform Chi-square / Fisher-exact for all dependent variables",
            value: "chi",
          },
        ],
      },

      {
        name: "Correlations",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Create multiple correlations: select Pearson, spearman, or allow
            Genie to choose the appropriate correlation for you!
            <br />
            <br />
            For example, suppose you want to correlate salary (in $US), age,
            academic years, experience years, etc.; just drag all those
            variables to the dependents column!
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Variables",
            value: "variable_names",
            multiple: true,
            required: true,
          },
          {
            name: "Split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
        ],
        methods: [
          {
            name: "Magic",
            description:
              "Allow Genie choose the appropriate statistical test!! Genie will perform t-test /ANOVA for numeric variables, Mann-Whitney / Kruskal-Willis for ordinal variables and Chi-square / Fisher-exact for nominal variables",
            value: "magic",
          },
          {
            name: "Pearson",
            description: "",
            value: "pearson",
          },
          {
            name: "Spearman",
            description: "",
            value: "spearman",
          },
        ],
      },
      {
        name: "Survival : Kaplan-Meyer",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Create numerous Kaplan-Meyer and log-rank tests.
            <br />
            <br />
            For example, suppose you want to compare the survival of COVID
            patients (Death, Yes/No) between groups according to age,
            socioeconomic status, smoking (Yes/No), Heart-diseases (Yes/No),
            etc., just drag the death variable to the outcomes columns, the
            time-to-death variable to the time-in-FU column and all the
            independent variables (age, smoking, heart disease, etc.) to the
            independents columns.
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Independent",
            value: "independents",
            multiple: true,
            required: true,
          },
          {
            name: "Event",
            value: "events",
            multiple: true,
            required: true,
          },
          {
            name: "Time to event",
            value: "follow_up_time",
            multiple: false,
            required: true,
          },
          {
            name: "split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
        ],
        methods: [],
      },
      {
        name: "Screen Test",
        image: "url(/static/select-analysis-image.png)",
        description: "",
        video: "",
        varibleColumns: [
          {
            name: "Predictors",
            value: "predictors",
            multiple: true,
            required: true,
          },
          {
            name: "Golds",
            value: "golds",
            multiple: true,
            required: true,
          },
        ],
        methods: [],
      },
    ],
  },
  {
    type: "Multivariate analyses",
    analyses: [
      {
        name: "Generalized Linear Models (GLM)",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Perform numerous General linear models (logistic, linear, Poisson,
            or negative-binomial regressions) across multiple independent and
            outcome variables.
            <br />
            <br />
            For example, suppose you want to associate exposure to multiple
            drugs with multiple possible adverse effects (yes/no), adjusting for
            age, ethnicity, and chronic diseases, just drag drug exposure
            variables to the exposures column, the adverse effects variables to
            the outcomes column and the adjustment variables to the covariates
            column!
            <br />
            <br />
            Choose "logistic" regression for dichotomous outcomes, "Poisson"
            regression for count or rates data, and "Negative-Binomial
            regression" for count or rates data with overspreading.
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Main Independents",
            value: "independents",

            multiple: true,
            required: true,
          },
          {
            name: "Covariates",
            value: "covariates",
            multiple: true,
            required: true,
          },
          {
            name: "Outcomes",
            value: "dependents",
            multiple: true,
            required: true,
          },
          {
            name: "First Interaction variable",
            value: "interaction_1",
            multiple: true,
            required: true,
          },
          {
            name: "Second Interaction variable",
            value: "interaction_2",
            multiple: true,
            required: true,
          },
          {
            name: "split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
        ],
        methods: [
          {
            name: "Logistic Regression",
            description: "",
            value: "logistic",
          },
          {
            name: "Poisson Regression",
            description: "",
            value: "poisson",
          },
          {
            name: "Negative Binomial Regression",
            description: "",
            value: "nb",
          },
        ],
      },
      {
        name: "Linear Regression",
        image: "url(/static/select-analysis-image.png)",
        description: <p></p>,
        video: "",
        varibleColumns: [
          {
            name: "Main Independents",
            value: "independents",
            multiple: true,
            required: true,
          },
          {
            name: "Covariates",
            value: "covariates",
            multiple: true,
            required: true,
          },
          {
            name: "Outcomes",
            value: "dependents",
            multiple: true,
            required: true,
          },
          {
            name: "First Interaction variable",
            value: "interaction_1",
            multiple: true,
            required: true,
          },
          {
            name: "Second Interaction variable",
            value: "interaction_2",
            multiple: true,
            required: true,
          },
          {
            name: "split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
        ],
        methods: [],
      },
      {
        name: "Survival Regressions",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Perform numerous survival regression models (COX, Weibull) across
            multiple independent and event variables.
            <br />
            <br />
            For example, suppose you want to compare the survival to several
            events (discharge from admission, death etc. ) between several
            independent variables (groups of age and socioeconomic status,
            smoking, Heart-diseases , etc.), adjusting for possible confounders
            (ethnicity, and chronic diseases) , just drag the event variables to
            the events columns, the time-to-death variable to the time-in-FU
            column , and all the independent variables (age, SE status, smoking,
            heart disease, etc.) to the independents columns.
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Main Independents",
            value: "independents",
            multiple: true,
            required: true,
          },
          {
            name: "Covariates",
            value: "covariates",
            multiple: true,
            required: true,
          },
          {
            name: "Events",
            value: "dependents",
            multiple: true,
            required: true,
          },
          {
            name: "time to event",
            value: "follow_up_time",
            multiple: false,
            required: true,
          },
          {
            name: "First Interaction variable",
            value: "interaction_1",
            multiple: true,
            required: true,
          },
          {
            name: "Second Interaction variable",
            value: "interaction_2",
            multiple: true,
            required: true,
          },
          {
            name: "split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
        ],
        methods: [
          {
            name: "Cox",
            description: "",
            value: "cox",
          },
        ],
      },
      {
        name: "Mixed Models: GLM",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Perform numerous General linear mixed-effects models (logistic,
            linear, Poisson, or negative-binomial regressions) across multiple
            independent and outcome variables.
            <br />
            <br />
            For example, suppose you want to associate exposure to multiple
            drugs with multiple possible adverse effects (yes/no), adjusting for
            age, smoking, and chronic diseases and clustering for sex, just drag
            drug exposure variables to the exposures column, the adverse effects
            variables to the outcomes column, the adjustment variables to the
            covariates column and "sex" variable to the cluster1 column!
            <br />
            <br />
            Choose "logistic" regression for dichotomous outcomes, "Poisson"
            regression for count or rates data, and "negative-binomial
            regression" for count or rates data with overspreading.
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Main Independents",
            value: "exposure",
            multiple: true,
            required: true,
          },
          {
            name: "Covariates",
            value: "covariate",
            multiple: true,
            required: true,
          },
          {
            name: "Outcomes",
            value: "outcome",
            multiple: true,
            required: true,
          },
          {
            name: "split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
          {
            name: "Cluster by",
            value: "clusters",
            multiple: true,
            required: false,
          },
        ],
        methods: [
          {
            name: "Logistic Regression",
            description: "",
            value: "logistic",
          },
          {
            name: "Poisson Regression",
            description: "",
            value: "pois",
          },
          {
            name: "Negative Binomial Regression",
            description: "",
            value: "nb",
          },
        ],
      },
      {
        name: "Mixed Models: Linear Regression",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Perform numerous linear regression models across multiple
            independent and outcome variables.
            <br />
            <br />
            For example, suppose you want to associate exposure to multiple
            drugs with multiple numeric outcomes (levels of enzymes,for
            instance), adjusting for age, ethnicity, and chronic diseases, just
            drag drug exposure variables to the exposures column, the outcome
            variables to the outcomes column and the adjustment variables to the
            covariates column!
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Main Independents",
            value: "exposure",
            multiple: true,
            required: true,
          },
          {
            name: "Covariates",
            value: "covariate",
            multiple: true,
            required: true,
          },
          {
            name: "Outcomes",
            value: "outcome",
            multiple: true,
            required: true,
          },
          {
            name: "split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
          {
            name: "Cluster by",
            value: "clusters",
            multiple: true,
            required: false,
          },
        ],
        methods: [],
      },
      {
        name: "Mixed Models: Survival Regressions",
        image: "url(/static/select-analysis-image.png)",
        description: (
          <p>
            Perform numerous survival mixed-effects regression models (COX,
            Weibull) across multiple independent and event variables.
            <br />
            <br />
            For example, suppose you want to compare the survival to several
            events (discharge from admission, death etc. ) between several
            independent variables (groups of age and socioeconomic status,
            smoking, Heart-diseases , etc.), adjusting for possible confounders
            (ethnicity, and chronic diseases) and clustering for sex, just drag
            the event variables to the events columns, the time-to-death
            variable to the time-in-FU column , all the independent variables
            (age, SE status, smoking, heart disease, etc.) to the independents
            columns and the clustering variables to the cluster columns.
            <br />
            <br />
            If you want to split the analysis for sex (perform a separate
            analysis for males and females, for example)- just drag the "sex"
            variable to the"split analysis by" column.
          </p>
        ),
        video: "",
        varibleColumns: [
          {
            name: "Main Independents",
            value: "exposure",
            multiple: true,
            required: true,
          },
          {
            name: "Covariates",
            value: "covariate",
            multiple: true,
            required: true,
          },
          {
            name: "Events",
            value: "outcome",
            multiple: true,
            required: true,
          },
          {
            name: "time to event ",
            value: "time",
            multiple: false,
            required: true,
          },
          {
            name: "split analysis by",
            value: "split_variable",
            multiple: false,
            required: false,
          },
          {
            name: "Cluster by",
            value: "clusters",
            multiple: true,
            required: false,
          },
        ],
        methods: [
          {
            name: "Weibull",
            description: "",
            value: "Weibull",
          },
          {
            name: "Cox",
            description: "",
            value: "cox",
          },
        ],
      },
      {
        name: "Repeated Measurements ANOVA",
        image: "url(/static/select-analysis-image.png)",
        description: <p></p>,
        video: "",
      },
    ],
  },
  {
    type: "Propensity Score Matching",
    analyses: [
      {
        name: "By K nearest neighbours",
        image: "url(/static/select-analysis-image.png)",
        description: <p></p>,
        video: "",
      },
      {
        name: "By Strata",
        image: "url(/static/select-analysis-image.png)",
        description: <p></p>,
        video: "",
      },
    ],
  },
];

export const MULTIVARIATE = [
  "Generalized Linear Models (GLM)",
  "Linear Regression",
  "Survival Regressions",
];

export const MIXED_MODELS = [
  "Mixed Models: GLM",
  "Mixed Models: Linear Regression",
  "Mixed Models: Survival Regressions",
];
