const LINK_REGEX =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const hasLink = (text) => {
  return LINK_REGEX.test(text);
};

export const Linkify = ({ children }) => {
  const isUrl = (word) => {
    return word.match(LINK_REGEX);
  };

  const addMarkup = (word) => {
    return isUrl(word)
      ? `<a href="${word}" target="_blank" rel="noopener noreferrer">${word}</a>`
      : word;
  };

  const words = children.split(" ");
  const formatedWords = words.map((w, i) => addMarkup(w));
  const html = formatedWords.join(" ");
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};
