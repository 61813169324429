import { useState } from "react";
import { Checkbox, Box, Tab, Tabs, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const AntTab = styled((props) => (
  <Tab disableFocusRipple disableRipple {...props} />
))(() => ({
  textTransform: "none",
  minWidth: "160px",
  backgroundColor: "#E1E3EA",
  fontSize: "16px",
  border: "none",
  borderRadius: "12px 12px 0 0",
  "&.Mui-selected": {
    backgroundColor: "#fff",
    border: "none",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
    border: "none",
  },
}));

export const TabsSet = ({ tabs, search, handleChecked }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", flex: "1 1 auto", maxHeight: "100%" }}>
      <Box sx={{ width: "fit-content", maxWidth: "100%" }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{ borderRadius: "12px 12px 0 0", backgroundColor: "#E1E3EA" }}
          scrollButtons={false}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <AntTab
              key={tab.name}
              value={tab.name}
              label={
                tab?.checkedRoute ? (
                  <Grid>
                    <Checkbox
                      onChange={handleChecked(tab.checkedRoute)}
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      sx={{ padding: "0 9px" }}
                    />
                    {tab.name}
                  </Grid>
                ) : (
                  tab.name
                )
              }
            />
          ))}
        </Tabs>
      </Box>
      {tabs.find((tab) => tab.name === activeTab)?.component}
    </Box>
  );
};
