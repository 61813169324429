import { Button, Stack } from "@mui/material";
import { setExportDialog } from "actions/chats";
import { DownloadAnalyseIcon } from "Icons/DownloadAnalyseIcon";
import React from "react";
import { useDispatch } from "react-redux";
import { EXPORT_TYPES } from "reducer/chats";

export const FooterBlock = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <Stack>
      <Button
        startIcon={<DownloadAnalyseIcon />}
        onClick={() => dispatch(setExportDialog(EXPORT_TYPES[0], id))}
        variant="contained"
        sx={{ maxWidth: "140px", height: "44px", ml: "auto" }}
      >
        Download
      </Button>
    </Stack>
  );
};
