import { Logo } from "components/logo";

import { ListContainer, StyledListItem } from "pages/home/components/Header/styles";

import { Grid, Stack, Typography } from "@mui/material";
import { POPUPS } from "hooks/usePopups";
import { PopupContext } from "App";
import { useContext } from "react";

export const Footer = () => {
  const { setOpenPopup } = useContext(PopupContext);

  const LINKS = [{ title: "Terms of use", onClick: () => setOpenPopup(POPUPS.TERMS_OF_USE) }];

  return (
    <Grid
      container
      p="34px 80px"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      gap="40px"
      sx={{ background: "#F9FAFB", borderTop: "1px #EAECF0 solid" }}
    >
      <Stack direction="row" alignItems="center" gap="40px">
        <Logo />
        <Stack direction="row" alignItems="center" gap="5px">
          <Typography variant="body1" color="#70798C">
            Copyright© 2024. Genie. All Right Reserved. Contact us{" "}
          </Typography>
          <Typography
            component={"a"}
            sx={{
              cursor: "pointer",
              color: "#6941C6",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            href="mailto:drsharondaniel@geniestat.com"
          >
            drsharondaniel@geniestat.com
          </Typography>
        </Stack>
      </Stack>

      <ListContainer sx={{ gap: "24px" }}>
        {LINKS.map(({ title, onClick }, index) => (
          <StyledListItem sx={{ fontWeight: 400 }} onClick={onClick} key={index}>
            {title}
          </StyledListItem>
        ))}
      </ListContainer>
    </Grid>
  );
};
