import { useState } from "react";

export const POPUPS = {
  TERMS_OF_USE: "TERMS_OF_USE",
};

const DEFAULT_STATE = {
  [POPUPS.TERMS_OF_USE]: false,
};

export const usePopups = () => {
  const [popupsState, setPopupsState] = useState(DEFAULT_STATE);

  const setOpenPopup = (type) => setPopupsState({ ...popupsState, [type]: true });
  const setClosePopup = (type) => setPopupsState({ ...popupsState, [type]: false });

  return {
    setOpenPopup,
    setClosePopup,
    popupsState,
  };
};
