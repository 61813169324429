import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Header } from "./components/Header";
import LoginPopUp from "../../components/login";
import { HomeWrapper } from "./components/styles";
import RegisterPopUp from "../../components/register";
import { WelcomeScreen } from "./components/WelcomeScreen";

import { registerGoogleUser } from "../../actions/user";
import { AboutAnalysesScreen } from "./components/AboutAnalysesScreen";
import { About } from "./components/About";
import { Footer } from "components/footer";
import { Founder } from "./components/Founder";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const googleResponse = ({ credential }) => {
    dispatch(registerGoogleUser(credential, () => navigate("/genie-gpt")));
  };

  const googleErrorResponse = (error) => {
    console.log(error);
  };

  return (
    <HomeWrapper container>
      <Header {...{ setOpenLogin, setOpenRegister }} />
      <WelcomeScreen {...{ setOpenRegister }} />
      <About />
      <AboutAnalysesScreen />
      <Founder />
      <Footer />

      <RegisterPopUp
        googleResponse={googleResponse}
        googleErrorResponse={googleErrorResponse}
        open={openRegister}
        setOpenLogin={setOpenLogin}
        handleClose={() => setOpenRegister(!openRegister)}
      />
      <LoginPopUp
        open={openLogin}
        googleResponse={googleResponse}
        googleErrorResponse={googleErrorResponse}
        setOpenRegister={setOpenRegister}
        handleClose={() => setOpenLogin(!openLogin)}
      />
    </HomeWrapper>
  );
}
