export const cl = {
  accordion: {
    backgroundColor: "#F9FAFB",
    border: "none",
    borderRadius: "8px !important",
    boxShadow: "none",
    mb: "10px",
    "&::before": {
      display: "none !important",
    },

    "& .Mui-expanded": {
      margin: "0!important",
    },
  },
  accordionPaper: {
    elevation: 0,
    border: "none",
    backgroundColor: "#F9FAFB",
    borderRadius: "8px !important",
  },

  accordionSummary: {
    background: "white",
    borderRadius: "8px",
    border: "1px #EAECF0 solid",
    padding: "9.5px 24px",
    minHeight: "44px !important",
    "& svg": {
      width: "24px !important",
      height: "24px !important",
    },

    "& .Mui-expanded": {
      borderBottom: "none !important",
    },

    "& .MuiAccordionSummary-content": {
      m: 0,
    },

    "& p": {
      fontSize: "16px",
      color: "#101828",
      fontWeight: 500,
      lineHeight: "20px",
    },
  },

  accordionDetails: {
    padding: "0px 24px",
  },

  accordionIcon: {
    background: "#F9FAFB",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};
