import { Avatar, Box, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const UserName = styled(Typography)(() => ({
  color: "#344054",
  fontSize: "16px",
  fontWeight: "600",
  textTransform: "capitalize",
}));

export const UserRole = styled(Typography)(() => ({
  fontSize: "10px",
  fontWeight: "400",
  color: "#292D32",
  textTransform: "capitalize",
  opacity: ".7",
  lineHeight: "14px",
}));

export const UserAvatar = styled(Avatar)(() => ({
  width: "36px",
  height: "36px",
  fontSize: "15px",
  backgroundColor: "#383838",
}));

export const ContentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "2px",
  marginLeft: "4px",
}));

export const IconWrapper = styled(Box)(() => ({
  marginLeft: "5px",
  cursor: "pointer",
  transition: "all 0.3s",
}));

export const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: "14px",

  "& svg": {
    margin: "0px 6px 0px 0px",
    //color: "#ffff",
  },
}));

export const DeleteMenuItem = styled(MenuItem)(() => ({
  color: "#D92D20 !important",
  //minWidth: "150px",

  svg: {
    color: "#ffff !important",
    margin: "0px 6px 0px 0px",
  },
}));
