import { useEffect, useState } from "react";

import { AnalyseTable } from "../AnalyseTable";

import { AccordionContent } from "../AnalyseItem/styled";
import { cl } from "../AnalyseGraph/styles";

import { Accordion, Typography, TableContainer, AccordionDetails, Box } from "@mui/material";
import { ArrowDown } from "Icons/ArrowDown";

export const CorelationAccordion = ({ data }) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    setKeys(Object.keys(data));
  }, [data]);

  return (
    <Box>
      {!!keys?.length &&
        keys.map((key, index) => (
          <TableContainer key={index}>
            <Accordion
              defaultExpanded={!index}
              sx={{
                ...cl.accordion,
                "&.Mui-expanded": {
                  marginBottom: "10px!important",
                },
              }}
              PaperProps={{
                sx: cl.accordionPaper,
              }}
            >
              <AccordionContent expandIcon={<ArrowDown />} sx={cl.accordionSummary}>
                <Typography lineHeight={2.75}>{key}</Typography>
              </AccordionContent>
              <AccordionDetails sx={{ p: 0 }}>
                <AnalyseTable data={data[key]} />
              </AccordionDetails>
            </Accordion>
          </TableContainer>
        ))}
    </Box>
  );
};
