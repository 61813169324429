import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GenieIcon } from "../../../components/genie-icon";
import { SelectAnalyses } from "./selectAnalyses";
import { MakeAnalyses } from "./makeAnalyses";
import { SelectMethod } from "./selectMethod";
import { toast } from "react-toastify";
import {
  changeMakeAnalyses,
  handleSaveMakeAnalyses,
  changeOneMakeAnalysesColumn,
  removeAnalyses,
} from "../../../actions/analyses";
import { analyses, MIXED_MODELS, MULTIVARIATE } from "./constants";

export function AnalysesDialogs({ isEdit }) {
  const [openSelectDialog, setOpenSelectDialog] = useState(false);
  const [openMakeDialog, setOpenMakeDialog] = useState(false);
  const [openSelectMethod, setSelectMethod] = useState(false);
  const [selected, setSelected] = useState(analyses[0].analyses[0]);
  const [selectedMethod, setSelectedMethod] = useState(
    selected.methods ? selected.methods[0] : null
  );
  const [openLoader, setOpenLoader] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAnalysesColumns = useSelector(
    (state) => state.analysesReducer.makeAnalysesColumns
  );
  const analysis = useSelector((state) => state.analysesReducer.analyse);
  const variblesData = useSelector(
    (state) => state.datasetsReducer.datasetVarTypes
  );
  const round_digits = useSelector(
    (state) => state.userReducer.data.number_of_roundings
  );

  useEffect(() => {
    clearMakeAnalyses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (!isEdit) return;
    setOpenMakeDialog(true);
    analyses.forEach((analyseGroup) => {
      analyseGroup.analyses.forEach((item) => {
        if (item.name === analysis.name) {
          setSelected(item);
        }
      });
    });
  }, [isEdit, analysis]);

  useEffect(() => {
    if (!isEdit || !variblesData) return;

    selected.varibleColumns.forEach((column) => {
      let vars = [];

      if (
        column.value === "interaction_1" &&
        !!Object.keys(analysis.summary.interactions).length
      ) {
        Object.keys(analysis.summary.interactions).forEach(
          (key) => variblesData[key] && vars.push(variblesData[key])
        );
      }

      if (
        column.value === "interaction_2" &&
        !!Object.keys(analysis.summary.interactions).length
      ) {
        Object.values(analysis.summary.interactions).forEach(
          (value) => variblesData[value] && vars.push(variblesData[value])
        );
      }

      if (
        Array.isArray(analysis.summary[column.value]) ||
        Array.isArray(analysis.summary[column.value + "s"])
      ) {
        if (column.value === "exposure") {
          analysis.summary["exposures"].forEach((variable) => {
            variblesData[variable] && vars.push(variblesData[variable]);
          });
        } else if (column.value === "covariate") {
          analysis.summary["covariates"].forEach((variable) => {
            variblesData[variable] && vars.push(variblesData[variable]);
          });
        } else if (column.value === "outcome") {
          analysis.summary["outcomes"].forEach((variable) => {
            variblesData[variable] && vars.push(variblesData[variable]);
          });
        } else {
          analysis.summary[column.value].forEach((variable) => {
            variblesData[variable] && vars.push(variblesData[variable]);
          });
        }
      }
      dispatch(changeOneMakeAnalysesColumn(column.value, vars));
      if (
        column.value === "split_variable" &&
        analysis.summary["split_variable_name"] &&
        analysis.summary["split_variable_name"] !== "NA" &&
        analysis.summary["split_variable_name"] !== ""
      ) {
        dispatch(
          changeOneMakeAnalysesColumn("split_variable", [
            variblesData[analysis.summary["split_variable_name"]],
          ])
        );
      }
      if (
        column.value === "follow_up_time" &&
        analysis.summary["follow_up_time"]
      ) {
        dispatch(
          changeOneMakeAnalysesColumn("follow_up_time", [
            variblesData[analysis.summary["follow_up_time"]],
          ])
        );
      }
      if (column.value === "clusters") {
        let clusters = [];
        [1, 2, 3].forEach((number) => {
          if (
            analysis.summary[`cluster${number}_name`] &&
            analysis.summary[`cluster${number}_name`] !== "NA"
          ) {
            clusters.push(
              variblesData[analysis.summary[`cluster${number}_name`]]
            );
          }
        });
        dispatch(changeOneMakeAnalysesColumn("clusters", clusters));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, variblesData, analysis]);

  const clearMakeAnalyses = () => {
    let data = {};
    selected.varibleColumns?.forEach((item) => {
      data[item.value] = [];
    });
    dispatch(changeMakeAnalyses(data));
  };

  const combineArrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      toast.error(
        "Interaction fields should have the same length to create an interaction term."
      );
      return [];
    }

    const combinedArray = [];
    for (let i = 0; i < arr1.length; i++) {
      combinedArray.push([arr1[i].variable_name, arr2[i].variable_name]);
    }

    return combinedArray;
  };

  const filterInteractions = (obj) => {
    const filteredObj = {};

    for (const key in obj) {
      if (key !== "interaction_1" && key !== "interaction_2") {
        filteredObj[key] = obj[key];
      }
    }

    return filteredObj;
  };

  const handleSave = () => {
    let reqData = {
      file_configuration: { width: 1000, height: 1000, resolution: 200 },
      data: {
        name: selected.name,
        dataset_id: isEdit ? analysis.dataset_id : parseInt(id),
        method: selectedMethod?.value,
        round_digits: 3,
        significance_level: 0.05,
        significance_level_normality: 0.05,
      },
    };

    if (MULTIVARIATE.includes(selected.name)) {
      const fieldData = combineArrays(
        makeAnalysesColumns["interaction_1"],
        makeAnalysesColumns["interaction_2"]
      );
      if (!!fieldData.length) reqData.data.interactions = fieldData;
    }

    if (selected.name === "Screen Test") {
      reqData.data.round_digits = round_digits || 3;
    }

    if (selected.name === "Correlations" || selected.name === "Screen Test") {
      // reqData.data.split_variable = "NA";
      reqData.data.split_variable = "all";
    }

    if (selected.name === "Correlations") {
      reqData.data.matrix_method = "pearson";
    }

    if ([...MULTIVARIATE, ...MIXED_MODELS].includes(selected.name)) {
      reqData.data.follow_up_time = [];
    }

    if (
      selected.name === "Linear Regression" ||
      selected.name === "Mixed Models: Linear Regression"
    ) {
      reqData.data.split_variable = "NA";
      reqData.data.method = "linear";
    }

    selected.varibleColumns.forEach((item) => {
      if (!item.multiple) {
        reqData.data[item.value] =
          makeAnalysesColumns[item.value][0]?.variable_name;
      } else {
        if (item.value !== "clusters") {
          reqData.data[item.value] = makeAnalysesColumns[item.value].map(
            (item) => item.variable_name
          );
        }
      }
      if (item.value === "clusters") {
        reqData.data.cluster1_name =
          makeAnalysesColumns.clusters[0]?.variable_name;
        reqData.data.cluster2_name =
          makeAnalysesColumns.clusters[1]?.variable_name;
        reqData.data.cluster3_name =
          makeAnalysesColumns.clusters[2]?.variable_name;
      }
      if (
        selected.name === "Survival Regressions" &&
        item.value === "follow_up_time"
      ) {
        reqData.data.follow_up_time = [
          makeAnalysesColumns[item.value][0]?.variable_name,
        ];
      }
    });

    if (MULTIVARIATE.includes(selected.name)) {
      reqData.data = filterInteractions(reqData.data);
    }

    setOpenLoader(true);
    dispatch(
      handleSaveMakeAnalyses(
        reqData,
        selected.name,
        (id) => {
          if (isEdit) {
            dispatch(removeAnalyses(analysis.id));
            window.location.pathname = "/dashboard/analyse/" + id;
          } else navigate(`/dashboard/analyse/${id}#table`);
        },
        setOpenLoader
      )
    );
    setSelectMethod(false);
    setOpenMakeDialog(false);
    clearMakeAnalyses();
  };

  return (
    <Fragment>
      {!isEdit && (
        <Button
          sx={{ background: "#219653" }}
          variant="contained"
          size="small"
          startIcon={
            <GenieIcon
              iconWidth="15px"
              iconHeight="15px"
              type="analyses-white"
            />
          }
          onClick={() => setOpenSelectDialog(!openSelectDialog)}
        >
          Analyze
        </Button>
      )}
      <SelectAnalyses
        open={openSelectDialog}
        handleClose={() => setOpenSelectDialog(!openSelectDialog)}
        selected={selected}
        handleChangeSelected={(analyse) => setSelected(analyse)}
        nextClick={() => {
          setOpenSelectDialog(!openSelectDialog);
          setOpenMakeDialog(!openMakeDialog);
        }}
      />
      <DndProvider backend={HTML5Backend}>
        <MakeAnalyses
          open={openMakeDialog}
          handleClose={() => setOpenMakeDialog(!openMakeDialog)}
          selectedAnalyse={selected}
          handleCreate={() =>
            selected.methods.length
              ? setSelectMethod((prev) => !prev)
              : handleSave()
          }
        />
      </DndProvider>
      {selectedMethod && (
        <SelectMethod
          open={openSelectMethod}
          handleClose={() => setSelectMethod((prev) => !prev)}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          handleSave={handleSave}
          selected={selected}
        />
      )}
      <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
