import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVaribles } from "../../actions/datasets";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";

import { roundNumbers } from "../../helpers/roundNumbers";

export const SummaryTable = () => {
  const dispatch = useDispatch();
  const [headers, setHeaders] = useState([]);
  const [summaryKeys, setSummaryKeys] = useState([]);
  const [selected, setSelected] = useState([]);

  const summary = useSelector((state) => state.datasetsReducer.summary);
  useEffect(() => {
    if (Object.keys(summary).length) {
      const summary_keys = Object.keys(summary);
      setSummaryKeys(summary_keys);
      setHeaders(Object.keys(summary[summary_keys[0]]));
    }
  }, [summary]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelect = (name) => (event) => {
    let _selected = [...selected];
    if (event.target.checked) {
      _selected.push(name);
    } else {
      const index = _selected.findIndex((item) => item === name);
      _selected.splice(index, 1);
    }
    setSelected(_selected);
    dispatch(setSelectedVaribles(_selected));
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(summaryKeys);
    } else {
      setSelected([]);
    }
  };

  return (
    summaryKeys.length && (
      <>
        {/* <SearchPanel /> */}
        <TableContainer component={Paper} sx={{ height: "calc(100vh - 190px)" }}>
          <Table stickyHeader sx={{ minWidth: 900 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onChange={handleSelectAll}
                    checked={selected.length === summaryKeys.length}
                  />
                </TableCell>
                {headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryKeys.map((item) => {
                const isItemSelected = isSelected(item);
                return (
                  <TableRow key={item} selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} onChange={handleSelect(item)} />
                    </TableCell>
                    {headers.map((header) => (
                      <TableCell key={header} component="th" scope="row">
                        {summary[item][header]?.map((val) => roundNumbers(val)).join(", ")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  );
};
