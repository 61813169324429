export const cl = {
  imageContainer: {
    aspectRatio: "16 / 9",
    "& img": {
      objectFit: "contain",
      width: "100%",
      height: "100%",
      backgroundColor: "#ffff",
      borderRadius: "14px",
      border: "1px #EAECF0 solid",
    },
    boxSizing: "border-box",
    display: "flex",
    backgroundColor: "#ffff",
    borderRadius: "14px",
    position: "relative",
    zIndex: 10,
  },
  sliderImageContainer: {
    p: "24px 24px 24px 0",
    width: "100%",
    height: "calc(100% + 19px)",
    aspectRatio: "16 / 9",
    backgroundColor: "#F9FAFB",
    boxSizing: "border-box",
    "& img": {
      aspectRatio: "16 / 9",
      objectFit: "contain",
      width: "100%",
      height: "100%",
      backgroundColor: "#ffff",
      borderRadius: "14px",
      border: "1px #EAECF0 solid",
    },
  },
  sliderImageRow: {
    backfaceVisibility: "hidden",
    display: "flex",
    gap: "50px",
    touchAction: "pan-y",
  },
  sliderImageItem: {
    minWidth: 0,
    position: "relative",
    flex: "0 0 100%",
  },
  descriptionText: {
    fontSize: "18px",
    lineHeight: "26px",
    color: "#475467",
    fontWeight: 500,
    whiteSpace: "pre-line",
  },
  titleText: {
    fontSize: "36px",
    lineHeight: "44px",
    whiteSpace: "pre-line",
  },
};
