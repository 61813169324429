import * as React from "react";
export const CrossIcon = ({ stroke = "#98A2B3", width = 44, height = 45, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m28 16.98-12 12m0-12 12 12"
      stroke={stroke}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
