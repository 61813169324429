export const fontFamily = [
  {label: 'Tahoma', value: 'Tahoma'},
  {label: 'Montserrat', value: 'Montserrat'}
]

export const faceStyle = [
  {label: 'Plain' , value: 'plain'},
  {label: 'Bold' , value: 'bold'},
  {label: 'Italic' , value: 'italic'},
  {label: 'Bold-Italic' , value: 'bold.italic'}
]

export const positionStyle = [
  {label: 'Right' , value: 'right'},
  {label: 'Left' , value: 'left'},
  {label: 'Top' , value: 'top'},
  {label: 'Bottom' , value: 'bottom'}
]

export const directionStyle = [
  {label: 'Vertical' , value: 'vertical'},
  {label: 'Horizontal' , value: 'horizontal'},
]

export const poinShape = [
  {label: '0', value: '0'},
  {label: '1', value: '1'},
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '5', value: '5'},
  {label: '6', value: '6'},
  {label: '7', value: '7'},
  {label: '8', value: '8'},
  {label: '9', value: '9'},
  {label: '10', value: '10'},
  {label: '11', value: '11'},
  {label: '12', value: '12'},
  {label: '13', value: '13'},
  {label: '14', value: '14'},
  {label: '15', value: '15'},
  {label: '16', value: '16'},
  {label: '17', value: '17'},
  {label: '18', value: '18'},
  {label: '19', value: '19'},
  {label: '20', value: '20'},
  {label: '21', value: '21'},
  {label: '22', value: '22'},
  {label: '23', value: '23'},
  {label: '24', value: '24'},
  {label: '25', value: '25'},
]

const lineType = [
  {label: 'twodash', value: 'twodash'},
  {label: 'solid', value: 'solid'},
  {label: 'longdash', value: 'longdash'},
  {label: 'dotted', value: 'dotted'},
  {label: 'dotdash', value: 'dotdash'},
  {label: 'dashed', value: 'dashed'},
  {label: 'blank', value: 'blank'}
]

const heatmapDerection = [
  {label: 'lower', value: 'lower'},
  {label: 'upper', value: 'upper'},
  {label: 'all', value: 'all'},
]


export const CompareGroupsConfigurationForm = [
  {
    name: 'MainTitle',
    feilds: [
      {
        label: 'Label',
        valueName: 'main.title.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.main.title',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.main.title',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.main.title',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },{
        label: 'Size',
        valueName: 'size.main.title',
        min: null,
        max: null,
        items: null,
        type: 'numeric',
      },
      {
        label: 'Face',
        valueName: 'face.main.title',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      }
    ]
  },
  {
    name: 'X axis title:',
    feilds: [
      {
        label: 'Label',
        valueName: 'x.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.x',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.x',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.x',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.x',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.x',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.x',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
    ]
  },
  {
    name: 'X axis Ticks:',
    feilds: [
      {
        label: 'Font',
        valueName: 'x.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'x.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'x.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'x.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'x.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'x.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Y axis title:',
    feilds: [
      {
        label: 'Label',
        valueName: 'y.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.y',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.y',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.y',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.y',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.y',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.y',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },

    ]
  },
  {
    name: 'Y axis Ticks:',
    feilds: [
      {
        label: 'Font',
        valueName: 'y.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'y.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'y.text.size',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Face',
        valueName: 'y.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'y.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'y.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Legend body:',
    feilds: [
      {
        label: 'Background color',
        valueName: 'legend.background.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Position',
        valueName: 'legend.position',
        min: null,
        max: null,
        items: positionStyle,
        type: 'select'
      },
      {
        label: 'Direction',
        valueName: 'legend.direction',
        min: null,
        max: null,
        items: directionStyle,
        type: 'select'
      },
      {
        label: 'Label',
        valueName: 'legend.title.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'legend.title.align',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'legend.title.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'legend.title.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'legend.title.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'legend.title.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
    ]
  },
  {
    name: 'Legend Text:',
    feilds: [
      {
        label: 'Align',
        valueName: 'legend.text.align',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'legend.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'legend.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'legend.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'legend.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
    ]
  },
  {
    name: 'Graph:',
    feilds: [
      {
        label: 'Point Color group1',
        valueName: 'color1',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Point Color group2',
        valueName: 'color2',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Point Color group3',
        valueName: 'color3',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Point Color group4',
        valueName: 'color4',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Point Color group5',
        valueName: 'color5',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Point Color group6',
        valueName: 'color6',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Points opacity',
        valueName: 'point.opacity',
        min: 0,
        max: 1,
        items: null,
        type: 'slider',
        steps: 10,
        step: 0.1
      },
      {
        label: 'Point size',
        valueName: 'point.size',
        min: 1,
        max: 20,
        items: null,
        type: 'slider'
      },
      {
        label: 'Jitter width',
        valueName: 'jitter.width',
        min: 1,
        max: 20,
        items: null,
        type: 'slider'
      },
      {
        label: 'Point shape',
        valueName: 'point.shape',
        min: null,
        max: null,
        items: poinShape,
        type: 'select'
      },
      {
        label: 'Panel color',
        valueName: 'panel.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Grid color',
        valueName: 'grid.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Plot background',
        valueName: 'plot.background',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
    ]
  }
];

export const CorrelationConfigurationForm = [
  {
    name: 'Main Title',
    feilds: [
      {
        label: 'Label',
        valueName: 'main.title.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.main.title',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.main.title',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.main.title',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.main.title',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.main.title',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
    ]
  },
  {
    name: 'X axis title',
    feilds: [
      {
        label: 'Label',
        valueName: 'x.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.x',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.x',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.x',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.x',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.x',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.x',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
    ]
  },
  {
    name: 'X axis Ticks',
    feilds: [
      {
        label: 'Font',
        valueName: 'x.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'x.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'x.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'x.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'x.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'x.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Y axis title',
    feilds: [
      {
        label: 'Label',
        valueName: 'y.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.y',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.y',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.y',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.y',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.y',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.y',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
    ]
  },
  {
    name: 'Y axis Ticks',
    feilds: [
      {
        label: 'Font',
        valueName: 'y.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'y.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'y.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'y.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'y.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'y.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Graph',
    feilds: [
      {
        label: 'Point Color',
        valueName: 'color1',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Points opacity',
        valueName: 'point.opacity',
        min: 0,
        max: 1,
        items: null,
        type: 'slider',
        steps: 10,
        step: 0.1
      },
      {
        label: 'Point size',
        valueName: 'point.size',
        min: 1,
        max: 10,
        items: null,
        type: 'slider'
      },
      {
        label: 'Point shape',
        valueName: 'point.shape',
        min: null,
        max: null,
        items: poinShape,
        type: 'select'
      },
      {
        label: 'Line size',
        valueName: 'line.size',
        min: 1,
        max: 10,
        items: null,
        type: 'slider'
      },
      {
        label: 'Line color',
        valueName: 'line.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Standard error',
        valueName: 'se',
        min: null,
        max: null,
        items: null,
        type: 'checkbox'
      },
      {
        label: 'Standard error opacity',
        valueName: 'se.alpha',
        min: 0,
        max: 1,
        items: null,
        type: 'slider',
        steps: 10,
        step: 0.1
      },
      {
        label: 'Line type',
        valueName: 'line.type',
        min: null,
        max: null,
        items: lineType,
        type: 'select'
      },
      {
        label: 'Panel color',
        valueName: 'panel.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Grid color',
        valueName: 'grid.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Plot background',
        valueName: 'plot.background',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
    ]
  }
];

export const CorrelationHeatmapConfigurationForm = [
  {
    name: 'Main Title',
    feilds: [
      {
        label: 'Label',
        valueName: 'main.title.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.main.title',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.main.title',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.main.title',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.main.title',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.main.title',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
    ]
  },
  {
    name: 'X axis title',
    feilds: [
      {
        label: 'Label',
        valueName: 'x.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.x',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.x',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.x',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.x',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.x',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.x',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
    ]
  },
  {
    name: 'X axis Ticks',
    feilds: [
      {
        label: 'Font',
        valueName: 'x.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'x.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'x.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'x.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'x.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'x.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Y axis title',
    feilds: [
      {
        label: 'Label',
        valueName: 'y.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.y',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.y',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.y',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.y',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.y',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.y',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
    ]
  },
  {
    name: 'Y axis Ticks',
    feilds: [
      {
        label: 'Font',
        valueName: 'y.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'y.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'y.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'y.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'y.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'y.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Graph',
    feilds: [
      {
        label: 'Low color',
        valueName: 'lowcolor',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Middle color',
        valueName: 'midcolor',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'High color',
        valueName: 'highcolor',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Direction',
        valueName: 'direction',
        min: null,
        max: null,
        items: heatmapDerection,
        type: 'select'
      },
      {
        label: 'Panel color',
        valueName: 'panel.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Grid color',
        valueName: 'grid.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Plot background',
        valueName: 'plot.background',
        min: null,
        max: null,
        items: null,
        type: 'color'
      }
    ]
  }
]

export const SurvivalConfigurationForm = [
  {
    name: '',
    feilds: [
      {
        label: 'Main title lable',
        valueName: 'main.title.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'X axis label',
        valueName: 'x.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Y axis label',
        valueName: 'y.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Risk Table (T/F)',
        valueName: 'risk.table',
        min: null,
        max: null,
        items: null,
        type: 'checkbox'
      },{
        label: 'Confidence Interval (T/F)',
        valueName: 'conf.int',
        min: null,
        max: null,
        items: null,
        type: 'checkbox',
      },
      {
        label: 'P. value (T/F)',
        valueName: 'pval',
        min: null,
        max: null,
        items: faceStyle,
        type: 'checkbox'
      },
      {
        label: 'X axis lower limit',
        valueName: 'x.lim.l',
        min: null,
        max: null,
        items: faceStyle,
        type: 'numeric'
      },
      {
        label: 'X axis higher limit',
        valueName: 'x.lim.h',
        min: null,
        max: null,
        items: faceStyle,
        type: 'numeric'
      },
      {
        label: 'Y axis lower limit',
        valueName: 'y.lim.l',
        min: null,
        max: null,
        items: faceStyle,
        type: 'numeric'
      },
      {
        label: 'Y axis higher limit',
        valueName: 'y.lim.h',
        min: null,
        max: null,
        items: faceStyle,
        type: 'numeric'
      },
      {
        label: 'Color group1',
        valueName: 'color1',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Color group2',
        valueName: 'color2',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Color group3',
        valueName: 'color3',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Color group4',
        valueName: 'color4',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Color group5',
        valueName: 'color5',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Color group6',
        valueName: 'color6',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
    ]
  }
]

export const MixedMultivariateConfigurationFrom = [
  {
    name: 'MainTitle',
    feilds: [
      {
        label: 'Label',
        valueName: 'main.title.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.main.title',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.main.title',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.main.title',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },{
        label: 'Size',
        valueName: 'size.main.title',
        min: null,
        max: null,
        items: null,
        type: 'numeric',
      },
      {
        label: 'Face',
        valueName: 'face.main.title',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      }
    ]
  },
  {
    name: 'X axis title:',
    feilds: [
      {
        label: 'Label',
        valueName: 'x.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.x',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.x',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.x',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.x',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.x',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.x',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
    ]
  },
  {
    name: 'X axis Ticks:',
    feilds: [
      {
        label: 'Font',
        valueName: 'x.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'x.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'x.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'x.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'x.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'x.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Y axis title:',
    feilds: [
      {
        label: 'Label',
        valueName: 'y.axis.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'hjust.y',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'family.y',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'color.y',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'size.y',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'face.y',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'angle.y',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },

    ]
  },
  {
    name: 'Y axis Ticks:',
    feilds: [
      {
        label: 'Font',
        valueName: 'y.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'y.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'y.text.size',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Face',
        valueName: 'y.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
      {
        label: 'Angle',
        valueName: 'y.text.angle',
        min: 0,
        max: 360,
        items: null,
        type: 'slider'
      },
      {
        label: 'Align',
        valueName: 'y.text.hjust',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
    ]
  },
  {
    name: 'Legend body:',
    feilds: [
      {
        label: 'Background color',
        valueName: 'legend.background.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Position',
        valueName: 'legend.position',
        min: null,
        max: null,
        items: positionStyle,
        type: 'select'
      },
      {
        label: 'Direction',
        valueName: 'legend.direction',
        min: null,
        max: null,
        items: directionStyle,
        type: 'select'
      },
      {
        label: 'Label',
        valueName: 'legend.title.lab',
        min: null,
        max: null,
        items: null,
        type: 'text'
      },
      {
        label: 'Align',
        valueName: 'legend.title.align',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'legend.title.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'legend.title.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'legend.title.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'legend.title.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
    ]
  },
  {
    name: 'Legend Text:',
    feilds: [
      {
        label: 'Align',
        valueName: 'legend.text.align',
        min: null,
        max: null,
        items: null,
        type: 'align'
      },
      {
        label: 'Font',
        valueName: 'legend.text.family',
        min: null,
        max: null,
        items: fontFamily,
        type: 'select'
      },
      {
        label: 'Color',
        valueName: 'legend.text.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Size',
        valueName: 'legend.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Face',
        valueName: 'legend.text.face',
        min: null,
        max: null,
        items: faceStyle,
        type: 'select'
      },
    ]
  },
  {
    name: 'Graph:',
    feilds: [
      {
        label: 'Horizontal line size',
        valueName: 'hlines.thickness',
        min: 1,
        max: 10,
        items: null,
        type: 'slider'
      },
      {
        label: 'Horizontal line color',
        valueName: 'hlines.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Side ticks height',
        valueName: 'legend.text.size',
        min: null,
        max: null,
        items: null,
        type: 'numeric'
      },
      {
        label: 'Vertical line size',
        valueName: 'vlines.thickness',
        min: 1,
        max: 10,
        items: null,
        type: 'slider'
      },
      {
        label: 'Vertical line color',
        valueName: 'vlines.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Vertical line type',
        valueName: 'vline.lty',
        min: null,
        max: null,
        items: lineType,
        type: 'select'
      },
      {
        label: 'Point color',
        valueName: 'point.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Point size',
        valueName: 'point.size',
        min: 1,
        max: 10,
        items: null,
        type: 'slider'
      },
      {
        label: 'Point shape',
        valueName: 'point.shape',
        min: null,
        max: null,
        items: poinShape,
        type: 'select'
      },
      {
        label: 'Panel color',
        valueName: 'panel.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Grid color',
        valueName: 'grid.color',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
      {
        label: 'Plot background',
        valueName: 'plot.background',
        min: null,
        max: null,
        items: null,
        type: 'color'
      },
    ]
  }
]