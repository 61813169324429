import { SvgIcon } from "@mui/material";
import React from "react";

export const StatisticThirdIcon = () => {
  return (
    <SvgIcon
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 300 300"
      xmlSpace="preserve"
    >
      <style>
        {
          "\n    .st0,.st1{fill:none;stroke:#5467ee;stroke-width:8.9396;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st1{stroke:#639aef}\n  "
        }
      </style>
      <path className="st0" d="M81.2 80.9v138.2h137.6m-113-23.5v-27.7" />
      <path className="st1" d="M134.9 195.6v-62.5" />
      <path className="st0" d="M164.1 195.6v-45.1" />
      <path className="st1" d="M193.3 195.6V98.3" />
    </SvgIcon>
  );
};
