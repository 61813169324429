import React from "react";
import { DatasetItem } from "./DatasetItem";
import { addChat } from "actions/chats";
import { useDispatch } from "react-redux";
import { DatasetListWrapper } from "../styles";
import { CircularProgress, Stack } from "@mui/material";
import { DatasetItemName } from "./styles";

export const DatasetsList = ({ list, loading }) => {
  const dispatch = useDispatch();

  const onDatasetSelect = (dataset) => dispatch(addChat({ dataset }));

  return (
    <>
      {loading && (
        <Stack alignItems="center" justifyContent="center" minHeight="288px">
          <CircularProgress color="primary" />
        </Stack>
      )}

      {!loading && !!list.length && (
        <DatasetListWrapper direction="row">
          {list.map((item) => (
            <DatasetItem key={item.id} dataset={item} onDatasetSelect={onDatasetSelect} />
          ))}
        </DatasetListWrapper>
      )}

      {!loading && !list.length && (
        <Stack alignItems="center" justifyContent="center" minHeight="288px">
          <DatasetItemName noWrap variant="h4">
            No datasets found
          </DatasetItemName>
        </Stack>
      )}
    </>
  );
};
