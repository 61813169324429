import { useEffect, useMemo, useState } from "react";

import { AnalyseTabs } from "../AnalyseTabs";
import { MultivariateAnalyse } from "../MultivariateAnalyse";

import { AnalyseMessageItem } from "../MessageList/styles";
import { HeaderBlock } from "./components/HeaderBlock";
import { FooterBlock } from "./components/FooterBlock";
import { gaClickCallback } from "helpers/common";

export const ITEM_TABS = ["Table", "Graph"];

export const AnalyseItem = ({ text, callback, currentAnalyse }) => {
  const [tabs, setTabs] = useState({});
  const [testTabs, setTestTabs] = useState({});
  const [selectedShowTab, setSelectedShowTab] = useState(ITEM_TABS[0]);

  const data = useMemo(() => currentAnalyse?.summary?.data, [currentAnalyse?.summary?.data]);

  const handleSetCurrentTab = () => {
    switch (currentAnalyse.analysis_type) {
      case "dependent":
      case "compare_groups":
      case "multivariate_single":
        return compareGroupsTabs(data);
      case "correlations":
        return correlationsTabs(data);
      case "mixed_multivariate":
      case "multivariate":
        return setTabs(multivariateTabs(data));
      default:
        break;
    }
  };

  useEffect(() => {
    if (!currentAnalyse) return;

    handleSetCurrentTab(currentAnalyse.analysis_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnalyse, data]);

  // const correlationsTabs = (data) => {
  //   let correlationsTabs = [];
  //   if (data?.all) {
  //     let correlationData = {
  //       matrix: data.all.matrix,
  //     };
  //     let keys = Object.keys(data.all.tables);
  //     keys.forEach((key) => {
  //       correlationData[key] = data.all.tables[key];
  //     });
  //     correlationsTabs.push(<CorelationAcardion data={correlationData} />);
  //   } else {
  //     let keys = Object.keys(data);
  //     keys.forEach((key) => {
  //       let correlationData = {
  //         matrix: data[key].matrix,
  //       };
  //       let keys2 = data[key]?.tables ? Object.keys(data[key]?.tables) : null;
  //       keys2?.forEach((key2) => {
  //         correlationData[key2] = data[key].tables[key2];
  //       });
  //       correlationsTabs.push(<CorelationAcardion data={correlationData} />);
  //     });
  //   }
  //   return correlationsTabs;
  // };

  const correlationsTabs = (data) => {
    if (data?.all) {
      let correlationData = {
        matrix: data.all.matrix,
      };
      let keys = Object.keys(data.all.tables);
      keys.forEach((key) => {
        correlationData[key] = data.all.tables[key];
      });

      setTestTabs({
        content: correlationData,
        tabs: [],
      });
    } else {
      console.log("with split");
      // let keys = Object.keys(data);
      // keys.forEach((key) => {
      //   let correlationData = {
      //     matrix: data[key].matrix,
      //   };
      //   let keys2 = data[key]?.tables ? Object.keys(data[key]?.tables) : null;
      //   keys2?.forEach((key2) => {
      //     correlationData[key2] = data[key].tables[key2];
      //   });
      // });
    }
  };

  const compareGroupsTabs = (data) => {
    if (data?.all) {
      let keys = Object.keys(data?.all?.tables);

      setTestTabs({
        content: data.all.tables,
        tabs: keys,
      });
    } else {
      let keys = Object.keys(data);
      keys.forEach((key) => {
        let keys2 = Object.keys(data[key].tables);
        setTestTabs({
          content: data[key].tables,
          tabs: keys2,
        });
        // keys2.forEach((key2, index) => (
        //   <AnalyseTable data={data[key].tables[key2]} tabs={keys} key={index} />
        // ));
      });
    }
  };

  const multivariateTabs = (data) => {
    let multivariateTabs = [];
    let images = [];

    if (data?.all) {
      let keys = Object.keys(data.all.tables.all_models);
      multivariateTabs.push(data.all.tables.main_independents);
      images.push(currentAnalyse?.summary?.images.all.main_independents);

      keys.forEach((key, index) => {
        multivariateTabs.push(data.all.tables.all_models[key]);
        images.push(currentAnalyse?.summary?.images.all.all_models[key]);
      });
    } else {
      let keys = Object.keys(data);
      keys.forEach((key, index) => {
        let keys2 = Object.keys(data[key].tables.all_models);
        multivariateTabs.push(data[key].tables.main_independents);
        images.push(currentAnalyse?.summary?.images[key].main_independents);

        keys2.forEach((key2, index2) => {
          multivariateTabs.push(data[key].tables.all_models[key2]);
          images.push(currentAnalyse?.summary?.images[key].all_models[key2]);
        });
      });
    }
    return { multivariateTabs, images };
  };

  return (
    <AnalyseMessageItem column="true">
      <HeaderBlock
        currentAnalyse={currentAnalyse}
        value={selectedShowTab}
        handleChange={(_, newValue) => {
          gaClickCallback({
            label: `${newValue} analyse tab`,
            callback: () => setSelectedShowTab(newValue),
          });
        }}
      />

      {!!tabs?.multivariateTabs?.length && (
        <MultivariateAnalyse
          tabs={["Main Independent Variable", "All Models"]}
          content={tabs}
          callback={callback}
          currentAnalyse={currentAnalyse}
          {...{ selectedShowTab }}
        />
      )}
      {testTabs.content && (
        <AnalyseTabs
          tabs={testTabs}
          callback={callback}
          currentAnalyse={currentAnalyse}
          selectedShowTab={selectedShowTab}
        />
      )}

      {selectedShowTab === ITEM_TABS[0] && <FooterBlock id={currentAnalyse?.id} />}
    </AnalyseMessageItem>
  );
};
