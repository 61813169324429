import { SvgIcon } from "@mui/material";
import * as React from "react";
export const StarsIcon = (props) => (
  <SvgIcon
    style={{ width: "17px", height: "17px", fill: "none" }}
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.083 17.667V13.5m0-8.333V1M1 3.083h4.167M1 15.583h4.167m5-13.75L8.722 5.591c-.235.61-.353.916-.536 1.173a2.5 2.5 0 0 1-.588.589c-.257.183-.563.3-1.174.535L2.667 9.333l3.757 1.445c.611.236.917.353 1.174.536.227.162.426.36.588.588.183.257.3.563.536 1.174l1.445 3.757 1.445-3.757c.235-.611.352-.917.535-1.174a2.5 2.5 0 0 1 .589-.588c.257-.183.562-.3 1.173-.536l3.758-1.445-3.758-1.445c-.61-.235-.916-.352-1.173-.535a2.5 2.5 0 0 1-.589-.589c-.183-.257-.3-.562-.535-1.173z"
      stroke="#2F80ED"
      strokeWidth="1.667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
