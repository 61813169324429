import { useSelector, useDispatch } from 'react-redux';
import { 
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  Grid
} from '@mui/material';

import { handleChangeConfiguration } from '../../actions/datasets';
import { separatorOptions } from './constants';

export function DatasetFileConfiguration() {
  const dispatch = useDispatch();
  const datasetCnfiguration = useSelector(state => state.datasetsReducer.datasetCnfiguration);

  const handleChange = event => {
    let configuration ={...datasetCnfiguration};
    configuration[event.target.name] =  event.target.name === 'header' ? event.target.checked : event.target.value;
    dispatch(handleChangeConfiguration(configuration));
  }

  return (
    <Grid container spacing={2} sx={{paddingTop: '10px'}}>
      <Grid item>
        <FormControlLabel
          label="Heading"
          control={
            <Checkbox
              color="secondary"
              size="small"
              name="header"
              checked={datasetCnfiguration.header}
              onChange={handleChange}
            />
          }
        />
      </Grid>
      
      {/* <Grid item>
        <FormControl sx={{width: '170px'}} size="small">
          <InputLabel color="secondary">Row Names</InputLabel>
          <Select
            defaultValue={'1920'}
            label="Row Names"
            color="secondary"
          >
            {rowOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
      
      <Grid item>
        <FormControl sx={{width: '130px'}} size="small">
          <InputLabel color="secondary">Separator</InputLabel>
          <Select
            value={datasetCnfiguration.sep}
            onChange={handleChange}
            name="sep"
            label="Separator"
            color="secondary"
          >
            {separatorOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <TextField
          value={datasetCnfiguration.na_values}
          onChange={handleChange}
          name="na_values"
          color="secondary"
          size="small"
          label="N/A String"
        />
      </Grid>
    </Grid>
  )
}