export const cl = {
  headerWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'inline-flex',
    gap: '20px',
    paddingBottom: '20px',
    borderBottom: '1px rgba(0, 0, 0, 0.05) solid',
  },

  title: {
    color: 'black',
    fontSize: '18px',
    fontWeight: 500,
    wordWrap: 'break-word',
  },
}
