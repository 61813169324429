import { Box, Button, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { styled } from "@mui/material";

const Sheilf = styled("div")(() => ({
  width: "100px",
  border: "1px solid #1CB4AE",
  borderRadius: "6px",
  background: "#ECEBEF",
  textAlign: "center",
  padding: "10px 20px 10px 20px",
  display: "inline-block",
}));

export default function GoldVariable({ golds, dogs, setGolds, setDogs }) {
  const addNewShelf = () => {
    let _golds = [...golds];
    let _dogs = [...dogs];
    _golds.push([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    _dogs.map((dog) => {
      return dog.shelfs.push([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ]);
    });
    setGolds(_golds);
    setDogs(_dogs);
  };

  const editGolds = (index, index2) => (event) => {
    let _golds = [...golds];
    _golds[index][index2] = event.target.checked;
    setGolds(_golds);
  };

  return (
    <Box>
      {golds.map((item, index) => (
        <Grid container marginBottom={"10px"}>
          <Grid item>
            <Sheilf>{"shelf " + (index + 1)}</Sheilf>
            {item.map((shelf, index2) => (
              <FormControlLabel
                control={<Checkbox checked={shelf} />}
                onChange={editGolds(index, index2)}
                label={index2 + 1}
                labelPlacement="top"
                sx={{ marginLeft: 0, marginRight: 0 }}
              />
            ))}
          </Grid>
        </Grid>
      ))}
      <Button variant="contained" color="secondary" onClick={addNewShelf}>
        add sheif +
      </Button>
    </Box>
  );
}
