import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects } from '../../actions/projects';
import { Box, Button } from '@mui/material';
import { SelectInput } from '../../components/inputs/select';
import { TextField } from '../../components/inputs/textInput';


export default function SelectProject({projectId, onChange, datasetName, setDatasetName}) {

  const dispatch = useDispatch();
  const projects = useSelector(state => state.projectsReducer.list)

  useEffect(() => {
    dispatch(fetchProjects());
  }, [])

  return (
    <Box maxWidth={'300px'}>
      <SelectInput
        label="Select Project"
        value={projectId}
        items={projects.map(project => {
          return {name: project.project_name, value: project.id}
        })}
        onChange={e => onChange(e.target.value)}
      />
      <br/>
      <br/>
      <TextField
        label="Dataset name"
        value={datasetName}
        onChange={ e => setDatasetName(e.target.value)}
      />
      <br/>
      <br/>
      <Button variant="contained" color='secondary'>Create New Project</Button>
    </Box>
  )
}