import { useSelector } from 'react-redux';
import { Grid, Box, Select, MenuItem } from '@mui/material';
import { 
  Chart as ChartJS, 
  ArcElement, 
  Tooltip, 
  Legend, 
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { Pie, Doughnut, Bar } from 'react-chartjs-2';

import { BoxPlot } from './boxPlotChart'
import { useState } from 'react';


const ordinalTypes = [
  {name: 'Bar', value: 'bar'},
  {name: 'Bar Border Radius', value: 'radius'},
  {name: 'Bar Horizontal', value: 'horizontal'},
  {name: 'Pie', value: 'pie'},
  {name: 'Doughnut', value: 'donut'},
  {name: 'Boxplot', value: 'boxplot'},
  {name: 'Histogram', value: 'histogram'}
]

const histogramOptions = {
  scales: {
    x: {
        type: 'linear',
        offset: false,
        grid: {
          offset: false
        },
        ticks: {
          stepSize: 2
        },
    }, 
    y: {
        // beginAtZero: true
        title: {
          display: true,
        }
    }
  }
}

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

export const ChartItem = ({variable}) => {
  const defaultType = variable.variable_type === 'factor' ? 'pie' : variable.variable_type === 'ordinal' ? 'bar' : 'histogram' 
  const [selectedChart, setSelectedChart] = useState(defaultType)
  const frequency = useSelector(state => state.datasetsReducer.frequency)
  const summary = useSelector(state => state.datasetsReducer.summary)
  const dataset = useSelector(state => state.datasetsReducer.data)

  const renderChart = variable => {
    if(selectedChart === 'bar' || selectedChart === 'radius' || selectedChart === 'horizontal') {
      const data = createBarChartData(variable.variable_name);
      return selectedChart === 'horizontal' ? (
          <Bar data={data} redraw={true} options={{ maintainAspectRatio: false, indexAxis: 'y' }}/>
        ) : (
          <Bar data={data} redraw={true} options={{ maintainAspectRatio: false, indexAxis: 'x' }}/>
        )
    }
    if(selectedChart === 'pie' || selectedChart === 'donut') {
      const data = createPieChartData(variable.variable_name)
      return selectedChart === 'pie' || !selectedChart ? (
          <Pie data={data} options={{ maintainAspectRatio: false }}/>
        ) : (
          <Doughnut data={data} options={{ maintainAspectRatio: false }}/>
        )
    }
    if(selectedChart === 'boxplot' || selectedChart === 'histogram') {
      const data = createBoxPlotChartData(variable.variable_name)
      return selectedChart === 'boxplot' ? (
          <BoxPlot data={data} variable={variable}/>
        ) : (
          <Bar data={data} options={histogramOptions}/>
        )
    }
  }

  const createBarChartData = (variable) => {
    let data = {
      labels: [],
      datasets: [
        {
          axis: selectedChart === 'horizontal' ? 'y': 'x',
          label: variable,
          data: [],
          backgroundColor: ['#167fbb', '#CD3200', '#53960b', '#642699', '#e99b1c', '#535377'],
          borderRadius: selectedChart === 'radius' ? 200 : 0
        }
      ]
    };

    frequency[variable].forEach(item => {
      data.datasets[0].data.push(item.Frequency)
      data.labels.push(item.Values)
    })

    return data
  }

  const createPieChartData = (variable) => {
    let data = {
      labels: [],
      datasets: [
        {
          label: variable,
          data: [],
          backgroundColor: ['#167fbb', '#CD3200', '#53960b', '#642699', '#e99b1c', '#535377']
        }
      ]
    };
    frequency[variable].forEach(item => {
      data.datasets[0].data.push(item.Frequency);
      data.labels.push(item.Values);
    })

    return data
  }

  const createBoxPlotChartData = (variable) => {
    let data = {}
    if(selectedChart === 'boxplot') {
      let scatters = []
      dataset.forEach((item) => {
        scatters.push(item[variable])
      })
      data = {
        labels: [variable],
        datasets: [
          {
            label: 'Dataset 1',
            backgroundColor: 'rgba(255,0,0,0.2)',
            borderColor: 'red',
            borderWidth: 2,
            padding: 100,
            itemRadius: 3,
            itemStyle: 'circle',
            itemBackgroundColor: '#167fbb',
            outlierBackgroundColor: '#167fbb',
            data: [
              {
                min: summary[variable].min[0],
                q1: summary[variable]['25%'][0],
                median: summary[variable]['50%'][0],
                q3: summary[variable]['75%'][0],
                max: summary[variable].max[0],
                items: scatters,
              },
            ],
          },
        ],
      };
    } else {
      data = {
        labels: [],
        datasets: [
          {
            axis: selectedChart === 'horizontal' ? 'y': 'x',
            label: variable,
            data: [],
            backgroundColor: ['#167fbb'],
            barPercentage: 1,
            categoryPercentage: 1,
          }
        ]
      };
      
      frequency[variable].forEach(item => {
        data.datasets[0].data.push(item.Frequency)
        item.Values ? data.labels.push(item.Values) : data.labels.push(item.value)
      })
    }

    return data;
  }

  return (
   
    <Grid container justifyContent={'center'} alignItems={'center'} height={'500px'}>
      <Select
        value={selectedChart}
        onChange={(e) => setSelectedChart(e.target.value)}
        size="small"
        fullWidth
      >
        {ordinalTypes.map(item => (
          <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
        ))}
      </Select>
      <Box width={"100%"} maxWidth={'600px'} height={"400px"}>{renderChart(variable)}</Box>
    </Grid>
  )
};



