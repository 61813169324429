import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { SearchIcon } from "../../../../../../../Icons/SearchIcon";

export const TableHeader = ({ search, onDatasetSearch }) => {
  return (
    <Stack
      direction="row"
      gap="50px"
      justifyContent="space-between"
      alignItems="center"
      p="24px"
      width="calc(100% - 48px)"
      mx="40px"
      sx={{ borderBottom: "1px #EAECF0 solid" }}
    >
      <Typography variant="h4">Recent Datasets</Typography>
      <TextField
        name="datasetName"
        value={search}
        InputProps={{
          placeholder: "Search dataset",
          startAdornment: <SearchIcon />,
          sx: { width: "437px", backgroundColor: "#ffff" },
        }}
        onChange={onDatasetSearch}
      />
    </Stack>
  );
};
