import { useState } from 'react';
import {
  InputBase,
  FormControl,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInput = styled(InputBase)(
  () => ({
    background: '#E6EAEE',
    borderRadius: '5px',
    border: 'none',
    height: '27px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#3C475D',
    padding: '0 0 0 8px'
  })
)

const InputAdornment = styled(Button)(
  () => ({
    backgroundColor: '#1CB4AE',
    minWidth: 'auto',
    padding: '2px 5px',
    color: '#fff'
  })
)



export function EditGraphInput({label, value, onChange, name, type, notButton}) {
  const [_value, setValue] = useState(value);

  const handleChange = () => {
    const event = {
      target: {
        name: name,
        value: _value
      }
    }
    onChange(event);
  }

  const changeText = e => {
    if(notButton) {
      setValue(e.target.value)
      onChange(e)
    } else {
      setValue(e.target.value)
    }
  }

  return (
    <FormControl variant="standard" fullWidth>
      <StyledInput
        type={type}
        name={name}
        placeholder={label}
        value={_value}
        onChange={changeText}
        endAdornment={!notButton ?
          <InputAdornment size='small' position="end" onClick={handleChange}>Save</InputAdornment> : null
        }
      />
    </FormControl>
  )
}