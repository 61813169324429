import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";

import { DatasetFileConfiguration } from "./datasetFileConfigurations";
import { DatasetPreviewTable } from "./datasetPreviewTable";
import { saveDataset } from "../../actions/datasets";

export function DatasetSettings({ open, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataSetFile = useSelector((state) => state.datasetsReducer.dataSetFile);

  const handleSave = () => {
    dispatch(saveDataset(navigate));
  };

  return (
    dataSetFile && (
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Dataset Settings</DialogTitle>
        <DialogContent>
          <DatasetFileConfiguration />
          <Grid container sx={{ paddingTop: "20px" }}>
            <Box sx={{ width: "40%" }}>
              <Typography variant="subtitle1">File Input</Typography>
              <Box
                sx={{
                  maxHeight: "400px",
                  overflowX: "scroll",
                  paddingRight: "10px",
                }}
              >
                {dataSetFile.raw_data}
              </Box>
            </Box>
            <Box sx={{ width: "60%" }}>
              <Typography variant="subtitle1">Data Preview</Typography>
              <DatasetPreviewTable />
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="info" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={handleSave}>
            Save Dataset
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}
