import { SvgIcon } from "@mui/material";
import React from "react";

export const StatisticSecondIcon = (props) => {
  return (
    <SvgIcon
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      style={{ fill: "none" }}
      viewBox="0 0 300 300"
      {...props}
    >
      <style>{"\n    .st2{fill:#639aef}.st3{fill:#5467ee}\n  "}</style>
      <path
        style={{
          fill: "none",
          stroke: "#5467ee",
          strokeWidth: 8.9396,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
        d="M81.2 80.9v138.2h137.6"
      />
      <path
        style={{
          fill: "none",
          stroke: "#639aef",
          strokeWidth: 8.9396,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
        d="M104.5 196.6 213.3 93.4"
      />
      <circle className="st2" cx={191.3} cy={172.1} r={8.3} />
      <circle className="st2" cx={108.1} cy={127.5} r={8.3} />
      <circle className="st3" cx={171.6} cy={89.2} r={8.3} />
      <circle className="st2" cx={138.4} cy={129.3} r={8.3} />
      <circle className="st3" cx={141.7} cy={194.9} r={8.3} />
    </SvgIcon>
  );
};
