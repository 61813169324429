import { useSelector } from "react-redux";
import { ActiveChat } from "./components/ActiveChat";
import { UserNavbar } from "./components/UserNavbar";

import { ChatContentWrapper } from "./styled";
import { EmptyUserScreen } from "./components/EmptyUserScreen";
import { NoneSelectedChatScreen } from "./components/NoneSelectedChatScreen";
import { CircularProgress, Stack } from "@mui/material";

export const ChatContent = () => {
  const { namesList: datasets, loading: datasetsLoading } = useSelector((state) => state.datasetsReducer);
  const { id: activeDatasetId } = useSelector((state) => state.chatsReducer.activeDataset) || {};

  return (
    <ChatContentWrapper>
      <UserNavbar />
      {datasetsLoading ? (
        <Stack alignItems="center" justifyContent="center" height="calc(100svh - 100px)">
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <>
          {activeDatasetId ? (
            <ActiveChat />
          ) : !!datasets.length ? (
            <NoneSelectedChatScreen />
          ) : (
            <EmptyUserScreen />
          )}
        </>
      )}
    </ChatContentWrapper>
  );
};
