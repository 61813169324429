import { ContentBlockHeader } from "../ContentBlockHeader";
import { TextBlock } from "../TextBlock";

export const ContentBlock = ({ handleClose }) => {
  return (
    <>
      <ContentBlockHeader title="Terms of use" handleClose={handleClose} />
      <TextBlock />
    </>
  );
};
