import { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Checkbox,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabsSet } from '../../components/tabs-set';
import { AnalyseTable } from './analyseTable';
import { GraphTreeView } from './graphTreeView';
import { ExportTables } from './exportTables';

export const MultivariateTabels = ({
  data,
  independent,
  analysisType
}) => {

  const [tabs, setTabs] = useState([]);
  const [treeView, setTreeView] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [openExport, setOpenExport] = useState(false);

  useEffect(() => {
      let _treeView = []
      if(data?.all) {
        if(independent) {
          _treeView = [{name: 'all', selected: false, open: true, data: [], route: 'all'}]
          let keys = Object.keys(data.all.tables.main_independents);
          keys.forEach((key, index) => {
            _treeView[0].data[index] = {name: key, selected: false, open: true, route: `all*${key}`}
          })
        } else {
          _treeView = [{name: 'all', selected: false, open: true, data: [], route: 'all'}]
          let keys = Object.keys(data.all.tables.all_models)
          keys.forEach((key, index) => {
            _treeView[0].data[index] = {name: key, selected: false, open: true, data: [], route: `all*${key}`}
            let keys2 = Object.keys(data.all.tables.all_models[key]);
            keys2.forEach((key2, index2) => {
              _treeView[0].data[index].data[index2] = {
                name: key2,
                selected: false,
                route: `all*${key}*${key2}`,
                value: data.all.tables.all_models[key][key2]
              }
            })
          })
        }
      } else {
        if(independent) {
          let keys = Object.keys(data)
          keys.forEach((key, index) => {
            _treeView[index] = { name: key, selected: false, open: true, data: [], route: `${key}` }
            let keys2 = Object.keys(data[key].tables.main_independents)
            keys2.forEach((key2, index2) => {
              _treeView[index].data[index2] = { name: key2, selected: false, open: true, route: `${key}*${key2}` }
            })
          })
        } else {
          let keys = Object.keys(data)
          keys.forEach((key, index) => {
            _treeView[index] = { name: key, selected: false, open: true, data: [], route: `${key}` }
            let keys2 = Object.keys(data[key].tables.all_models)
            keys2.forEach((key2, index2) => {
              _treeView[index].data[index2] = { name: key2, selected: false, open: true, data: [], route: `${key}*${key2}` }
              let keys3 = Object.keys(data[key].tables.all_models[key2])
              keys3.forEach((key3, index3) => {
                _treeView[index].data[index2].data[index3] = {
                  name: key3,
                  selected: false,
                  route: `${key}*${key2}*${key3}`,
                  value: data[key].tables.all_models[key2][key3]
                }
              })
            })
          })
        }
      }
      setTreeView(_treeView);
  }, [data, independent])

  useEffect(() => {
    let multivariateTabs = [];
    if(data?.all) {
      let keys = Object.keys(data.all.tables.all_models)
      if(independent) {
        multivariateTabs.push({
          name: `all`,
          component: (
            <MultivariateAcardion 
              data={data.all.tables.main_independents}
              handleSelect={handleSelectTreeView}
              treeView={treeView[0]?.data}
              route={`all`}
            />
          ),

      })
      } else {
        keys.forEach((key, index) => {
          multivariateTabs.push({
            name: `all:${key}`,
            component: (
              <MultivariateAcardion
                data={data.all.tables.all_models[key]}
                handleSelect={handleSelectTreeView}
                treeView={treeView[0]?.data[index].data}
                route={`all*${key}`}
              />
            )
          })
        })
      }
    } else {
      let keys = Object.keys(data)
      keys.forEach((key, index) => {
        let keys2 = Object.keys(data[key].tables.all_models)
        if(independent) {
          multivariateTabs.push({
            name: `${key}`,
            component: (
              <MultivariateAcardion
                data={data[key].tables.main_independents}
                handleSelect={handleSelectTreeView}
                treeView={treeView[index]?.data}
                route={`${key}`}
              />
            )
          })
        } else {
          keys2.forEach((key2, index2) => {
            multivariateTabs.push({
              name: `${key}:${key2}`,
              component: (
                <MultivariateAcardion
                  data={data[key].tables.all_models[key2]}
                  handleSelect={handleSelectTreeView}
                  treeView={treeView[index]?.data[index2].data}
                  route={`${key}*${key2}`}
                />)
            })
          })
        }
      })
    }
    setTabs(multivariateTabs);
  }, [data, independent, treeView])

  const handleSelectTable = (route, checked) => {
    const _route = analysisType + "*" + route
    let _selectedTables = [...selectedTables];
    if(checked) {
      _selectedTables.push(_route);
      setSelectedTables(prevState => [...prevState, _route]);
    } else {
      setSelectedTables(prevState => {
        const index = prevState.findIndex(item => item === _route);
        if(index !== -1)prevState.splice(index, 1);
        return [...prevState]
      });
    }
  }
  
  const handleSelectTreeView = route => event => {
    let _treeView = [...treeView];
    const routeArr = route.split('*');
    if(routeArr.length === 1) {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      _treeView[index].selected = event.target.checked
      _treeView[index].data.forEach(item => {
        if(!item.data) {
          handleSelectTable(item.route ,event.target.checked);
        }
        item.selected = event.target.checked
        item.data?.forEach(item2 => {
          handleSelectTable(item2.route ,event.target.checked);
          item2.selected = event.target.checked
        })
      })
    } else if(routeArr.length === 2) {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      const index2 = _treeView[index].data.findIndex(item => item.name === routeArr[1])
      _treeView[index].data[index2].selected = event.target.checked
      if(!_treeView[index].data[index2].data) {
        handleSelectTable(_treeView[index].data[index2].route ,event.target.checked);
      }
      _treeView[index].data[index2].data?.forEach(item => {
        handleSelectTable(item.route ,event.target.checked);
        item.selected = event.target.checked
      })
    } else {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      const index2 = _treeView[index].data.findIndex(item => item.name === routeArr[1])
      const index3 = _treeView[index].data[index2].data.findIndex(item => item.name === routeArr[2])
      handleSelectTable(_treeView[index].data[index2].data[index3].route ,event.target.checked);
      _treeView[index].data[index2].data[index3].selected = event.target.checked
    }
    setTreeView(_treeView)
  }
  
  return !!tabs.length && !!treeView.length && (
    <Fragment>
      <Grid container justifyContent={'start'} spacing={2} sx={{width: '100%'}}>
        <Grid item>
          <Button
            sx={{background: '#1CB4AE', margin: '20px 0'}} 
            variant="contained"
            size="small"
            onClick={() => setOpenExport(true)}
          >
            Export
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <GraphTreeView treeView={treeView} setTreeView={setTreeView} handleSelectTreeView={handleSelectTreeView} />
        </Grid>
        <Grid item xs={9}>
          <TabsSet tabs={tabs} />
        </Grid>
      </Grid>
      <ExportTables
        open={openExport}
        handleClose={() => setOpenExport(!openExport)}
        selectedTables={selectedTables}
        mode={independent ? 'main_independents' : 'all_models'}
      />
    </Fragment>
  );
};

const MultivariateAcardion = ({
  data,
  handleSelect,
  treeView,
  route
}) => {
  const [keys, setKeys] = useState([])
  useEffect(() => {
    setKeys(Object.keys(data))
  }, [data])

  return keys.map((key, index) => (
    <Accordion key={index} defaultExpanded={!index} sx={{width: '100%'}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Checkbox
          checked={treeView?.find(item => item.name === key)?.selected || false}
          onChange={handleSelect(route+'*'+key)}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        />
        <Typography lineHeight={2.75}><b>{key}</b></Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AnalyseTable data={data[key]}/>
      </AccordionDetails>
    </Accordion>
  ))
}
