import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const HomeWrapper = styled(Grid)(() => ({
  height: "100svh",
  backgroundColor: "#ffff",
  alignItems: "center",
  //padding: "0 80px",
}));

export const TitleText = styled(Typography)(() => ({
  color: "#101828",
  fontSize: "60px",
  fontWeight: 600,
  lineHeight: "72px",
}));

export const SubTitleText = styled(Typography)(() => ({
  color: "#475467",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "30px",
}));
