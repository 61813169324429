import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GenieIcon } from "../../components/genie-icon";

import {
  Box,
  Table,
  Drawer,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const AnalysesListHeader = styled(Box)(() => ({
  padding: "34px",
  color: "#5A606B",
  fontSize: "24px",
  fontWeight: 600,
  borderBottom: "1px solid rgba(133, 147, 173, 0.26)",
  fontFamily: "montserrat",
  display: "flex",
  alignItems: "center",
}));

export const AnalysesList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const datasetAnalyses = useSelector((state) => state.analysesReducer.list);

  return (
    datasetAnalyses && (
      <Fragment>
        <Button
          sx={{ background: "#2D9CDB" }}
          variant="contained"
          size="small"
          startIcon={
            <GenieIcon
              iconWidth="15px"
              iconHeight="15px"
              type="dataset-white"
            />
          }
          onClick={() => setOpen(!open)}
        >
          Analyses List
        </Button>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(!open)}
          sx={{ zIndex: 1300 }}
        >
          <AnalysesListHeader>
            <GenieIcon type="analyses-inactive" />
            <Typography sx={{ marginLeft: "10px" }} variant="h4">
              List of Analyses
            </Typography>
          </AnalysesListHeader>
          <Box padding={"24px"}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>NO</TableCell>
                  <TableCell>Name Analysis</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datasetAnalyses.map((analyse, index) => (
                  <TableRow
                    key={analyse.id}
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/dashboard/analyse/${analyse.id}#table`)
                    }
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {analyse.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {analyse.analysis_type}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Drawer>
      </Fragment>
    )
  );
};
