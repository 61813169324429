import {
  getAnalyses,
  createCompareGroups,
  createCorrelations,
  getAnalyse,
  updateCompareGroups,
  getExportGraphs,
  updateCorrelation,
  createSurvival,
  createMultivariate,
  updateSurvival,
  deleteAnalyses,
  createMixedMultivariate,
  updateMixedMultivariate,
  getExportTables,
  updateMultivariate,
  createScreenTest,
} from "../services/analyses";
import { toast } from "react-toastify";

export const SET_ANALYSES = "ANALYSES/SET_ANALYSES";
export const CHANGE_FILTERS = "ANALYSES/CHANGE_FILTERS";
export const CHANGE_MAKE_ANALYSES = "ANALYSES/CHANGE_MAKE_ANALYSES";
export const CHANGE_ONE_MAKE_ANALYSES_COLUMN =
  "ANALYSES/CHANGE_ONE_MAKE_ANALYSES_COLUMN";
export const SET_ANALYSE = "ANALYSES/SET_ANALYSE";
export const SET_TREE_VIEW = "ANALYSES/SET_TREE_VIEW";
export const SET_SELECT_GRAPH_IMAGE = "ANALYSES/SET_SELECT_GRAPH_IMAGE";
export const SET_EXPORT_FILE_URL = "ANALYSES/SET_EXPORT_FILE_URL";
export const CLEAR_FILTERS = "ANALYSES/CLEAR_FILTERS";

export const fetchAnalyses = () => {
  return (dispatch, getState) => {
    getAnalyses(getState().analysesReducer.filters)
      .then((res) => {
        dispatch({
          type: SET_ANALYSES,
          list: res.data.records || [],
          count: res.data.count,
        });
      })
      .catch((errors) => {
        // if(errors.response) {
        //   dispatch({ type: SET_ERRORS, errors: errors.response.data.detail })
        //   dispatch(setShowErrors(true))
        // }
      });
  };
};

export const removeAnalyses = (id) => {
  return (dispatch) => {
    deleteAnalyses(id).then(() => {
      dispatch(fetchAnalyses());
    });
  };
};

export const changeFilters = (filters) => {
  return (dispatch) => {
    Promise.resolve(dispatch({ type: CHANGE_FILTERS, filters })).then(() =>
      dispatch(fetchAnalyses())
    );
  };
};

export const clearFilters = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTERS });
  };
};

export const changeMakeAnalyses = (makeAnalyses) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_MAKE_ANALYSES, makeAnalyses });
  };
};

export const changeOneMakeAnalysesColumn = (name, data) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_ONE_MAKE_ANALYSES_COLUMN, name, data });
  };
};

export const handleSaveMakeAnalyses = (
  data,
  analyseName,
  callback,
  closeLoader
) => {
  return (dispatch) => {
    switch (analyseName) {
      case "Compare Groups":
        createCompareGroups(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Correlations":
        createCorrelations(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Survival : Kaplan-Meyer":
        createSurvival(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Screen Test":
        createScreenTest(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Generalized Linear Models (GLM)":
        createMultivariate(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Linear Regression":
        createMultivariate(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Survival Regressions":
        createMultivariate(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Mixed Models: GLM":
        createMixedMultivariate(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Mixed Models: Linear Regression":
        createMixedMultivariate(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      case "Mixed Models: Survival Regressions":
        createMixedMultivariate(data)
          .then((res) => {
            dispatch({ type: SET_ANALYSE, analyse: res.data });
            closeLoader(false);
            callback(res.data.analysis_id);
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
            closeLoader(false);
          });
        break;

      default:
        return null;
    }
  };
};

export const fetchAnalyse = (id) => {
  return (dispatch) => {
    getAnalyse(id).then((res) => {
      dispatch(setTreeView(res.data.summary.data));
      dispatch({ type: SET_ANALYSE, analyse: res.data });
    });
  };
};

export const clearAnalyse = () => {
  return (dispatch) => {
    dispatch({ type: SET_ANALYSE, analyse: {} });
  };
};

export const setTreeView = (data) => {
  let _treeView = [];
  if (data?.null) {
    _treeView = [
      { name: "all", selected: false, open: true, data: [], route: "all" },
    ];
    let keys = Object.keys(data.null);
    keys.forEach((graph, index) => {
      _treeView[0].data[index] = {
        name: graph,
        selected: false,
        open: true,
        data: [],
        route: `all*${graph}`,
      };
      let keys2 = Object.keys(data.null[graph]);
      keys2.forEach((key2, index2) => {
        _treeView[0].data[index].data[index2] = {
          name: key2,
          selected: false,
          route: `all*${graph}*${key2}`,
        };
      });
    });
  } else {
    let keys = Object.keys(data);
    keys.forEach((key, index) => {
      _treeView[index] = {
        name: key,
        selected: false,
        open: true,
        data: [],
        route: `${key}`,
      };
      let keys2 = Object.keys(data[key]);
      keys2.forEach((key2, index2) => {
        _treeView[index].data[index2] = {
          name: key2,
          selected: false,
          open: true,
          data: [],
          route: `${key}*${key2}`,
        };
        let keys3 = Object.keys(data[key][key2]);
        keys3.forEach((key3, index3) => {
          _treeView[index].data[index2].data[index3] = {
            name: key3,
            selected: false,
            route: `${key}*${key2}*${key3}`,
          };
        });
      });
    });
  }
  return (dispatch) => {
    dispatch({ type: SET_TREE_VIEW, treeView: _treeView });
  };
};

export const changeTreeView = (treeView) => {
  return (dispatch) => {
    dispatch({ type: SET_TREE_VIEW, treeView: treeView });
  };
};

export const editCompareGroup = (data, route, callback) => {
  return (dispatch, getState) => {
    const analyse = getState().analysesReducer.analyse;
    updateCompareGroups(data)
      .then((res) => {
        if (data.data.save) {
          getAnalyse(data.data.analysis_id).then((analysis) => {
            dispatch({
              type: SET_ANALYSE,
              analyse: { ...analyse, summary: analysis.data.summary },
            });
            callback(false);
          });
        } else {
          dispatch(
            setSelectGraphImage(res.data.images[route[0]][route[1]][route[2]])
          );
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

export const editCorrelations = (data, route, callback) => {
  return (dispatch, getState) => {
    const analyse = getState().analysesReducer.analyse;
    updateCorrelation(data)
      .then((res) => {
        if (data.data.save) {
          getAnalyse(data.data.analysis_id).then((analysis) => {
            dispatch({
              type: SET_ANALYSE,
              analyse: { ...analyse, summary: analysis.data.summary },
            });
            callback(false);
          });
        } else {
          dispatch(
            setSelectGraphImage(res.data.images[route[0]][route[1]][route[2]])
          );
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

export const editSurvival = (data, route, callback) => {
  return (dispatch, getState) => {
    const analyse = getState().analysesReducer.analyse;
    updateSurvival(data)
      .then((res) => {
        if (data.data.save) {
          dispatch({
            type: SET_ANALYSE,
            analyse: { ...analyse, summary: res.data },
          });
          callback(false);
        } else {
          dispatch(
            setSelectGraphImage(res.data.images[route[0]][route[1]][route[2]])
          );
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

export const editMuktivariate = (data, route, callback) => {
  return (dispatch, getState) => {
    const analyse = getState().analysesReducer.analyse;
    updateMultivariate(data)
      .then((res) => {
        if (data.data.save) {
          getAnalyse(data.data.analysis_id).then((analysis) => {
            dispatch({
              type: SET_ANALYSE,
              analyse: { ...analyse, summary: analysis.data.summary },
            });
            callback(false);
          });
        } else {
          if (route.length > 2) {
            dispatch(
              setSelectGraphImage(res.data.images[route[0]][route[1]][route[2]])
            );
          } else {
            dispatch(
              setSelectGraphImage(
                res.data.images[route[0]].main_independents[route[1]]
              )
            );
          }
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

export const editMixedMuktivariate = (data, route, callback) => {
  return (dispatch, getState) => {
    const analyse = getState().analysesReducer.analyse;
    updateMixedMultivariate(data)
      .then((res) => {
        if (data.data.save) {
          dispatch({
            type: SET_ANALYSE,
            analyse: { ...analyse, summary: res.data },
          });
          callback(false);
        } else {
          dispatch(
            setSelectGraphImage(res.data.images[route[0]][route[1]][route[2]])
          );
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

export const setSelectGraphImage = (image) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECT_GRAPH_IMAGE, image });
  };
};

export const exportGraphs = (data, setOpenLoader) => {
  return (dispatch) => {
    getExportGraphs(data)
      .then((res) => {
        dispatch({ type: SET_EXPORT_FILE_URL, url: res.data });
        setOpenLoader(false);
      })
      .catch((err) => {
        setOpenLoader(false);
      });
  };
};

export const exportTables = (data, setOpenLoader) => {
  return (dispatch) => {
    getExportTables(data)
      .then((res) => {
        dispatch({ type: SET_EXPORT_FILE_URL, url: res.data });
        setOpenLoader(false);
      })
      .catch((err) => {
        setOpenLoader(false);
      });
  };
};

export const clearExportFileUrl = () => {
  return (dispatch) => {
    dispatch({ type: SET_EXPORT_FILE_URL, url: "" });
  };
};
