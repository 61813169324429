import { Button, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const EmptyScreenWrapper = styled(Stack)(() => ({
  height: "calc(100svh - 100px)",
  padding: "43px 40px",
  gap: "40px",
  backgroundColor: "#F9FAFB",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
}));

export const AddDatasetBtn = styled(Button)(() => ({
  color: "#ffff",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  wordWrap: "break-word",
  padding: "15px 18px",

  "& .MuiButton-startIcon": {
    margin: "0 12px 0 0",
  },

  marginBottom: "100px", //100px is the height of navbar
}));
