import { Button, Stack, styled, Typography } from "@mui/material";

export const DatasetItemWrapper = styled(Stack)(() => ({
  boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
  borderRadius: 16,
  border: "1px #EAECF0 solid",
  padding: "20px 0px",
  width: "328px",
  minWidth: "328px",
  overflow: "hidden",
  boxSizing: "border-box",
  marginTop: "24px",
  marginBottom: "30px",
  marginLeft: "24px",
  "&:last-child": {
    marginRight: "24px",
  },
}));

export const DatasetItemContent = styled(Stack)(() => ({
  borderBottom: "1px #EAECF0 solid",
  borderTop: "1px #EAECF0 solid",
  padding: "20px",
}));

export const SelectDatasetBtn = styled(Button)(() => ({
  width: "100%",
  maxWidth: "100%",
  padding: "10px 14px",
  margin: "20px 0 0",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "8px",
  border: "1px #D6BBFB solid",
  color: "#53389E",
  fontSize: 14,
  fontWeight: "600",
  lineHeight: "20px",

  "&:hover": {
    color: "#ffff",
  },
}));

export const DatasetItemName = styled(Typography)(() => ({
  width: "fit-content",
  maxWidth: "100%",
  color: "#101828",
  fontSize: 20,
  fontWeight: "700",
  lineHeight: "24px",
}));
