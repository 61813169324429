import {
  InputBase,
  Select,
  FormControl,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInput = styled(InputBase)(
  () => ({
    background: '#E6EAEE',
    borderRadius: '5px',
    border: 'none',
    height: '27px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#3C475D',
    padding: '0 8px'
  })
)

export function EditGraphSelect({ value, onChange, items, name }) {

  return (
    <FormControl variant="standard" fullWidth>
      <Select
        value={value}
        input={<StyledInput />}
        name={name}
        onChange={onChange}
      >
        {items.map(item => (
          <MenuItem key={name+item} value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}