import { Fragment, useState } from "react";
import { Button } from "@mui/material";
import { NewVariableDialog } from "./newVariableDialog";

export function CreateNewVariable() {
  const [openDialog, setOpenDialog] = useState(false);

 return (
  <Fragment>
    <Button
      sx={{background: '#219653'}}
      variant="contained"
      size="small"
      onClick={() => setOpenDialog(!openDialog)}
    >
      Create New Vriable
    </Button>
    <NewVariableDialog
      open={openDialog}
      handleClose={() => setOpenDialog(false)}
    />
  </Fragment>
 )
}