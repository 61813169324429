import StyledTooltip from "../../styledTooltip";
import { SendIcon } from "Icons/SendIcon";
import { InfoTooltipContent } from "pages/gpt/components/chatContent/components/InfoTooltipContent";

import { InputIconButton, StyledInput } from "./styles";

import { FormControl, Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { gaClickCallback } from "helpers/common";

export const ChatInput = ({
  label,
  name,
  onChange,
  handleKeyDown,
  handleSetExampleMessage,
  message,
  handleSubmit,
}) => {
  return (
    <Stack direction="row" gap="24px" px="40px">
      <FormControl fullWidth>
        <StyledInput
          name={name}
          value={message}
          placeholder={label}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          startAdornment={
            <StyledTooltip
              title={<InfoTooltipContent handleSetExampleMessage={handleSetExampleMessage} />}
              placement="top"
              arrow
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [410, 0] } }],
              }}
            >
              <InfoOutlinedIcon sx={{ mr: "10px", mt: "0.5px" }} fontSize="small" />
            </StyledTooltip>
          }
        />
      </FormControl>
      <InputIconButton
        disabled={!message}
        onClick={() =>
          gaClickCallback({
            label: `user sent message: ${message}`,
            callback: () => handleSubmit(),
          })
        }
      >
        <SendIcon />
      </InputIconButton>
    </Stack>
  );
};
