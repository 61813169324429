import { SvgIcon } from "@mui/material";
import React from "react";

export const DownloadAnalyseIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px", fill: "none" }}
      viewBox="0 0 24 24"
    >
      <path
        d="m9.32 11.68 2.56 2.56 2.56-2.56M11.88 4v10.17"
        stroke="#fff"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12.18c0 4.42-3 8-8 8s-8-3.58-8-8"
        stroke="#fff"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
