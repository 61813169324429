import { SET_PROJECTS, CHANGE_FILTERS, SET_SELECTED, SET_ERRORS } from '../actions/projects';

const initialState = {
  list: [],
  selected: {
    id: null,
    project_name: '',
    user_id: null,
    project_description: '',
    modified_at: '',
    created_at: ''
  },
  filters: {
    limit: 25,
    offset: 0,
    page: 0
  },
  count: 0,
  errors: []
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return { ...state, list: action.list, count: action.count };

    case CHANGE_FILTERS:
      return { ...state, filters: action.filters };

    case SET_SELECTED: 
      return { ...state, selected: action.project };

    case SET_ERRORS:
      return { ...state, errors: action.errors };
    
    default:
      return state;
  }
};

export default projectsReducer;
