import { useEffect, useState } from 'react';

import { TabsSet } from '../../components/tabs-set';
import { GraphTreeView } from './graphTreeView';
import { useSelector } from 'react-redux';
import { AnalyseGraphs } from './analyseGraphs';
import { ExportGraphs } from './exportGraphs';
import { Grid, Button, Box} from '@mui/material';

export const MultivariateGraphs = ({handleCloseEdit, independent}) => {
  const [tabs, setTabs] = useState([]);
  const [treeView, setTreeView] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const data = useSelector(state => state.analysesReducer.analyse?.summary?.images);

  useEffect(() => {
    if(!data) return
    const _treeView = createTreeView(data, independent)
    setTreeView(_treeView);
  }, [data, independent])

  useEffect(() => {
    if(!data) return
    const multivariateTabs = cretaeTabs(data, independent, treeView, handleSelectTreeView, handleCloseEdit)
    setTabs(multivariateTabs);
  }, [data, treeView, independent])


  const handleSelectTreeView = route => event => {
    let _treeView = [...treeView];
    const routeArr = route.split('*');

    if(routeArr.length === 1) {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      _treeView[index].selected = event.target.checked
      _treeView[index].data.forEach(item => {
        item.selected = event.target.checked
        item?.data?.forEach(item2 => {
          item2.selected = event.target.checked
        })
      })
    } else if(routeArr.length === 2) {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      const index2 = _treeView[index].data.findIndex(item => item.name === routeArr[1])
      _treeView[index].data[index2].selected = event.target.checked
      _treeView[index].data[index2]?.data?.forEach(item => {
        item.selected = event.target.checked
      })
    } else {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      const index2 = _treeView[index].data.findIndex(item => item.name === routeArr[1])
      const index3 = _treeView[index].data[index2].data.findIndex(item => item.name === routeArr[2])
      _treeView[index].data[index2].data[index3].selected = event.target.checked
    }
    setTreeView(_treeView)
  }

  return !!tabs.length && (
    <Box sx={{position: 'relative'}}>
      <Button
        sx={{
          background: '#1CB4AE',
          position: 'absolute',
          left: '420px',
          top: '-89px'
        }} 
        variant="contained"
        size="small"
        onClick={() => setOpenExport(true)}
      >
        Export
      </Button>
      <Grid container wrap='nowrap' sx={{marginTop: '40px'}}>
        <Grid item xs={3}>
          <GraphTreeView treeView={treeView} setTreeView={setTreeView} handleSelectTreeView={handleSelectTreeView} />
        </Grid>
        <Grid item xs={9}>
          <TabsSet tabs={tabs} search={false}/>
        </Grid>
      </Grid>
      <ExportGraphs open={openExport} handleClose={() => setOpenExport(false)} treeView={treeView}/>
    </Box>
  );
};

const cretaeTabs = (
  data,
  independent,
  treeView,
  handleSelectTreeView,
  handleCloseEdit
  ) => {
  let multivariateTabs = [];
  if(data?.all) {
    let keys = Object.keys(data.all.all_models)
    if(independent) {
      multivariateTabs.push({
        name: `all`,
        component: (
          <AnalyseGraphs
            data={data.all.main_independents}
            treeView={treeView[0]}
            handleSelectTreeView={handleSelectTreeView}
            handleCloseEdit={handleCloseEdit}
          />
        )
      })
    } else {
      keys.forEach((key, index) => {
        multivariateTabs.push({
          name: `all:${key}`,
          component: (
            <AnalyseGraphs
              data={data.all.all_models[key]}
              treeView={treeView[0].data[index]}
              handleSelectTreeView={handleSelectTreeView}
              handleCloseEdit={handleCloseEdit}
            />
          )
        })
      })
    }
  } else {
    let keys = Object.keys(data)
    keys.forEach((key, index) => {
      let keys2 = Object.keys(data[key].all_models)
      if(independent) {
        multivariateTabs.push({
          name: `${key}`,
          component: (
            <AnalyseGraphs
              data={data[key].main_independents}
              treeView={treeView.find(item => item.name === key)}
              handleSelectTreeView={handleSelectTreeView}
              handleCloseEdit={handleCloseEdit}
            />
          )
        })
      } else {
        keys2.forEach((key2, index2) => {
          multivariateTabs.push({
            name: `${key}:${key2}`,
            component: (
              <AnalyseGraphs
                data={data[key].all_models[key2]}
                treeView={treeView[index]?.data[index2]}
                handleSelectTreeView={handleSelectTreeView}
                handleCloseEdit={handleCloseEdit}
              />
            )
          })
        })
      }
    })
  }
  return multivariateTabs;
}

const createTreeView = (data, independent) => {
  let treeView = []
  if(data?.null) {
    if(independent) {
      treeView = [{name: 'all', selected: false, open: true, data: [], route: 'all'}]
      let keys = Object.keys(data.tables.main_independents);
      keys.forEach((key, index) => {
        treeView[0].data[index] = {name: key, selected: false, open: true, route: `all*${key}`, value: data.all.main_independents[key]}
      })
    } else {
      treeView = [{name: 'all', selected: false, open: true, data: [], route: 'all'}]
      let keys = Object.keys(data.tables.all_models)
      keys.forEach((key, index) => {
        treeView[0].data[index] = {name: key, selected: false, open: true, data: [], route: `all*${key}`}
        let keys2 = Object.keys(data.data.tables.all_models[key]);
        keys2.forEach((key2, index2) => {
          treeView[0].data[index].data[index2] = {
            name: key2,
            selected: false,
            route: `all*${key}*${key2}`,
            value: data.all.all_models[key][key2]
          }
        })
      })
    }
  } else {
    if(independent) {
      let keys = Object.keys(data)
      keys.forEach((key, index) => {
        treeView[index] = { name: key, selected: false, open: true, data: [], route: `${key}` }
        let keys2 = Object.keys(data[key].main_independents)
        keys2.forEach((key2, index2) => {
          treeView[index].data[index2] = { name: key2, selected: false, open: true, route: `${key}*${key2}`, value: data[key].main_independents[key2] }
        })
      })
    } else {
      let keys = Object.keys(data)
      keys.forEach((key, index) => {
        treeView[index] = { name: key, selected: false, open: true, data: [], route: `${key}` }
        let keys2 = Object.keys(data[key].all_models)
        keys2.forEach((key2, index2) => {
          treeView[index].data[index2] = { name: key2, selected: false, open: true, data: [], route: `${key}*${key2}`}
          let keys3 = Object.keys(data[key].all_models[key2])
          keys3.forEach((key3, index3) => {
            treeView[index].data[index2].data[index3] = {
              name: key3,
              selected: false,
              route: `${key}*${key2}*${key3}`,
              value: data[key].all_models[key2][key3]
            }
          })
        })
      })
    }
  }
  return treeView;
}