import React from "react";

import { DataSetWrapper } from "./styles";

import { DownloadIcon } from "../../../../../../Icons/DownloadIcon";

import { IconButton, Typography } from "@mui/material";

export const EmptyDataSet = ({ onClickCallback }) => {
  return (
    <DataSetWrapper>
      <IconButton sx={{ p: 0 }} disableRipple onClick={onClickCallback}>
        <DownloadIcon />
      </IconButton>
      <Typography variant="subtitle2" color="text.secondary">
        No Data Set <br />
        Please Add New Data Set <br />
        First
      </Typography>
      <Typography
        onClick={onClickCallback}
        variant="subtitle2"
        color="primary.main"
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Upload Data Set
      </Typography>
    </DataSetWrapper>
  );
};
