import {
  SET_ANALYSES,
  CHANGE_FILTERS,
  CHANGE_MAKE_ANALYSES,
  CHANGE_ONE_MAKE_ANALYSES_COLUMN,
  SET_ANALYSE,
  SET_TREE_VIEW,
  SET_SELECT_GRAPH_IMAGE,
  SET_EXPORT_FILE_URL,
  CLEAR_FILTERS
} from '../actions/analyses';

const initialState = {
  list: [],
  filters: {
    project_id: null,
    limit: 25,
    offset: 0,
    page: 0
  },
  analyse: {},
  makeAnalysesColumns: {},
  treeView: [],
  selectGraphImage: '',
  exportFileUrl: '',
  count: 0
};

const analysesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYSES:
      return { ...state, list: action.list, count: action.count };

    case CHANGE_FILTERS:
      return { ...state, filters: action.filters };

    case CLEAR_FILTERS:
      return { ...state, filters: initialState.filters }

    case CHANGE_MAKE_ANALYSES:
      return { ...state, makeAnalysesColumns: action.makeAnalyses };

    case CHANGE_ONE_MAKE_ANALYSES_COLUMN:
      return { ...state, makeAnalysesColumns: {...state.makeAnalysesColumns, [action.name]: action.data} };

    case SET_ANALYSE:
      return { ...state, analyse: action.analyse };

    case SET_TREE_VIEW:
      return { ...state, treeView: action.treeView };

    case SET_SELECT_GRAPH_IMAGE:
      return { ...state, selectGraphImage: action.image }

    case SET_EXPORT_FILE_URL:
      return { ...state, exportFileUrl: action.url }
    
    default:
      return state;
  }
};

export default analysesReducer;
