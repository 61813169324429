import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import * as Yup from "yup";

import { TextField } from "../inputs/textInput";

import { clearErrors, SET_DATASET_NAMES } from "../../actions/datasets";
import { useFormik } from "formik";
import { useEffect } from "react";
import { renameDataset } from "services/datasets";

export function RenameDataset({ open, handleClose, activeDataset }) {
  const dispatch = useDispatch();

  const datasets = useSelector((state) => state.datasetsReducer.namesList);

  const onClose = () => {
    dispatch(clearErrors());
    handleClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      datasetName: activeDataset?.name,
    },
    onSubmit: (values) => {
      dispatch(
        renameDataset(activeDataset?.id, { name: values.datasetName }).then((res) => {
          if (res.data) {
            const datasetList = [...datasets];
            const index = datasetList.findIndex((dataset) => dataset.id === activeDataset?.id);
            const updatedDataset = { ...datasetList[index], name: values.datasetName };
            if (index !== -1) datasetList.splice(index, 1, updatedDataset);

            dispatch({
              type: SET_DATASET_NAMES,
              namesList: datasetList || [],
            });
            onClose();
          }
        })
      );
    },
    validationSchema: Yup.object().shape({
      datasetName: Yup.string()
        .required("Required field")
        .min(4, "Ensure this value has at least 4 characters"),
    }),
  });

  const errors = useSelector((state) => state.datasetsReducer.errors);

  useEffect(() => {
    if (activeDataset?.name) formik.setFieldValue("datasetName", activeDataset.name, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDataset?.name]);

  const checkError = (field) => {
    let error = "";
    errors.forEach((err) => {
      if (err.loc[1] === field) {
        error = err.msg;
      }
    });

    return error;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Rename Dataset</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ gap: "20px", display: "flex", flexDirection: "column" }}>
          <TextField
            absoluteErrorLabel
            name="datasetName"
            label="Dataset Name*"
            inputProps={{
              placeholder: "Enter dataset name",
            }}
            sx={{
              "label + &": {
                marginTop: "22px !important",
              },
            }}
            labelSx={{
              transform: "none",
            }}
            value={formik.values.datasetName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={checkError("dataset_name") || (formik.touched.datasetName && formik.errors.datasetName)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ p: "6.5px 14px", height: "40px", color: "#344054", borderColor: "#D0D5DD", mr: "4px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={activeDataset.name === formik.values.datasetName || !formik.values.datasetName}
            color="primary"
            type="submit"
            sx={{ p: "8px 14px" }}
          >
            Rename
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
