import { styled } from '@mui/system';
import { Box, FormControlLabel, Checkbox, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TreeWrapper = styled(Box)(
  () => ({
    background: '#fff',
    borderRadius: '12px',
    height: 'auto',
    marginRight: '30px',
    padding: '24px'
  })
)

const TreeItem = styled(Box)(
  ({parrent}) => ({
    paddingLeft: '30px',
    borderLeft: !parrent && '1px solid #8593AD'
  })
)

const TreeLabel = styled(FormControlLabel)(
  ({parrent}) => ({
    marginLeft: '-20px',
    marginBottom: '-12px',
    paddingTop: '10px',
    position: 'relative',
    "&::after": !parrent && {
      content: "''",
      position: 'absolute',
      width: '20px',
      borderTop: '1px dashed #8593AD',
      left: '-10px'
      
    },
    '& .MuiTypography-root': {
      maxWidth:'100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    width: '100%'
  })
)

export const GraphTreeView = ({treeView, setTreeView, handleSelectTreeView}) => {

  const handleOpenTreeView = route => {
    let _treeView = [...treeView];
    const routeArr = route.split('*');
    if(routeArr.length === 1) {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      _treeView[index].open = !_treeView[index].open
    } else if(routeArr.length === 2) {
      const index = _treeView.findIndex(item => item.name === routeArr[0])
      const index2 = _treeView[index].data.findIndex(item => item.name === routeArr[1])
      _treeView[index].data[index2].open = !_treeView[index].data[index2].open
    }
    setTreeView(_treeView)
  }

  return (
    <TreeWrapper>
      {treeView.map((item) => (
        <TreeItemContainer
          key={item.route}
          label={item.name}
          open={item.open}
          parrent={'1'}
          route={item.route}
          handleSelectTreeView={handleSelectTreeView}
          handleOpenTreeView={handleOpenTreeView}
          selected={item.selected}
        >
          {item?.data.map((item2) => (
            <TreeItemContainer
              key={item2.route}
              label={item2.name}
              open={item2.open}
              route={item2.route}
              handleSelectTreeView={handleSelectTreeView}
              handleOpenTreeView={handleOpenTreeView}
              selected={item2.selected}
            >
              {item2.data && item2?.data.map((item3) => {
                return item3.name !== 'all' && (
                  <TreeItemContainer
                    key={item3.route}
                    label={item3.name}
                    route={item3.route}
                    handleSelectTreeView={handleSelectTreeView}
                    handleOpenTreeView={handleOpenTreeView}
                    selected={item3.selected}
                  />
                )
              })}
            </TreeItemContainer>
          ))}
        </TreeItemContainer>
      ))}
    </TreeWrapper>
  );
};

const TreeItemContainer = ({
  label,
  children,
  parrent,
  open,
  route,
  handleSelectTreeView,
  handleOpenTreeView,
  selected
}) => (
  <TreeItem parrent={parrent}>
    <Box sx={{display: 'flex'}}>
      <TreeLabel
        onChange={handleSelectTreeView(route)}
        checked={selected}
        parrent={parrent}
        control={<Checkbox />}
        label={label}
      />
      {children && (
        <ExpandMoreIcon 
          onClick={() => handleOpenTreeView(route)}
          sx={{
            color: '#8593AD',
            cursor: 'pointer',
            transform: open ? 'rotate(.5turn)' : 'rotate(0turn)',
            margin: 'auto',
            marginTop: '20px'
          }}
        />
      )}
      
    </Box>
    <Collapse in={open}>
      {children}
    </Collapse>
  </TreeItem>
)