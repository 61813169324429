import { SvgIcon } from "@mui/material";
import React from "react";

export const DatasetIcon = ({ fill = "#525866", ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px", fill: "none" }}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M6.25 5.5V3.25A.75.75 0 0 1 7 2.5h9a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75h-2.25v2.25c0 .414-.338.75-.755.75h-8.99a.75.75 0 0 1-.755-.75l.002-10.5c0-.414.338-.75.755-.75zM4.752 7l-.002 9h7.5V7zM7.75 5.5h6V13h1.5V4h-7.5zm-1.5 3.75h4.5v1.5h-4.5zm0 3h4.5v1.5h-4.5z"
        fill={fill}
      />
    </SvgIcon>
  );
};
