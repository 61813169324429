import { useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChartItem } from './chartItem';

export const ChartList = () => {
  
  const variables = useSelector(state => state.datasetsReducer.datasetVarTypes)

  return (
    Object.keys(variables).map((variable, index) => (
      <Accordion key={index} defaultExpanded={!index} sx={{width: '100%'}} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>{variable}</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChartItem variable={variables[variable]}/>
        </AccordionDetails>
      </Accordion>
    ))
  )
};