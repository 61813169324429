import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { EditGraphInput } from "../../components/inputs/editGrphInput";
import { GenieIcon } from "../../components/genie-icon";
import { exportGraphs, clearExportFileUrl } from "../../actions/analyses";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const fileTypes = [
  { name: "PDF", icon: "pdf", value: "pdf" },
  { name: "PPT", icon: "ppt", value: "PP" },
  { name: "DOCX", icon: "doc", value: "docx" },
  { name: "XLSX", icon: "exel", value: "xlsx" },
  { name: "JPEG", icon: "jpg", value: "jpeg" },
  { name: "TIFF", icon: "tiff", value: "tiff" },
  { name: "PNG", icon: "png", value: "png" },
  { name: "BMP", icon: "", value: "bmp" },
];

const FileTypeSelection = styled(Grid)(({ selected }) => ({
  width: "77px",
  height: "84px",
  border: selected ? "1px solid #2984FF" : "1px solid rgba(0, 0, 0, 0.1)",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "0 7px 7px 0",
  cursor: "pointer",
}));

export function ExportGraphs({ open, handleClose, treeView }) {
  const [selectedFileType, setSelectedFileType] = useState("pdf");
  const [height, setHeight] = useState("1000");
  const [width, setWidth] = useState("1000");
  const [noOfColumns, setNoOfColumns] = useState("1");
  const [mode, setMode] = useState("all");
  const [resolution, setResolution] = useState("200");
  const [selctedRoutes, setSelectedRoutes] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const exportFileUrl = useSelector((state) => state.analysesReducer.exportFileUrl);

  useEffect(() => {
    let selected = [];
    let selectedValue = [];
    treeView.forEach((item) => {
      item.data.forEach((item2) => {
        if (item2.data?.length) {
          item2.data &&
            item2.data.forEach((item3) => {
              if (item3.selected) {
                selected.push(item3.route.split("*"));
                selectedValue.push(item3.value);
              }
            });
        } else {
          if (item2.selected) {
            selected.push(item2.route.split("*"));
            selectedValue.push(item2.value);
          }
        }
      });
    });
    setSelectedRoutes(selected);
    setSelectedGraphs(selectedValue);
  }, [treeView]);

  useEffect(() => {
    dispatch(clearExportFileUrl());
  }, [height, width, noOfColumns, resolution, mode, selectedFileType, dispatch]);

  const handleExport = () => {
    setOpenLoader(true);
    let primary_names = [];
    let secondary_names = [];
    let split_levels = [];
    selctedRoutes.forEach((route) => {
      if (route[0]) split_levels.push(route[0]);
      // if(route.length === 2) {
      //   if(route[1]) secondary_names.push(route[1]);
      // } else {
      if (route[1]) primary_names.push(route[1]);
      // }

      if (route[2]) secondary_names.push(route[2]);
    });
    const data = {
      analysis_id: +id,
      mode: mode,
      file_type: selectedFileType,
      file_name: "test",
      follow_up_name: "",
      file_configuration: {
        width: parseInt(width),
        height: parseInt(height),
        resolution: resolution,
      },
      primary_names: primary_names,
      secondary_names: secondary_names,
      number_of_columns: parseInt(noOfColumns),
      split_levels: split_levels,
      heatmap: !!primary_names.find((item) => item === "heatmap"),
    };
    dispatch(exportGraphs(data, setOpenLoader));
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = exportFileUrl;
    link.setAttribute("download", `analyse${id}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const closeDialog = () => {
    handleClose();
    dispatch(clearExportFileUrl());
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
      <DialogTitle>Export Graphs</DialogTitle>
      <DialogContent sx={{ overflowY: "visible" }}>
        <Grid container spacing={2}>
          <Grid item xs={5} sx={{ maxHeight: "fit-content" }}>
            <Typography fontSize={"18px"} color="#3B3946" marginBottom={"20px"}>
              Document Setup:
            </Typography>
            <Grid container mb={"10px"}>
              <Grid item xs={4}>
                <Typography fontSize={"12px"} fontWeight={400} color={"#181818"} lineHeight={"26px"}>
                  Mode
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <RadioGroup row value={mode} onChange={(e) => setMode(e.target.value)}>
                  <FormControlLabel value="all" control={<Radio />} label="All" />
                  <FormControlLabel value="single" control={<Radio />} label="Single" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container mb={"10px"}>
              <Grid item xs={4}>
                <Typography fontSize={"12px"} fontWeight={400} color={"#181818"} lineHeight={"26px"}>
                  Height
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <EditGraphInput
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  type="number"
                  notButton={true}
                />
              </Grid>
            </Grid>
            <Grid container mb={"10px"}>
              <Grid item xs={4}>
                <Typography fontSize={"12px"} fontWeight={400} color={"#181818"} lineHeight={"26px"}>
                  Width
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <EditGraphInput
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  type="number"
                  notButton={true}
                />
              </Grid>
            </Grid>
            <Grid container mb={"10px"}>
              <Grid item xs={4}>
                <Typography fontSize={"12px"} fontWeight={400} color={"#181818"} lineHeight={"26px"}>
                  No of Columns
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <EditGraphInput
                  value={noOfColumns}
                  onChange={(e) => setNoOfColumns(e.target.value)}
                  type="number"
                  notButton={true}
                />
              </Grid>
            </Grid>
            <Grid container mb={"10px"}>
              <Grid item xs={4}>
                <Typography fontSize={"12px"} fontWeight={400} color={"#181818"} lineHeight={"26px"}>
                  Resolution
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <EditGraphInput
                  value={resolution}
                  onChange={(e) => setResolution(e.target.value)}
                  type="number"
                  notButton={true}
                />
              </Grid>
            </Grid>
            <Typography fontSize={"18px"} color="#3B3946" marginBottom={"20px"}>
              Export to:
            </Typography>
            <Grid container>
              {fileTypes.map((item) => (
                <FileTypeSelection
                  key={item.value}
                  item
                  selected={item.value === selectedFileType}
                  onClick={() => setSelectedFileType(item.value)}
                >
                  <GenieIcon type={item.icon} iconWidth="20px" />
                  <Typography fontSize={"12px"} fontWeight={300}>
                    {item.name}
                  </Typography>
                </FileTypeSelection>
              ))}
            </Grid>
            <br />
            <Box mb={"-70px"}>
              <Typography fontSize={"18px"} color="#3B3946" marginBottom={"20px"}>
                Share
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <FacebookShareButton url={exportFileUrl} disabled={!exportFileUrl}>
                    <FacebookIcon size={50} borderRadius={"6px"} />
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton url={exportFileUrl} disabled={!exportFileUrl}>
                    <WhatsappIcon size={50} borderRadius={"6px"} />
                  </WhatsappShareButton>
                </Grid>
                <Grid item>
                  <LinkedinShareButton url={exportFileUrl} disabled={!exportFileUrl}>
                    <LinkedinIcon size={50} borderRadius={"6px"} />
                  </LinkedinShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton url={exportFileUrl} disabled={!exportFileUrl}>
                    <TwitterIcon size={50} borderRadius={"6px"} />
                  </TwitterShareButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography fontSize={"18px"} color="#3B3946" marginBottom={"20px"}>
              Preview
            </Typography>
            <Box sx={{ overflowY: "auto", maxHeight: "555px" }}>
              {selectedGraphs.map((graph) => (
                <img style={{ maxWidth: "100%" }} src={graph} alt="graph" />
              ))}
            </Box>
          </Grid>
        </Grid>
        <Backdrop sx={{ color: "#fff", zIndex: 10001, flexDirection: "column" }} open={openLoader}>
          <CircularProgress color="inherit" />
          <Typography>Exporting Graphs</Typography>
        </Backdrop>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={closeDialog}>
          Cancel
        </Button>
        {exportFileUrl ? (
          <Button variant="contained" color="secondary" onClick={downloadFile}>
            Download
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={handleExport}>
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
