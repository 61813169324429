import { Stack, Typography } from "@mui/material";
import React from "react";

export const AnalyseInfoBlock = ({ label, icon }) => {
  return (
    <Stack
      direction="row"
      sx={{
        maxHeight: "40px",
        p: "10px 16px",
        background: "#F9FAFB",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",

        svg: {
          width: "24px !important",
          height: "24px !important",
        },
      }}
    >
      {icon}
      <Typography variant="subtitle2">{label}</Typography>
    </Stack>
  );
};
