import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export const SimpleSuggestionBlock = ({ text, onClick }) => {
  return (
    <Stack
      onClick={onClick}
      direction="row"
      gap="8px"
      sx={{
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.04)",
        border: "1px #EAECF0 solid",
        backgroundColor: "#ffff",
        borderRadius: "8px",
        padding: "14px 12px",
        transition: "all 0.5s",
        cursor: "pointer !important",
        "&:hover": {
          transition: "all 0.5s",
          "& .MuiTypography-root": {
            color: "#6E3FF3",
          },
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          width: "8px",
          height: "8px",
          minWidth: "8px",
          bgcolor: "#6E3FF3",
          mt: "7.5px",
        }}
      />
      <Typography lineHeight="23px" fontSize={14} color="#475467">
        {text}
      </Typography>
    </Stack>
  );
};
