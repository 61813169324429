import {
  Dialog,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const AnalysePopup = ({
  open,
  onClose,
  content,
  maxHeight = "700px",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{
        "& .css-voqszn-MuiPaper-root-MuiDialog-paper": {
          maxHeight,
          height: "100%",
        },
      }}
    >
      <DialogActions sx={{ p: "34px 20px 24px" }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent sx={{ mb: "34px" }}>{content}</DialogContent>
    </Dialog>
  );
};
