import { Box, InputLabel, Stack, styled, Typography } from "@mui/material";

export const HeaderWrapper = styled(Stack)(() => ({
  zIndex: 1,
  margin: "0 auto",
  textAlign: "center",
  alignItems: "center",
  padding: "24px",
  gap: "4px",
}));

export const Title = styled(Typography)(() => ({
  color: "#101828",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "28px",
  marginTop: "12px",
}));

export const Subtitle = styled(Typography)(() => ({
  color: "#475467",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
}));

export const BackgroundIcon = styled(Box)(() => ({
  marginTop: "-15px",
  marginLeft: "55px",
  position: "absolute",
}));

export const Label = styled(InputLabel)(() => ({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  marginBottom: "6px",
}));
