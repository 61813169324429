import React from "react";
import LogoImage from "../../images/short-logo.png";

import { Box, Stack, Typography } from "@mui/material";

export const ShortLogo = () => {
  return (
    <Stack direction="row" alignItems="center" gap="12px">
      <Box
        component="img"
        src={LogoImage}
        sx={{
          width: "36px",
          height: "36px",
        }}
      />

      <Stack>
        <Typography variant="subtitle2" fontSize="12px">
          Genie
        </Typography>
        <Typography variant="subtitle2" fontSize="12px" color="text.secondary">
          Statistics
        </Typography>
      </Stack>
    </Stack>
  );
};
