export const GA4_ACTION_TYPES = {
  BUTTON: "Button",
  LINK: "Link",
};

export const GA4_ACTIONS = {
  CLICK: "click",
};

export const HERO_VIDEO_URL =
  "https://unprocessedfilesfromfront.s3.eu-central-1.amazonaws.com/media_landing_page/genielanding.webm";

export const ABOUT_SCREEN_BLOCK_DATA = [
  {
    title: "Automated Model\n Selection & Conclusion Drafting",
    description: "Tailored results for every dataset, no expertise required.",
    img: [
      "https://unprocessedfilesfromfront.s3.eu-central-1.amazonaws.com/media_landing_page/select_analysis_landing.jpeg",
    ],
  },
  {
    title: "Ready-to-Use Tables",
    description:
      "Instantly generated tables with automatically drafted conclusions ready to drop into your report.",
    img: [
      "https://unprocessedfilesfromfront.s3.eu-central-1.amazonaws.com/media_landing_page/table_landing.png",
    ],
  },
  {
    title: "Custom Graphs \nfor Every Analysis",
    description: "Tailored visuals for each\n statistical model and test, instantly generated.",
    img: [
      "https://unprocessedfilesfromfront.s3.eu-central-1.amazonaws.com/media_landing_page/graph_boxplott.png",
      "https://unprocessedfilesfromfront.s3.eu-central-1.amazonaws.com/media_landing_page/graph_columns.png",
      "https://unprocessedfilesfromfront.s3.eu-central-1.amazonaws.com/media_landing_page/graph_forestplott.png",
    ],
  },
];

export const ABOUT_US_BLOCK_DATA = {
  title: "Welcome to Genie, your intelligent companion for transforming your data into insights",
  captureText: "About Us",
  subtitle: "Mission Statement:",
  description: [
    "Our mission is to empower non-professional users in the academic, health, and pharma markets to effortlessly perform complex analyses and achieve precise results in no time.",
    "With Genie, there's no need for a deep understanding of statistics; simply share your wishes and ideas and let Genie work its magic.",
    "Our innovative platform uses exclusive algorithms to wisely select and execute multiple analyses of different types in one run, ensuring you receive the most accurate results in the fastest and most comprehensible way. This includes drafted conclusions, tables, graphs, and even initial drafts of the results and methods sections for your article or report.",
  ],
};

export const CEO_BLOCK_DATA = {
  title: "Dr. Sharon Daniel(MD, MPH, Ph.D)",
  captureText: "CEO & Founder",
  description: [
    "Pediatrician, Biostatistician, and Epidemiologist: Expert in integrating clinical practice with advanced statistical analysis.",
    "Postdoc at Columbia University: Specialized in cutting-edge biostatistical methods for medical research.",
    "Experienced Educator: Lecturer in Biostatistics and Epidemiology, with nearly 17 years of academic-teaching experience and founder of Israel’s first academic advanced R programming course for biostatisticians.",
    "Data Analysis Expert: Directed numerous academic and private projects in healthcare, pharma, and research.",
    "Innovative Leader: Creator of Genie, an AI-powered platform making statistical analyses simple and accessible for everyone.",
  ],
};
