import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PageTitle } from "../../components/pageTitle";
import { OwerviewStats } from "./OwerviewStats";
import { AnalysesTable } from "./analysesTable";

import { fetchAnalyses } from "../../actions/analyses";
import { Box } from "@mui/material";

export default function Analyses() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnalyses());
  }, [dispatch]);

  return (
    <Box>
      <PageTitle title="Analysis" />
      <OwerviewStats only={"analyses"} />
      <AnalysesTable />
    </Box>
  );
}
