import { useContext } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import { registerUser, clearErrors } from "../../actions/user";

import { GOOGLE_ID, DEFAULT_USER_DATA, getRegistrationFields } from "./register.constants";

import { ErrorText, GreenSpan, StyledDialog, StyledDialogContent, TextInput } from "./styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { HeaderBlock } from "../login/components/HeaderBlock";
import { Label } from "../login/styles";
import { gaClickCallback } from "helpers/common";
import { GA4_ACTION_TYPES } from "constants/common.constants";
import { PopupContext } from "App";
import { POPUPS } from "hooks/usePopups";
import { Checkbox, FormControlLabel } from "@mui/material";
import { CheckboxIcon } from "Icons/CheckboxIcon";
import { CheckedCheckboxIcon } from "Icons/CheckedCheckboxIcon";

export default function RegisterPopUp({
  open,
  handleClose,
  setOpenLogin,
  googleResponse,
  googleErrorResponse,
}) {
  const dispatch = useDispatch();
  const { setOpenPopup } = useContext(PopupContext);

  const onClose = () => {
    formik.resetForm();
    dispatch(clearErrors());
    handleClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: DEFAULT_USER_DATA,
    onSubmit: (data) => {
      const { is_confirmed, ...rest } = data || {};
      dispatch(registerUser(rest, () => onClose()));
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Required field"),
      last_name: Yup.string().required("Required field"),
      email: Yup.string().email("Email is not valid").required("Required field"),
      is_confirmed: Yup.bool().required("Required field"),
      password: Yup.string()
        .required("Required field")
        .min(3, "Password must be at least 8 characters")
        .max(255, "Password cannot be longer than 255 characters"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required field"),
    }),
  });

  const errors = useSelector((state) => state.userReducer.errors);

  const handleOpenLogIn = (event) => {
    event.stopPropagation();
    handleClose();
    setOpenLogin(true);
  };

  const checkError = (field) => {
    let error = "";
    if (Array.isArray(errors)) {
      errors.forEach((err) => {
        if (err.loc[1] === field) {
          error = err.msg;
        }
      });
    }

    return error;
  };

  const onTermsOpen = () => setOpenPopup(POPUPS.TERMS_OF_USE);

  const fields = getRegistrationFields();

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <HeaderBlock
        title="Create an account"
        subTitle="Welcome to Genie - Let’s create yor account"
        onClose={onClose}
      />
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogContent sx={{ gap: "56px" }}>
          <Grid container gap="16px" width="100%">
            {fields.map(({ name, label, type }, index) => (
              <Box sx={{ width: "100%" }} key={index}>
                <Label>{label}*</Label>
                <TextInput
                  fullWidth
                  type={type}
                  name={name}
                  placeholder={label}
                  value={formik.values[name]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors[name] && !!formik.touched?.[name] && !!formik.errors[name]}
                />
                <ErrorText>
                  {!!formik.errors[name] && !!formik.touched?.[name]
                    ? formik.errors[name]
                    : checkError("name")}
                </ErrorText>
              </Box>
            ))}
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    checked={formik.values["is_confirmed"]}
                    onChange={formik.handleChange}
                    name="is_confirmed"
                  />
                }
                label=" I have read the terms of use"
              />
            </Grid>
          </Grid>

          <Grid container gap="12px" alignItems="center" flexDirection="column">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formik.values["is_confirmed"]}
              onClick={() => gaClickCallback({ action: "Sign Up (registration)" })}
            >
              Sign Up
            </Button>

            <GoogleOAuthProvider clientId={GOOGLE_ID}>
              <GoogleLogin
                size="large"
                buttonText="Login"
                onSuccess={googleResponse}
                onError={googleErrorResponse}
                width="392"
              />
            </GoogleOAuthProvider>

            <Typography fontSize="14px" mt="12px">
              Don’t have an account?
              <GreenSpan
                onClick={(e) =>
                  gaClickCallback({
                    type: GA4_ACTION_TYPES.LINK,
                    label: "Log in",
                    callback: () => handleOpenLogIn(e),
                  })
                }
              >
                Log in
              </GreenSpan>
            </Typography>
            <Typography fontSize="14px" lineHeight="20px">
              <GreenSpan onClick={onTermsOpen}>Terms of use</GreenSpan>
            </Typography>
          </Grid>
        </StyledDialogContent>
      </form>
    </StyledDialog>
  );
}
