import { Typography, Box } from "@mui/material";

export const TEXT = [
  {
    title: "Last Updated: November 27th 2024",
    text: 'Welcome to Genie, the statistical analysis platform provided by Daniel Statistical Solutions Ltd. ("Company," "we," "our," or "us"). By accessing or using our platform, you ("User," "you") agree to these Terms of Use. If you do not agree with any part of these terms, you must not use the platform.',
  },
  {
    title: "1. Voluntary Use of the Platform",
    text: "By using Genie, you confirm that you are accessing and using the platform of your own free will. You acknowledge that the results provided are for informational purposes only and that you remain solely responsible for the decisions and actions you take based on those results.",
  },
  {
    title: "2. Data Usage and Responsibilities",
    text:
      "You agree that any data you upload to Genie:\n" +
      "a. Does not contain identifiable personal information as defined by applicable privacy laws. You are responsible for anonymizing or de-identifying your data before uploading it to our platform.\n" +
      "b. Is under your ownership or you have the necessary rights and permissions to upload and use the data for analysis on Genie.\n\n" +
      "We do not store or guarantee the security of the data you upload or the analysis results provided. You are responsible for maintaining backups and ensuring the confidentiality of your data before and after using the platform.",
  },
  {
    title: "3. Disclaimer of Warranties and Limitation of Liability",
    text:
      'a. Genie and its associated services are provided on an "as-is" and "as-available" basis. We make no guarantees as to the accuracy, reliability, or completeness of the analysis results generated by Genie.\n' +
      "b. We are not liable for any errors, inaccuracies, or omissions in the results or for any decisions, actions, or outcomes resulting from the use of Genie.\n" +
      "c. You agree that the platform may be subject to interruptions, bugs, or errors, and the Company does not warrant uninterrupted service or the absence of defects.",
  },
  {
    title: "4. Indemnification and Waiver of Claims",
    text:
      "a. You agree to indemnify and hold harmless Daniel Statistical Solutions Ltd. and its employees, agents, and affiliates from and against any claims, liabilities, damages, or expenses (including legal fees) arising from your use of the platform.\n" +
      "b. By using Genie, you irrevocably waive any right to sue the Company for damages, losses, or other claims related to the analysis results, data security, or any associated outcomes.",
  },
  {
    title: "5. Prohibited Conduct",
    text:
      "You agree not to use Genie for:\n" +
      "a. Uploading or processing data that violates applicable laws or regulations.\n" +
      "b. Misusing the platform to create, share, or distribute false, misleading, or harmful information.\n" +
      "c. Any purpose that violates these Terms of Use or the rights of others.",
  },
  {
    title: "6. Modifications to the Platform and Terms",
    text: "We reserve the right to modify or discontinue any part of the platform at our discretion without notice. We may also update these Terms of Use periodically, and your continued use of the platform constitutes acceptance of the updated terms.",
  },
  {
    title: "7. Governing Law and Jurisdiction",
    text: "These Terms of Use shall be governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising from these terms or the use of Genie shall be subject to the exclusive jurisdiction of the courts of [Your Location].",
  },
  {
    title: "8. Contact Us",
    text:
      "For questions about these Terms of Use, please contact us at:\n" +
      "Daniel Statistical Solutions Ltd.\n" +
      "Email: drsharondaniel@geniestat.com\n" +
      "Address: Kefar Maimon, POB 412, Israel",
  },
];

export const TextBlock = () => {
  return (
    <Box
      sx={{
        h5: {
          fontSize: "16px",
          color: "#101828",
          mb: "12px",
        },
        h4: {
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "24px",
          mb: "12px",
        },
      }}
    >
      {TEXT.map((item, index) => (
        <Box key={index}>
          <Typography variant="h5" fontWeight={600}>
            {item.title}
          </Typography>
          <Typography variant="h4" whiteSpace="pre-line">
            {item.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
