import { useState, useCallback } from "react";
import { useDrop } from "react-dnd";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
} from "@mui/material";
import { VaribleList } from "./variblesList";
import { AnalysesColumn } from "./analysesColumn";

export function MakeAnalyses({
  open,
  handleClose,
  selectedAnalyse,
  handleCreate,
}) {
  const [selected, setSelected] = useState([]);

  const handleDrop = useCallback((item) => {
    item.deleteVarible(item.varible);
  }, []);

  const [, drop] = useDrop({
    accept: "varible",
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Make Analyses</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexWrap: "nowrap" }} ref={drop}>
          <VaribleList selected={selected} setSelected={setSelected} />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              maxWidth: "775px",
              flexWrap: "wrap",
            }}
          >
            {selectedAnalyse?.varibleColumns?.map((column) => (
              <AnalysesColumn
                key={column.name}
                column={column}
                setSelected={setSelected}
                selected={selected}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
