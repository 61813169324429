import React from "react";
import { useDispatch } from "react-redux";

import { AddDatasetBtn, EmptyScreenWrapper } from "./styles";

import { PlusIcon } from "../../../../../../Icons/PlusIcon";
import { UploadDatasetIcon } from "../../../../../../Icons/UploadDatasetIcon";

import { Typography } from "@mui/material";
import { openCreateDialog } from "../../../../../../actions/datasets";
import { gaClickCallback } from "helpers/common";

export const EmptyUserScreen = () => {
  const dispatch = useDispatch();

  return (
    <EmptyScreenWrapper>
      <UploadDatasetIcon />
      <Typography variant="h1" lineHeight="60px">
        Welcome to Genie! <br />
        To begin, upload a new dataset.
      </Typography>
      <AddDatasetBtn
        startIcon={<PlusIcon />}
        variant="contained"
        onClick={() => {
          gaClickCallback({
            label: "Add New Dataset on empty user screen",
            callback: () => dispatch(openCreateDialog(true)),
          });
        }}
      >
        Add New Dataset
      </AddDatasetBtn>
    </EmptyScreenWrapper>
  );
};
