import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";

const ExpresionContainer = styled(Box)(() => ({
  border: "1px solid #EAEDF2",
  borderRadius: "12px",
  padding: "16px",
  height: "105px",
  marginBottom: "16px",
}));

export function ShowExspresion({ expression }) {
  const renderExpretionValue = (item) => {
    if (item.type === "function") {
      return <Typography>{`${item.value}(${item.parameters.map((parameter) => parameter)})`}</Typography>;
    } else {
      return <Typography>{`${item.value}`}</Typography>;
    }
  };

  return (
    <ExpresionContainer>
      {expression.length ? (
        <Grid container wrap="wrap" spacing={1}>
          {expression.map((item, index) => (
            <Grid item key={index}>
              {renderExpretionValue(item)}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography color={"#8593AD"} fontSize={"12px"}>
          expression
        </Typography>
      )}
    </ExpresionContainer>
  );
}
