import { Stack, Tab, Tabs } from "@mui/material";
import React, { Fragment } from "react";
import { ITEM_TABS } from "..";
import { normalizeSnakeCaseString } from "helpers/common";
import { StatisticSecondIcon } from "Icons/StatisticSecondIcon";
import { AnalyseInfoBlock } from "./AnalyseInfoBlock";
import { ResidentsIcon } from "Icons/ResidentsIcon";
import { DimentionIcon } from "Icons/DimentionIcon";

export const HeaderBlock = ({ value, handleChange, currentAnalyse }) => {
  const ANALYSE_INFO = [
    {
      label: normalizeSnakeCaseString(currentAnalyse?.summary?.report?.analysis_type),
      icon: (
        <StatisticSecondIcon
          stroke="#475467"
          style={{
            transform: "scale(1.6)",
          }}
        />
      ),
    },
    {
      label: currentAnalyse?.summary?.report?.dimention,
      icon: <DimentionIcon />,
    },
    {
      label: normalizeSnakeCaseString(currentAnalyse?.summary?.report?.subset),
      icon: <ResidentsIcon />,
    },
  ];

  return (
    <Stack gap="20px" justifyContent="space-between" direction="row" alignItems="center">
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          width: "fit-content",
          borderRadius: "8px",
          minHeight: "40px",
          border: "1px #D0D5DD solid",
          "& .MuiTab-root.Mui-selected": {
            backgroundColor: "#7F56D9",
            color: "#fff",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& button": {
            minHeight: "40px",
            minWidth: "70px",
            fontSize: "14px",
            fontWeight: "600",
          },
        }}
      >
        {ITEM_TABS.map((item, index) => (
          <Tab value={item} label={item} key={index} />
        ))}
      </Tabs>

      <Stack gap="12px" direction="row" alignItems="center">
        {ANALYSE_INFO.map((item, index) => (
          <Fragment key={index}>{!!item.label && <AnalyseInfoBlock key={index} {...item} />}</Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
