import { SvgIcon } from "@mui/material";
import React from "react";

export const HomeIcon = ({ stroke = "#D92D20", ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px" }}
      viewBox="0 0 0.6 0.6"
      className="icon glyph"
      {...props}
    >
      <path
        d="M.283.056a.025.025 0 0 1 .033 0l.225.2a.025.025 0 0 1-.033.037L.5.286v.189a.05.05 0 0 1-.05.05h-.3A.05.05 0 0 1 .1.475V.286L.092.293A.025.025 0 0 1 .059.256zM.15.242v.233h.075V.35A.025.025 0 0 1 .25.325h.1A.025.025 0 0 1 .375.35v.125H.45V.242L.3.109zm.175.233v-.1h-.05v.1z"
        fill="#475467"
      />
    </SvgIcon>
  );
};
