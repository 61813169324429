import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const MessageItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "identity",
})(({ identity, column }) => ({
  display: "flex",
  maxWidth: "fit-content",
  minWidth: "20%",
  flexDirection: column ? "column" : identity ? "row-reverse" : "row",
  justifyContent: "flex-start",
  alignItems: column ? "flex-start" : "center",
  padding: identity ? "20px 0px 20px 20px" : "20px 20px 20px 0px",
  marginLeft: identity && "auto",
  gap: "20px",
}));

export const AnalyseMessageItem = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  boxSizing: "border-box",
  backgroundColor: "#ffff",
  flexDirection: "column",
  padding: "32px 20px",
  borderRadius: "20px",
  border: "1px #EAECF0 solid",
  gap: "20px",
}));

export const MessageText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "error_text" && prop !== "isUser",
})(({ error_text, isUser }) => ({
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "24px",
  whiteSpace: "pre-line",
  padding: "12px 24px",
  color: error_text ? "#FF4343" : "#1E1D23",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.05)",
  borderRadius: isUser ? "12px 12px 0 12px" : "12px 12px 12px 0",

  a: {
    color: error_text ? "#FF4343" : "#1E1D23",
  },
}));

export const MessagesWrapper = styled(Box)(() => ({
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  padding: "0 40px",
}));
