import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Sidebar } from './sidebar';
import '../index.css';

const DashboardLayoutRoot = styled('div')(
  () => ({
    backgroundColor: 'yellow',
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutContentWrapper = styled('div')(
  () => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingLeft: 200,
    marginLeft: '-190px',
    background: '#F8FAFF'
  })
);

const DashboardLayoutContent = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '34px',
  maxHeight: 'calc(100vh - 68px)'
});

export const Layout = () => (
  <DashboardLayoutRoot>
    <Sidebar />
    <DashboardLayoutContentWrapper>
      <DashboardLayoutContent>
        <Outlet />
      </DashboardLayoutContent>
    </DashboardLayoutContentWrapper>
  </DashboardLayoutRoot>
);