import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { TextField } from "../inputs/textInput";
import { UploadFile } from "../inputs/uploadFile";

import { clearErrors, fetchDatasetNames, fetchDatasets, openCreateDialog } from "../../actions/datasets";
import { createDatasetId } from "../../services/datasets";
import { useSetFileTrack } from "../../hooks/useSetFileTrack";
import { setLoading } from "../../actions/user";
import { MAX_IMAGE_SIZE, SOCKET_STATUS } from "./constants";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { handleLargeFileUpload, uploadDatasetFile } from "../../services/datasets-s3";
import { Typography } from "@mui/material";
import { StarsIcon } from "../../Icons/StarsIcon";
import { addChat } from "actions/chats";
import { gaClickCallback } from "helpers/common";

const DEFAULT_VALUES = {
  datasetName: "",
  datasetFile: "",
  description: "",
};

export function UploadDataset({ open, handleClose }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [datasetData, setDatasetData] = useState(DEFAULT_VALUES);
  const [datasetId, setDatasetId] = useState(null);

  const { loading, response } = useSetFileTrack({
    dataset_id: datasetId,
    token: localStorage.getItem("session_token"),
  });

  const isChatRout = pathname === "/genie-gpt";

  const selectedProject = useSelector((state) => state.projectsReducer.selected);
  const { id } = useSelector((state) => state.userReducer.data);
  const errors = useSelector((state) => state.datasetsReducer.errors);

  const handleChangeName = (event) => {
    const { value, name } = event.target;
    setDatasetData({ ...datasetData, [name]: value });
  };

  const handleChangeFile = (event) => {
    const { files } = event.target;
    setDatasetData({ ...datasetData, datasetFile: files[0] });
  };

  const onClose = () => {
    setDatasetData(DEFAULT_VALUES);
    dispatch(clearErrors());
    handleClose();
  };

  useEffect(() => {
    if (loading) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (response) {
      switch (response.event) {
        case SOCKET_STATUS.READY:
          dispatch(openCreateDialog(false));
          setDatasetId(null);
          setDatasetData(DEFAULT_VALUES);
          dispatch(addChat({ dataset: { id: datasetId, name: datasetData.datasetName } }));
          if (isChatRout) {
            dispatch(fetchDatasetNames());
          } else {
            dispatch(fetchDatasets());
          }
          break;
        case SOCKET_STATUS.ERROR:
          toast.error("Failed to upload Dataset. Please, try again");
          setDatasetId(null);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isChatRout, response]);

  const validationSchema = Yup.object().shape({
    datasetName: Yup.string()
      .required("Required field")
      .min(4, "Ensure this value has at least 4 characters"),
    datasetFile: Yup.mixed()
      .required("Required field")
      .test("fileName", 'The name of the file cannot include "."', (value) => {
        if (!value) return true;
        const fileName = value.name.replace(/\.[^/.]+$/, "");
        return fileName.indexOf(".") === -1;
      }),
  });

  const handleSubmit = () => {
    const filters = {
      dataset_name: datasetData.datasetName,
      ...(selectedProject.id ? { project_id: selectedProject.id } : { from_statgpt: true }),
      comment: datasetData.description,
    };
    dispatch(setLoading(true));
    createDatasetId(filters).then(({ data }) => {
      setDatasetId(data.dataset_id);
      const fileSizeKiloBytes = datasetData.datasetFile.size / 1024;
      if (fileSizeKiloBytes >= MAX_IMAGE_SIZE) {
        handleLargeFileUpload(
          datasetData.datasetFile,
          data.dataset_id,
          selectedProject.id || data.project_id,
          id
        );
      } else {
        uploadDatasetFile(
          datasetData.datasetFile,
          data.dataset_id,
          selectedProject.id || data.project_id,
          id
        );
      }
    });
    dispatch(clearErrors());
  };

  const checkError = (field) => {
    let error = "";
    errors.forEach((err) => {
      if (err.loc[1] === field) {
        error = err.msg;
      }
    });

    return error;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Dataset</DialogTitle>
      <Formik initialValues={datasetData} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ setFieldValue, setFieldTouched }) => (
          <Form>
            <DialogContent sx={{ gap: "20px", display: "flex", flexDirection: "column" }}>
              <Field name="datasetName">
                {({ meta }) => (
                  <TextField
                    absoluteErrorLabel
                    name="datasetName"
                    label="Dataset Name*"
                    inputProps={{
                      placeholder: "Enter dataset name",
                    }}
                    sx={{
                      "label + &": {
                        marginTop: "22px !important",
                      },
                    }}
                    labelSx={{
                      transform: "none",
                    }}
                    onBlur={() => setFieldTouched("datasetName", true)}
                    error={checkError("dataset_name") || (!!meta.error && !!meta.touched && meta.error)}
                    value={datasetData.datasetName}
                    onChange={(event) => {
                      handleChangeName(event);
                      setFieldValue("datasetName", event.target.value);
                    }}
                  />
                )}
              </Field>
              <Field name="datasetFile">
                {({ meta }) => (
                  <UploadFile
                    absoluteErrorLabel
                    labelElement={
                      <Typography fontSize="16px" textAlign="center" lineHeight="24px" color="#101828">
                        <span style={{ color: "#6941C6", fontWeight: "600" }}>Click to upload </span>or drag
                        and drop file here <br />( csv, xlsx, etc )
                      </Typography>
                    }
                    accept=".csv, .xls, .xlsx, .dta, .sas, .sav, .sas7bdat"
                    onBlur={() => setFieldTouched("datasetFile", true)}
                    value={datasetData.datasetFile}
                    onChange={(event) => {
                      handleChangeFile(event);
                      setFieldValue("datasetFile", event.target.files[0]);
                    }}
                    error={!!meta.error && !!meta.touched ? meta.error : ""}
                  />
                )}
              </Field>

              <TextField
                absoluteErrorLabel
                multiline
                labelIcon={<StarsIcon />}
                rows={5.5}
                name="description"
                label="Description (Recommended)"
                inputProps={{
                  placeholder:
                    "Describe, in your own words, the primary objective or hypothesis of your analysis",
                }}
                sx={{
                  "label + &": {
                    marginTop: "20px !important",
                  },
                }}
                labelSx={{
                  transform: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
                onBlur={() => setFieldTouched("description", true)}
                value={datasetData.description}
                onChange={(event) => {
                  handleChangeName(event);
                  setFieldValue("description", event.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={onClose}
                sx={{ p: "6.5px 14px", height: "40px", color: "#344054", borderColor: "#D0D5DD", mr: "4px" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() =>
                  gaClickCallback({
                    label: "Create Dataset on upload dataset popup",
                  })
                }
                sx={{ p: "8px 14px" }}
              >
                Create Dataset
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
