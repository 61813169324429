import { SvgIcon } from "@mui/material";
import React from "react";

export const CheckedCheckboxIcon = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
      <path d="M18.75 2.25H5.25a3.003 3.003 0 0 0 -3 3v13.5a3.003 3.003 0 0 0 3 3h13.5a3.003 3.003 0 0 0 3 -3V5.25a3.003 3.003 0 0 0 -3 -3m-1.676 6.482 -6.3 7.5a0.75 0.75 0 0 1 -0.563 0.268h-0.013a0.75 0.75 0 0 1 -0.557 -0.248l-2.7 -3a0.75 0.75 0 1 1 1.115 -1.003l2.123 2.359 5.746 -6.84a0.75 0.75 0 0 1 1.148 0.965" />
    </SvgIcon>
  );
};
