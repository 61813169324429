export const rowOptions = [
  { name: "Automatic", value: "1920" },
  { name: "Use first column", value: "1440" },
  { name: "Use numbers", value: "1520" },
];

export const varTypeOptions = [
  { name: "Numeric", value: "numeric" },
  { name: "Factor", value: "factor" },
  { name: "Ordinal", value: "ordinal" },
  { name: "DateTime", value: "datetime" },
  { name: "String", value: "string" },
];

export const separatorOptions = [
  { name: "comma", value: "," },
  { name: "semicolon", value: ";" },
  { name: "colon", value: ":" },
  { name: "star", value: "*" },
  { name: "dollar", value: "$" },
];

export const SOCKET_STATUS = {
  READY: "dataset_ready",
  ERROR: "dataset_failed",
};

export const MAX_IMAGE_SIZE = 5120;
