import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';

import { handleChangeVarTypes } from '../../actions/datasets';

import { varTypeOptions } from './constants';

export function DatasetPreviewTable() {
  const dispatch = useDispatch();
  const dataSetFile = useSelector(state => state.datasetsReducer.dataSetFile);
  const datasetVarTypes = useSelector(state => state.datasetsReducer.datasetVarTypes);

  const handleChange = header => event => {
    let varTypes = [...datasetVarTypes];
    varTypes = varTypes.map(varType => {
      if(varType.variable_name === header) return {...varType, variable_type: event.target.value};
      return varType;
    });
    dispatch(handleChangeVarTypes(varTypes));
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataSetFile.headers.map(header => (
                <TableCell key={header}>
                  <b>{header}</b>
                  <FormControl sx={{width: '100%', minWidth: '130px'}} size="small">
                    <Select
                      
                      value={datasetVarTypes?.find(varType => varType.variable_name === header).variable_type}
                      onChange={handleChange(header)}
                      color="secondary"
                    >
                      {varTypeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSetFile.json_data.map((row, index) => (
              <TableRow key={index}>
                {dataSetFile.headers.map(header => (
                  <TableCell key={header} align="left">{row[header]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}