export const GenieIcon = (props) => (
  <div
    className={props.class}
    onClick={props.callback}
    style={{
      width: props.iconWidth != null ? props.iconWidth : '20px',
      height: props.iconHeight != null ? props.iconHeight : '20px',
      background: 'url(/static/icons/' + props.type + (props.file_type || '.svg') +') center center / contain no-repeat'
    }}
  />
);