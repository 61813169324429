import {
  SET_USER,
  SET_CHECK_USER,
  SET_ERRORS,
  SET_LOADING,
  SET_SHOW_ERRORS
} from '../actions/user';

const initialState = {
  data: {
    id: null,
    email: '',
    first_name: '',
    last_name: '',
    middle_name: ''
  },
  userChecked: false,
  errors:[],
  showErrors: false,
  loading: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, data: action.user, };
    
    case SET_CHECK_USER:
      return { ...state, userChecked: action.userChecked };
    
    case SET_ERRORS:
      return { ...state, errors: action.errors };

    case SET_SHOW_ERRORS:
      return { ...state, showErrors: action.showErrors };

    case SET_LOADING:
      return { ...state, loading: action.loading };

    default:
      return state;
  }
};

export default userReducer;
