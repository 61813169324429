import { styled } from "@mui/system";

import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar } from "@mui/material";

export const UserDialogTitle = styled(DialogTitle)(() => ({
  borderBottom: "1px solid #474551",
  fontSize: "20px",
  padding: "24px",
}));

export const CloseIconButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: "#474551",
}));

export const UserAvatar = styled(Avatar)(() => ({
  width: "82px",
  height: "82px",
  fontSize: "30px",
  backgroundColor: "#383838",
  border: "2.5px solid #ffff",
  boxShadow: "0px 10px 40px rgba(52, 64, 84, 0.20)",
}));

export const classes = {
  header: {
    boxSizing: "border-box",
    margin: "4px",
    width: "calc(100% - 8px)",
    padding: "56px 20px 0",
    height: "100px",
    backgroundColor: "rgba(228, 228, 228)",
    borderRadius: "10px 10px 0 0",
    marginBottom: "130px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    border: "1px #d6d9df solid",
  },

  userBlock: {
    maxWidth: "100%",
    flexDirection: "column",
    ml: "4px",

    "& h5": {
      fontSize: "16px",
      color: "#525866",
      fontWeight: "400",
    },

    "& h6": {
      color: "#101828",
      fontSize: "21px",
      fontWeight: "600",
    },
  },

  fieldLabel: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "normal",
    width: "32%",
    color: "#101828",
  },

  nameFieldContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "14px",
    padding: "24px 0 6px",
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(228, 228, 228)",
    },
  },

  nameFieldWrapper: {
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "flex-end",
    gap: "14px",
  },
};
