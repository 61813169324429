import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { SimpleMenu } from "../simpleMenu/";

import { handleUserName } from "helpers/getUserName";

import { ArrowDown } from "Icons/ArrowDown";
import { UserName, UserAvatar, IconWrapper, ContentContainer, CustomMenuItem } from "./styles";

import Grid from "@mui/material/Grid";
import { HomeIcon } from "Icons/HomeIcon";
import { LogOutIcon } from "Icons/LogOutIcon";
import { SettingIcon } from "Icons/SettingIcon";
import { resetChats } from "actions/chats";
import { useDispatch } from "react-redux";
import { gaClickCallback } from "helpers/common";

export const UserInfoBar = ({ url, first_name, last_name, setShowSettings }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.setItem("session_token", "");
    dispatch(resetChats());
    navigate("/");
  };

  const goHome = () => {
    navigate("/dashboard");
  };

  const openSettings = () => {
    setShowSettings(true);
  };

  const MENU_ITEMS = [
    {
      title: "Home",
      icon: <HomeIcon />,
      callback: goHome,
      id: 1,
    },
    {
      title: "Settings",
      icon: <SettingIcon />,
      callback: () =>
        gaClickCallback({
          label: "user settings",
          callback: () => openSettings(),
        }),
      id: 2,
    },
    {
      title: "Log out",
      icon: <LogOutIcon />,
      callback: logOut,
      id: 3,
    },
  ];

  return (
    <Grid sx={{ display: "flex" }} alignItems="center" gap="4px">
      {url ? (
        <UserAvatar alt="User avatar" src={url} />
      ) : (
        <UserAvatar alt="User avatar">{handleUserName("user", first_name, last_name)}</UserAvatar>
      )}
      <ContentContainer>
        <UserName>{`${first_name} ${last_name}`}</UserName>
      </ContentContainer>
      <IconWrapper
        onClick={handleClick}
        sx={{ transform: open ? "rotate(180deg)" : "rotate(0deg)", marginTop: open ? "-3px" : "6px" }}
      >
        <ArrowDown />
      </IconWrapper>

      <SimpleMenu handleClose={handleClose} open={open} anchorEl={anchorEl}>
        {MENU_ITEMS.map(({ title, callback, icon, id }) => (
          <CustomMenuItem onClick={callback} key={id}>
            {icon} {title}
          </CustomMenuItem>
        ))}
      </SimpleMenu>
    </Grid>
  );
};
