import { Fragment } from 'react';
import { Box, Button, Grid, FormControlLabel, Checkbox } from '@mui/material';
import {styled} from '@mui/material';
import { useEffect } from 'react';
import { TextField } from '../../components/inputs/textInput';


const Sheilf = styled('div')(
  () => ({
    width: '100px',
    border: '1px solid #1CB4AE',
    borderRadius: '6px',
    background: '#ECEBEF',
    textAlign: 'center',
    padding: '10px 20px 10px 20px',
    display: 'inline-block'
  })
);

export default function Dog({dogs, golds, setDogs}) {

  useEffect(() => {
    let edited = false
    dogs.forEach(dog => {
      dog.shelfs.forEach(item => {
        item.forEach(item2 => {
          if(item2) edited = true
        })
      })
    })
    if(edited) {
      return
    }
    let shelfs = [];
    let _dogs = [...dogs];
    golds.forEach(() => {
      shelfs.push([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
    });

    _dogs.map(dog => {
      dog.shelfs = shelfs;
    })
    setDogs(_dogs);
  }, [])

  const editDog = (dogIndex ,index, index2) => (event) => {
    let _dogs = [...dogs];
    _dogs[dogIndex].shelfs[index][index2] = event.target.checked;
    setDogs(_dogs);
  }

  const editDogName = dogIndex => event => {
    let _dogs = [...dogs];
    _dogs[dogIndex].name = event.target.value;
    setDogs(_dogs);
  }

  const addNewDog = () => {
    let _dogs = [...dogs];
    let shelfs = [];
    golds.forEach(() => {
      shelfs.push([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
    });
    _dogs.push({name: '', shelfs: shelfs});
    setDogs(_dogs);
  }

  return (
    <Fragment>
      {dogs.map((dog, dogIndex) => (
        <Box key={`dog${dogIndex}`} mb={'30px'}>
          <TextField
            label="Dog name"
            value={dog.name}
            onChange={editDogName(dogIndex)}
          />
          {dog.shelfs.map((item, index) => (
            <Grid key={`shelfs${index}`} container marginBottom={'10px'}>
              <Grid item>
                <Sheilf>
                  {'shelf ' + (index+1)}
                </Sheilf>
                {item?.map((shelf, index2) => (
                  <FormControlLabel
                    key={`shelf${index2}`}
                    value="top"
                    control={<Checkbox checked={shelf}/>}
                    onChange={editDog(dogIndex, index, index2)}
                    label={index2+1}
                    labelPlacement="top"
                    sx={{marginLeft: 0, marginRight: 0}}
                  />
                ))}
              </Grid>
            </Grid>
          ))}
        </Box>
      ))}
      <Button variant="contained" color='secondary' onClick={addNewDog}>add dog +</Button>
    </Fragment>
  )  
    
  
}