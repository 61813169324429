import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchAnalyses } from "../../actions/analyses";
import { setSelectedVaribles } from "../../actions/datasets";

import { ExportTables } from "./exportTables";
import { AnalysesList } from "./analysesList";
import { CreateNewVariable } from "./createNewVariable";
import { AnalysesDialogs } from "./analysesDialog/index";

import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const DatasetTab = styled(Box)(() => ({
  padding: "17px 28px",
  background: "#E1E3EA",
  borderRadius: "12px",
  fontSize: "16px",
  color: "#4E4B5F",
  cursor: "pointer",
  fontFamily: "Pront, sans-serif",
}));

export const DatasetTabs = ({ tabs }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Data");

  useEffect(() => {
    dispatch(setSelectedVaribles([]));
    dispatch(fetchAnalyses());
  });

  return (
    <Grid flexDirection="column" flex="1 1 auto" maxHeight="100%">
      <Grid
        item
        flex="0 1 auto"
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box sx={{ margin: "20px 0 20px 0" }}>
          <Grid container spacing={2}>
            {tabs.map((tab) => (
              <Grid key={tab.name} item>
                <DatasetTab
                  sx={
                    activeTab === tab.name && {
                      background: "#2984FF",
                      color: "#F8FAFF",
                    }
                  }
                  onClick={() => setActiveTab(tab.name)}
                >
                  {tab.name}
                </DatasetTab>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item>
              <CreateNewVariable />
            </Grid>
            <Grid item>
              <AnalysesList />
            </Grid>
            <Grid item>
              <AnalysesDialogs />
            </Grid>
            {(activeTab === "Frequency" || activeTab === "Summary") && (
              <Grid item>
                <ExportTables activeTab={activeTab} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item flex="1 1 auto" maxHeight="calc(100% - 110px)" overflow="auto">
        {tabs.find((tab) => tab.name === activeTab).component}
      </Grid>
    </Grid>
  );
};
