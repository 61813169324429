import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ArrowDown } from "Icons/ArrowDown";
import { SimpleSuggestionBlock } from "./components/SimpleSuggestionBlock";
import { gaClickCallback } from "helpers/common";

export const SeeMoreAccordion = ({ suggestionsList, sendMessage, onStateChange, isExpandedList }) => {
  const SUGGESTIONS_LIST = [
    { title: suggestionsList.compare },
    { title: suggestionsList.describe },
    { title: suggestionsList.link },
  ];

  const ACTION_BUTTONS = [
    { label: "Phrase Results", onClick: () => sendMessage("Phrase Results") },
    { label: "Phrase Methods", onClick: () => sendMessage("Phrase Methods") },
  ];

  return (
    <Stack px="40px">
      <Accordion
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
          width: "100%",
          mb: "-1px",
          "&.Mui-expanded": {
            marginBottom: "-1px!important",
          },
          zIndex: 1,
        }}
        expanded={isExpandedList}
        onClick={() => {
          if (!isExpandedList)
            gaClickCallback({
              label: "see more button",
            });
        }}
        onChange={() => onStateChange({ isExpandedList: !isExpandedList })}
      >
        <AccordionSummary
          expandIcon={<ArrowDown width="14px" height="14px" />}
          sx={{
            height: "fit-content !important",
            minHeight: "fit-content !important",
            width: "fit-content",
            gap: "14px",
            px: "0px",
          }}
        >
          <Stack gap="16px" direction="row" width="fit-content">
            {ACTION_BUTTONS.map(({ label, onClick }) => (
              <Button
                key={label}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  gaClickCallback({
                    label: label,
                    callback: () => onClick(),
                  });
                }}
                sx={{ fontWeight: "600" }}
              >
                {label}
              </Button>
            ))}
          </Stack>
          <Box sx={{ height: "16px", my: "auto" }}>
            <Typography fontSize="12px" lineHeight="16px" ml="20px">
              See {isExpandedList ? "less" : "more"}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: "4px 0px 16px",
            height: "fit-content",
            gap: "18px",
            display: "flex",
          }}
        >
          {SUGGESTIONS_LIST.map(({ title }, index) => (
            <SimpleSuggestionBlock key={index} text={title} onClick={() => sendMessage(title)} />
          ))}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
