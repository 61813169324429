import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NavbarWrapper = styled(Grid)(() => ({
  padding: "22px 22px 64px 22px",
  background: "#ffff",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "280px",
  minWidth: "280px",
  boxSizing: "border-box",
  overflow: "hidden",
  gap: "20px",
  borderRight: "1px #EAECF0 solid",

  "& ul": {
    padding: 0,
    gap: "6px",
    display: "flex",
    flexDirection: "column",
  },
}));

export const NavbarContent = styled(Box)(() => ({
  borderTop: "1px solid #EAECF0",
  padding: "20px 0px 0px",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  height: "100%",
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
}));

export const LinksWrapper = styled(Box)(() => ({
  height: "calc(50vh - 162px)",
  overflowY: "auto",
}));
