import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const getProjects = (filters) => {
  const sessionToken = localStorage.getItem("session_token");
  const keys = Object.keys(filters);
  let filter_params = "";
  keys.forEach((key) => {
    if (filters[key]) {
      filter_params += `${key}=${filters[key]}&`;
    }
  });

  return axios.get(`${API_URL}/projects/?${filter_params}`, {
    headers: {
      Authorization: "Bearer " + sessionToken,
    },
  });
};

export const createProject = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/projects/create/`, data);
};

export const getProject = (id) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.get(`${API_URL}/projects/${id}/`);
};

export const deleteProject = (id) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.delete(`${API_URL}/projects/${id}/`);
};
