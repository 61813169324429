import Menu from "@mui/material/Menu";

export const SimpleMenu = ({ handleClose, open, anchorEl, children }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiList-root": {
          padding: "9px 0px 9px 0px",
        },

        "& .MuiMenu-paper": {
          boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.05)",
          borderRadius: "8px",
          border: "1px #EAECF0 solid",
        },

        "& .MuiMenuItem-root": {
          color: "#475467",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          padding: "8px 12px",
        },
      }}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </Menu>
  );
};
