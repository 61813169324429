import { Grid, Box } from "@mui/material";
import { styled } from '@mui/system';

const KeyBoardContainer = styled(Box)(
  () => ({
    border: '1px solid #EAEDF2',
    borderRadius: '12px',
    padding: '16px'
  })
)

const KeyItemContainer = styled(Box)(
  ({fullWidth}) => ({
    background: '#D9E7FA',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 700,
    color: '#333333',
    width: fullWidth ? '165px' : 'inerith',
    minWidth: '50px',
    height: '50px',
     cursor: 'pointer'
  })
)

const numbers = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '.'];
const actions = ['+', '-', '*', '/', '^']

export function KeyBoard({addItemToExpression, deleteItemfromExpression}) {

  const addActionToExpression = (action) => {
    const expressionItem = {
      type: 'action',
      value: action
    };
    addItemToExpression(expressionItem);
  }

  const addNumberToExpression = (number) => {
    const expressionItem = {
      type: 'number',
      value: number
    };
    addItemToExpression(expressionItem);
  }

  return(
    <KeyBoardContainer>
      <Grid container justifyContent={'right'} flexWrap={'nowrap'} spacing={2}>
        <Grid item container spacing={1} flexDirection={'column'} flex={'0'}>
          {actions.map(action => (
            <Grid item key={action}>
              <KeyItem  symbol={action} onClick={() => addActionToExpression(action)} />
            </Grid>
          ))}
        </Grid>
        <Grid item container spacing={1} maxWidth={'190px'}>
          {numbers.map(number => (
            <Grid item key={number} flex={number === '0' ? '1':'0'}>
              <KeyItem  symbol={number} onClick={() => addNumberToExpression(number)}/>
            </Grid>
          ))}
          <Grid item>
          <KeyItem  symbol={'Delete'} fullWidth onClick={deleteItemfromExpression} />
          </Grid>
        </Grid>
      </Grid>
    </KeyBoardContainer>
  )
}

const KeyItem = ({symbol, fullWidth, onClick}) => {
  return (
    <KeyItemContainer fullWidth={fullWidth} onClick={onClick} >
      {symbol}
    </KeyItemContainer>
  )
}