import * as React from "react";
import { SvgIcon } from "@mui/material";

export const PlusIcon = (props) => (
  <SvgIcon
    style={{ width: "15px", height: "15px", fill: "none" }}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.6428 8.8104H8.88659V13.5254C8.88659 14.2846 8.26578 14.9 7.49998 14.9C6.73417 14.9 6.11336 14.2846 6.11336 13.5254V8.8104H1.35713C0.642808 8.73707 0.0999756 8.14034 0.0999756 7.42842C0.0999756 6.7165 0.642808 6.11977 1.35713 6.04644H6.09845V1.34623C6.17243 0.638101 6.77437 0.0999756 7.49252 0.0999756C8.21067 0.0999756 8.81262 0.638101 8.88659 1.34623V6.04644H13.6428C14.3571 6.11977 14.9 6.7165 14.9 7.42842C14.9 8.14034 14.3571 8.73707 13.6428 8.8104Z"
      fill="white"
    />
  </SvgIcon>
);
