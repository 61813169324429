import { useEffect, useState } from "react";

import {
  Box,
  Table,
  Paper,
  Tooltip,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  TableContainer,
  Stack,
} from "@mui/material";
import { roundNumbers } from "helpers/roundNumbers";
import { CopyIcon } from "Icons/CopyIcon";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const VARIABLES_WITH_ICON = ["Name", "Variable", "index"];

export const AnalyseTable = ({ data }) => {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (!!data.length) {
      let keys = Object.keys(data[0]);
      setHeaders(keys);
    }
  }, [data]);

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      {!!headers.length && (
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            boxSizing: "border-box",
            maxHeight: "400px",
            borderRadius: 0,
          }}
        >
          <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={`header:${header}`}
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#101828",
                      minWidth: "150px",
                      lineHeight: "19px",
                      background: "linear-gradient(0deg, #FAFBFB, #FAFBFB)",
                      borderColor: "#F2F4F7",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{ "& .MuiTableCell-root": { borderColor: "#F2F4F7", fontWeight: 400, color: "#101828" } }}
            >
              {data?.map((row, index) => (
                <TableRow hover key={index} sx={{ backgroundColor: index % 2 !== 0 ? "#F9FAFB" : "inherit" }}>
                  {headers.map((header) => {
                    const variable = row?.["p value"] || row?.["P>|z|"];
                    const showIcon = !!variable && (variable < 0.05 || variable === "<0.001");
                    const isItemWithIcon = VARIABLES_WITH_ICON.includes(header) && showIcon;

                    return header !== "Conclusion" ? (
                      <TableCell key={header + index} align="left">
                        <Stack
                          sx={
                            isItemWithIcon
                              ? {
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                  gap: "6px",
                                }
                              : {}
                          }
                        >
                          {roundNumbers(row[header])}
                          {isItemWithIcon && <CheckCircleOutlineIcon sx={{ color: "#2F80ED" }} />}
                        </Stack>
                      </TableCell>
                    ) : (
                      <Tooltip
                        followCursor
                        enterDelay={500}
                        title={row.Conclusion}
                        key={header + index}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(row.Conclusion);
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            "& svg": {
                              opacity: 0,
                              transition: "0.3s",
                            },
                            ":hover": {
                              "& svg": {
                                opacity: 1,
                                transition: "0.3s",
                              },
                            },
                          }}
                        >
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box maxWidth="80%">{row[header]}</Box>

                            <IconButton
                              onClick={() => {
                                copyTextToClipboard(row[header]);
                              }}
                            >
                              <CopyIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </Tooltip>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
