import { useDispatch, useSelector } from 'react-redux';
import { setShowErrors, clearErrors } from '../actions/user';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';

export const ErrorPopup = () => {
  const dispatch = useDispatch();
  const open = useSelector(state => state.userReducer.showErrors);
  const error = useSelector(state => state.userReducer.errors);
  const [errors, setErrors] = useState([])
  useEffect(() => {
    if(!error.length) {
      return
    }
    let newErrors = []
    if(Array.isArray(error)) {
      error.forEach(err => {
        newErrors.push((err?.loc ? err.loc[1] : '') + ' ' + err.msg)
      })
      setErrors(newErrors)
    }
    if(typeof error === 'string') {
      setErrors([error])
    }
  }, [error])

  const handleClose = () => {
    dispatch(setShowErrors(false));
    dispatch(clearErrors())
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {errors.length > 1 ? 'Errors' : 'Error'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <List>
            {errors.map(errorItem => (
              <ListItem>
                <Typography color={'error'}>{errorItem}</Typography>
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color='info' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}