import React from "react";

import { CHAT_SUGGESTIONS } from "../../../../../../constants/chat.constants";

import { ExampleText } from "./styles";

import { Box, Stack, Typography } from "@mui/material";

export const InfoTooltipContent = ({ handleSetExampleMessage }) => {
  return (
    <Box sx={{ overflowY: "auto", height: "100%", maxHeight: "70vh" }}>
      {CHAT_SUGGESTIONS.map(({ title, command, example }, index) => (
        <Stack key={index} gap="5px" sx={{ whiteSpace: "pre-wrap" }} py="4px" px="6px">
          <Typography variant="subtitle2" fontWeight={600}>
            {title}:
          </Typography>
          {!!example && (
            <Typography variant="subtitle2" ml="30px">
              (e.g., {example})
            </Typography>
          )}
          <ExampleText variant="subtitle2" onClick={() => handleSetExampleMessage(command)}>
            {command}
          </ExampleText>
        </Stack>
      ))}
    </Box>
  );
};
