import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ActiveChart, IconShadowWrapper, NavbarItem, PurpleBox, StylesInput } from "./styles";

import { DeleteIcon } from "Icons/DeleteIcon";
import { PencilIcon } from "Icons/PencilIcon";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DatasetIcon } from "Icons/DatasetIcon";
import { gaClickCallback } from "helpers/common";

export const NavbarLink = ({
  id,
  type = "default",
  itemCallback = () => {},
  title,
  active = false,
  onClick,
  handleDeleteChat,
  handleUpdateChat,
}) => {
  const { id: activeChatId } = useSelector((state) => state.chatsReducer.activeChat);
  const [isEditing, setIsEditing] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [editedName, setEditedName] = useState(title);

  const handleEditClick = (event) => {
    event.stopPropagation();
    setIsEditing(true);
  };

  const handleSaveClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (title !== editedName) handleUpdateChat(id, editedName);
    setIsEditing(false);
    setIsHover(false);
  };

  const handleCancelClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsEditing(false);
    setEditedName(title);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    handleDeleteChat(true);
  };

  useEffect(() => {
    if (activeChatId && activeChatId !== id) {
      setIsEditing(false);
    }
  }, [activeChatId, id]);

  const handleShowCorrectItem = (name) => {
    switch (name) {
      case "dataset":
        return (
          <NavbarItem
            active={active}
            onClick={() =>
              gaClickCallback({
                label: "Dataset click on Navbar item",
                callback: () => onClick(),
              })
            }
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {active && <ActiveChart />}
            <DatasetIcon fill={active ? "#7F56D9" : "#525866"} />
            <Typography noWrap>{title}</Typography>
            <IconShadowWrapper isHover={isHover}>
              <MoreHorizIcon onClick={itemCallback} />
            </IconShadowWrapper>
          </NavbarItem>
        );
      case "chatLink":
        return (
          <NavbarItem
            isEditing={isEditing}
            active={active}
            onClick={(event) =>
              gaClickCallback({
                label: "Chat link click on Navbar item",
                callback: () => {
                  event.stopPropagation();
                  onClick();
                },
              })
            }
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <PurpleBox />
            {isEditing ? (
              <StylesInput
                focused
                onClick={(event) => event.stopPropagation()}
                value={editedName}
                onChange={({ target }) => setEditedName(target.value)}
                fullWidth
              />
            ) : (
              <Typography noWrap>{title}</Typography>
            )}
            {isEditing ? (
              <IconShadowWrapper isHover={isHover} isEdit isChat>
                <IconButton disableRipple sx={{ p: 0 }} onClick={(event) => handleSaveClick(event)}>
                  <CheckIcon sx={{ width: "21px", color: "#475467" }} />
                </IconButton>
                <IconButton disableRipple sx={{ p: 0 }} onClick={(event) => handleCancelClick(event)}>
                  <CloseIcon sx={{ width: "21px", color: "#475467" }} />
                </IconButton>
              </IconShadowWrapper>
            ) : (
              <IconShadowWrapper isHover={isHover} isEdit={false} isChat>
                <IconButton disableRipple sx={{ p: 0 }} onClick={(event) => handleEditClick(event)}>
                  <PencilIcon />
                </IconButton>
                <IconButton disableRipple sx={{ p: 0 }} onClick={(event) => handleDeleteClick(event)}>
                  <DeleteIcon stroke="#475467" />
                </IconButton>
              </IconShadowWrapper>
            )}
          </NavbarItem>
        );

      default:
        break;
    }
  };
  return <>{handleShowCorrectItem(type)}</>;
};
