import { Box, Stack, Typography } from "@mui/material";

import { classes, UserAvatar } from "../styles";
import { handleUserName } from "helpers/getUserName";

export const ModalHeader = ({ userInfo }) => {
  const { first_name, last_name, email } = userInfo || {};
  return (
    <Box sx={classes.header}>
      <UserAvatar alt="User avatar">{handleUserName("user", first_name, last_name)}</UserAvatar>
      <Stack direction="column" sx={classes.userBlock}>
        <Typography variant="h6" noWrap>
          {first_name} {last_name}
        </Typography>
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          height="24px"
          sx={{
            maxWidth: "100%",
            overflow: "hidden",

            svg: {
              width: "19px",
              height: "19px",
              mb: "-3px",
            },
          }}
        >
          <Typography variant="h5" noWrap lineHeight="23px">
            {email}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
