import { CircularProgress, Stack } from "@mui/material";
import React from "react";

export const LoadingScreen = ({ wrapperSx }) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        ...wrapperSx,
      }}
    >
      <CircularProgress color="primary" />
    </Stack>
  );
};
