import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  DialogActions,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Stack,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TextField } from "../../components/inputs/textInput";
import { SelectInput } from "../../components/inputs/select";
import { GenieIcon } from "../../components/genie-icon";

import { updateVariableAttribute } from "../../actions/datasets";

export const varTypeOptions = [
  { name: "Numeric", value: "numeric" },
  { name: "Factor", value: "factor" },
  { name: "Ordinal", value: "ordinal" },
  { name: "String", value: "string" },
];

export function VariableEditDialog({ open, handleClose, selectedVariable, datasetId = null }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [name, setName] = useState(selectedVariable?.variable_name);
  const [type, setType] = useState(selectedVariable?.variable_type);
  const [note, setNote] = useState(selectedVariable?.note);
  const [missingValue, setMissingValue] = useState(0);
  const [discreateItems, setDiscreateItems] = useState([]);
  const [discreateValues, setDiscreateValues] = useState([]);
  const [discreateValue, setDiscreateValue] = useState(null);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [rangeX, setRangeX] = useState("");
  const [rangeY, setRangeY] = useState("");
  const [rangesArray, setRangesArray] = useState([]);

  const [openAddNewLabel, setOpenAddNewLabel] = useState(false);
  const [labelValues, setLabelValues] = useState({});
  const [newValue, setNewValue] = useState("");
  const [newLabel, setNewLabel] = useState("");
  const summary = useSelector((state) => state.datasetsReducer.summary);
  const frequency = useSelector((state) => state.datasetsReducer.frequency);

  useEffect(() => {
    setName(selectedVariable?.variable_name);
    setType(selectedVariable?.variable_type);
    setNote(selectedVariable?.note);
    setMissingValue(summary?.[selectedVariable?.variable_name]?.["NAs"]?.[0]);

    let _discreateValuesItems = [];
    frequency[selectedVariable?.variable_name]?.forEach((item) => {
      _discreateValuesItems.push({ value: item.Values, name: item.Values });
    });
    setDiscreateItems(_discreateValuesItems);
    setDiscreateValues(selectedVariable?.discrete_missing_value);
    if (selectedVariable.missing_value_range.length) {
      const range = selectedVariable.missing_value_range?.split("-");
      setRangesArray([...rangesArray, `${range[0]}-${range[1]}`]);
    } else {
      setRangesArray([]);
    }
    setLabelValues(selectedVariable?.value_labels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariable, summary, frequency]);

  const handleCloseAddNew = () => {
    setDiscreateValue(null);
    setOpenAddNew(false);
  };

  const addNewDiscreateValue = () => {
    let _discreateValues = [...discreateValues];
    _discreateValues.push(discreateValue);
    setDiscreateValues(_discreateValues);
    handleCloseAddNew();
  };

  const handleDeleteDiscreateValue = (value) => {
    let _discreateValues = [...discreateValues];
    const index = _discreateValues.findIndex((el) => el === value);
    _discreateValues.splice(index, 1);
    setDiscreateValues(_discreateValues);
  };

  const handleCloseAddNewLabel = () => {
    setNewValue("");
    setNewLabel("");
    setOpenAddNewLabel(false);
  };

  const addNewLabelValue = () => {
    let _labelValues = { ...labelValues };
    _labelValues[newLabel] = newValue;
    setLabelValues(_labelValues);
    handleCloseAddNewLabel();
  };

  const handleDeleteLabelValue = (label) => {
    let _labelValues = { ...labelValues };
    delete _labelValues[label];
    setLabelValues(_labelValues);
  };

  const handleAddRanges = () => {
    setRangesArray([...rangesArray, `${rangeX}-${rangeY}`]);
    setRangeX("");
    setRangeY("");
  };

  const handleFilterRanges = (range) => {
    const filtredRanges = rangesArray.filter((item) => item !== range);
    setRangesArray(filtredRanges);
  };

  const handleSave = () => {
    let data = {
      variable_name: selectedVariable.variable_name,
      variable_type: type,
      note: note,
      missing_value_range: rangesArray,
      discrete_missing_value: discreateValues,
      value_labels: labelValues,
      new_variable_name: name,
    };

    dispatch(updateVariableAttribute(data, id || datasetId));
    handleClose();
  };

  return (
    selectedVariable && (
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>{selectedVariable?.variable_name} Properties</DialogTitle>
        <DialogContent>
          <TextField label="New Variable Name" value={name} onChange={(e) => setName(e.target.value)} />

          <SelectInput
            label="Variable Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            items={varTypeOptions}
          />

          <TextField label="Note" value={note} onChange={(e) => setNote(e.target.value)} />

          <Typography fontWeight={600} fontSize={15}>
            Missing Values: {missingValue > 0 ? missingValue : ""}
          </Typography>
          <br />
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                left: "150px",
                cursor: "pointer",
                zIndex: 9999,
              }}
              onClick={() => setOpenAddNew(true)}
            >
              <GenieIcon type="add" />
            </div>
            <Typography fontSize={15}>New Discrete Value</Typography>
            <br />
            <Stack spacing={1} direction="row" sx={{ flexWrap: "wrap" }}>
              {discreateValues.length ? (
                discreateValues.map((value) => (
                  <Chip
                    sx={{ marginBottom: "8px" }}
                    label={value}
                    onDelete={() => handleDeleteDiscreateValue(value)}
                  />
                ))
              ) : (
                <Chip sx={{ marginBottom: "8px" }} label={"None"} />
              )}
            </Stack>

            <Dialog open={openAddNew} onClose={setOpenAddNew} maxWidth="xs">
              <DialogTitle>
                <Typography>Add New Discrete Value</Typography>
              </DialogTitle>
              <DialogContent>
                <SelectInput
                  label="New Discreate Value"
                  value={discreateValue}
                  onChange={(e) => setDiscreateValue(e.target.value)}
                  items={discreateItems}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="info" onClick={handleCloseAddNew}>
                  Cancel
                </Button>
                <Button variant="contained" color="secondary" onClick={addNewDiscreateValue}>
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <Stack py="12px">
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                label="Range"
                placeholder="Start range"
                value={rangeX}
                onChange={(e) => setRangeX(e.target.value)}
              />
              <TextField placeholder="End range" value={rangeY} onChange={(e) => setRangeY(e.target.value)} />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleAddRanges}
                sx={{ minWidth: "45px", maxWidth: "45px", height: "45px", marginTop: "8px !important" }}
              >
                <AddIcon />
              </Button>
            </Stack>
            {!!rangesArray.length && (
              <Stack spacing={1.3} direction="row" sx={{ flexWrap: "wrap" }}>
                {rangesArray.map((value) => (
                  <Chip key={value} label={value} onDelete={() => handleFilterRanges(value)} />
                ))}
              </Stack>
            )}
          </Stack>

          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                left: "150px",
                cursor: "pointer",
                zIndex: 9999,
              }}
              onClick={() => setOpenAddNewLabel(true)}
            >
              <GenieIcon type="add" />
            </div>
            <Typography fontSize={15}>Value Labels</Typography>
            <br />
            <Stack spacing={1} direction="row" sx={{ flexWrap: "wrap" }}>
              {Object.keys(labelValues).length ? (
                Object.keys(labelValues).map((label) => (
                  <Chip
                    sx={{ marginBottom: "8px" }}
                    label={label + " = " + labelValues[label]}
                    onDelete={() => handleDeleteLabelValue(label)}
                  />
                ))
              ) : (
                <Chip sx={{ marginBottom: "8px" }} label={"None"} />
              )}
            </Stack>
            <Dialog open={openAddNewLabel} onClose={setOpenAddNewLabel} maxWidth="xs">
              <DialogTitle>
                <Typography>Add New Value Label</Typography>
              </DialogTitle>
              <DialogContent>
                <TextField label="Label" value={newLabel} onChange={(e) => setNewLabel(e.target.value)} />
                <SelectInput
                  label="Value"
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                  items={discreateItems}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="info" onClick={handleCloseAddNewLabel}>
                  Cancel
                </Button>
                <Button variant="contained" color="secondary" onClick={addNewLabelValue}>
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <br />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="info" onClick={handleClose} fullWidth={false}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={handleSave} fullWidth={false}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}
