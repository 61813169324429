import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  editCompareGroup,
  editCorrelations,
  editSurvival,
  editMixedMuktivariate,
  editMuktivariate,
} from "../../actions/analyses";
import {
  Drawer,
  Grid,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EditForm } from "./editForm";

export const EditGraph = ({ open, handleClose, route }) => {
  const [loader, setLoader] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const dispatch = useDispatch();
  const analyse = useSelector((state) => state.analysesReducer.analyse);
  const selectGraphImage = useSelector(
    (state) => state.analysesReducer.selectGraphImage
  );

  useEffect(() => {
    if (analyse.summary?.data && route) {
      if (route[1] === "heatmap" && analyse.analysis_type === "correlations") {
        setConfiguration(analyse.summary.data[route[0]].configurationHeat[0]);
        return;
      }

      if (
        analyse.analysis_type === "mixed_multivariate" ||
        analyse.analysis_type === "multivariate"
      ) {
        if (route.length > 2) {
          setConfiguration(
            analyse.summary.data[route[0]].configurations.all_models[route[1]][
              route[2]
            ][0]
          );
        } else {
          setConfiguration(
            analyse.summary.data[route[0]].configurations.main_independents[
              route[1]
            ][0]
          );
        }
        return;
      }
      setConfiguration(
        analyse.summary.data[route[0]].configurations[route[1]][route[2]][0]
      );
    }
  }, [analyse, route]);

  const onChange = (event) => {
    let _configuration = { ...configuration };
    _configuration = {
      ...configuration,
      [event.target.name]: event.target.value || event.target.checked,
    };
    setConfiguration(_configuration);
  };

  useEffect(() => {
    if (configuration) {
      handleSubmit("single", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  const handleSubmit = (apply_term, save) => {
    if (save) setLoader(true);
    let data = {
      file_configuration: { width: 1000, height: 1000, resolution: 200 },
    };
    if (analyse.analysis_type === "compare_groups") {
      data = {
        ...data,
        data: {
          name: analyse.name,
          analysis_id: analyse.id,
          apply_term: apply_term,
          split_level: [route[0]],
          save,
          dependent_name: [route[1]],
          independent_name: [route[2]],
          new_configuration: [configuration],
        },
      };
      dispatch(editCompareGroup(data, route, setLoader));
    }
    if (analyse.analysis_type === "correlations") {
      data = {
        ...data,
        data: {
          name: analyse.name,
          analysis_id: analyse.id,
          apply_term: apply_term,
          split_level: [route[0]],
          save: save,
          var1: route[1] === "heatmap" ? [] : [route[1]],
          var2: route[1] === "heatmap" ? [] : [route[2]],
          heatmap: route[1] === "heatmap",
          new_configuration: [configuration],
          new_configuration_heatmap: [],
        },
      };
      dispatch(editCorrelations(data, route, setLoader));
    }

    if (analyse.analysis_type === "survival") {
      data = {
        ...data,
        data: {
          name: analyse.name,
          analysis_id: analyse.id,
          apply_term: apply_term,
          split_level: route[0] === "all" ? "all" : route[0],
          save: save,
          event_name: route[1],
          independent_name: route[2],
          new_configuration: [configuration],
        },
      };
      dispatch(editSurvival(data, route, setLoader));
    }

    if (analyse.analysis_type === "multivariate") {
      data = {
        ...data,
        data: {
          name: analyse.name,
          analysis_id: analyse.id,
          apply_term: apply_term,
          split_level: route[0] === "all" ? ["all"] : [route[0]],
          save: save,
          dependents: [route[1]],
          new_configuration: [configuration],
        },
      };
      if (route[2]) data.data.independents = [route[2]];
      // if (route[2]) data.data.exposure = [route[2]];
      dispatch(editMuktivariate(data, route, setLoader));
    }

    if (analyse.analysis_type === "mixed_multivariate") {
      data = {
        ...data,
        data: {
          name: analyse.name,
          analysis_id: analyse.id,
          apply_term: apply_term,
          split_level: route[0] === "all" ? "all" : route[0],
          save: save,
          outcome: [route[1]],
        },
        configuration: [configuration],
      };

      if (route[2]) data.data.exposure = [route[2]];
      dispatch(editMixedMuktivariate(data, route, setLoader));
    }
  };

  const getImageUrl = () => {
    if (selectGraphImage) return selectGraphImage;
    if (analyse.analysis_type === "correlations" && route[1] === "heatmap") {
      return analyse?.summary?.images[route[0]].heatmap;
    }
    if (
      analyse.analysis_type === "mixed_multivariate" ||
      analyse.analysis_type === "multivariate"
    ) {
      if (route.length > 2) {
        return analyse?.summary?.images[route[0]].all_models[route[1]][
          route[2]
        ];
      } else {
        return analyse?.summary?.images[route[0]].main_independents[route[1]];
      }
    }
    return analyse?.summary?.images[route[0]][route[1]][route[2]];
  };

  return (
    configuration && (
      <Drawer
        anchor="right"
        open={open}
        onClose={() => handleClose()}
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { width: "calc(100vw - 200px)", background: "#F8FAFF" },
        }}
      >
        <Grid container justifyContent={"flex-end"} wrap={"nowrap"}>
          <Grid item width={"100%"}>
            <Box
              sx={{
                padding: "50px 40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ExpandMoreIcon
                sx={{
                  color: "#141414",
                  cursor: "pointer",
                  transform: "rotate(.25turn)",
                  marginRight: "15px",
                }}
                onClick={() => handleClose()}
              />
              <Typography variant="h2" color="#141414">
                Graphic View
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "0 40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "calc(100vh - 300px)" }}
                src={getImageUrl()}
                alt="graph"
              />
            </Box>
          </Grid>
          <Grid item minWidth={"400px"}>
            <EditForm
              configuration={configuration}
              onChange={onChange}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              analyseType={analyse.analysis_type}
              itHeat={
                route[1] === "heatmap" &&
                analyse.analysis_type === "correlations"
              }
            />
          </Grid>
        </Grid>
        <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={loader}>
          <Box display={"flex"} flexDirection="column" alignItems={"center"}>
            <Typography>Updating graphs</Typography>
            <CircularProgress color="inherit" />
          </Box>
        </Backdrop>
      </Drawer>
    )
  );
};
