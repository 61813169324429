import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import {
  changeFilters,
  fetchDatasets,
  deleteDatasetById,
} from "../../actions/datasets";

import { GenieIcon } from "../../components/genie-icon";
import { Pagination } from "../../components/pagination";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

export const DatasetsTable = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const datasets = useSelector((state) => state.datasetsReducer.list);
  const filters = useSelector((state) => state.datasetsReducer.filters);
  const count = useSelector((state) => state.datasetsReducer.count);

  const handleDeleteDataset = (event, id) => {
    event.stopPropagation();
    dispatch(deleteDatasetById(id, () => dispatch(fetchDatasets())));
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ height: "calc(100vh - 510px)" }}>
        <Table stickyHeader sx={{ minWidth: 900 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Dataset Name!</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Analysis Count</TableCell>
              <TableCell>Number of Cols</TableCell>
              <TableCell>Number of Rows</TableCell>
              <TableCell>Parent Project</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datasets.map((dataset) => (
              <TableRow
                key={dataset.id}
                onClick={() => navigate(`/dashboard/dataset/${dataset.id}`)}
              >
                <TableCell component="th" scope="row">
                  {dataset.name}
                </TableCell>
                <TableCell align="left">
                  {moment(dataset.created_at).format("MM/DD/yy, h:mm")}
                </TableCell>
                <TableCell align="left">{dataset.analyses_count}</TableCell>
                <TableCell align="left">{dataset.number_of_columns}</TableCell>
                <TableCell align="left">{dataset.number_of_rows}</TableCell>
                <TableCell align="left">{dataset.project_name}</TableCell>
                <TableCell align="left">
                  <Grid container justifyContent={"space-around"}>
                    <GenieIcon
                      callback={(event) =>
                        handleDeleteDataset(event, dataset.id)
                      }
                      type="trash"
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        filters={filters}
        changeFilters={(filters) => dispatch(changeFilters(filters))}
        count={count}
      />
    </>
  );
};
