import React from "react";
import { SuggestionWrapper } from "./styles";
import { StatisticIcon } from "Icons/StatisticIcon";
import { IconButton, Stack, Typography } from "@mui/material";
import { StatisticSecondIcon } from "Icons/StatisticSecondIcon";
import { StatisticThirdIcon } from "Icons/StatisticThirdIcon";
import { SUGGESTIONS_TYPES } from "./SuggestionsListScreen";

export const SuggestionItem = ({ title, type, onClick }) => {
  const itemTypes = {
    [SUGGESTIONS_TYPES[0]]: <StatisticIcon />,
    [SUGGESTIONS_TYPES[1]]: <StatisticThirdIcon />,
    [SUGGESTIONS_TYPES[2]]: <StatisticSecondIcon />,
  };
  return (
    <SuggestionWrapper onClick={onClick}>
      <IconButton sx={{ p: 0, width: "32px", ml: type === SUGGESTIONS_TYPES[0] ? "16px" : "0" }}>
        {itemTypes[type]}
      </IconButton>

      <Stack sx={{ width: "calc(100% - 40px)" }}>
        <Typography variant="body1">{title}</Typography>
      </Stack>
    </SuggestionWrapper>
  );
};
