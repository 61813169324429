import * as Yup from "yup";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { TextField } from "../inputs/textInput";

import { updateRequest } from "../../actions/user";

import { classes, CloseIconButton } from "./styles";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Button, DialogContent, DialogActions, Stack, Typography } from "@mui/material";
import { getSettingsField } from "./userSettings.constants";
import { ModalHeader } from "./components/ModalHeader";

export const UserSettings = ({ open, onClose, userData }) => {
  const dispatch = useDispatch();

  const FIELDS_FOR_CHANGE = useMemo(
    () => ({
      email: userData.email,
      first_name: userData.first_name,
      last_name: userData.last_name,
      number_of_roundings: userData.number_of_roundings,
      significance_level: userData.significance_level,
      significance_level_normality: userData.significance_level_normality,
    }),
    [userData]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: FIELDS_FOR_CHANGE,
    onSubmit: (values) => {
      dispatch(updateRequest(values, handleCloseSettings));
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Required field"),
      last_name: Yup.string().required("Required field"),
      number_of_roundings: Yup.number()
        .required("Required field")
        .positive("Only positive number are aloved"),
      significance_level: Yup.number().required("Required field").positive("Only positive number are aloved"),
      significance_level_normality: Yup.number()
        .required("Required field")
        .positive("Only positive number are aloved"),
    }),
  });

  const handleCloseSettings = () => {
    onClose(false);
    formik.setValues(FIELDS_FOR_CHANGE);
  };

  const fields = getSettingsField();

  return (
    <Dialog
      open={open}
      onClose={handleCloseSettings}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: "700px",
          borderRadius: "14px !important",
        },
      }}
    >
      <ModalHeader {...{ userInfo: formik.values || {} }} />
      <CloseIconButton onClick={handleCloseSettings}>
        <CloseIcon />
      </CloseIconButton>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            borderTop: "#EAECF0 1px solid",
            borderBottom: "#EAECF0 1px solid",
            padding: "0px",
            margin: "0 24px",
            display: "flex",
            flexDirection: "column",
            "label+.MuiInputBase-root": {
              marginTop: "0px",
            },
          }}
        >
          <Stack sx={classes.nameFieldContainer}>
            <Typography variant="h6" sx={classes.fieldLabel}>
              Name
            </Typography>
            <Stack direction="column" width={{ xs: "65%", sm: "67%" }} gap="14px">
              <Stack sx={classes.nameFieldWrapper} width="100%">
                {fields.slice(0, 2).map(({ name, label, type, disabled }, index) => (
                  <TextField
                    key={index}
                    disabled={disabled}
                    type={type}
                    name={name}
                    placeholder={label}
                    value={formik.values[name]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={!!formik.errors[name] && !!formik.touched?.[name] && formik.errors[name]}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={classes.nameFieldContainer}>
            <Typography variant="h6" sx={classes.fieldLabel}>
              Email
            </Typography>
            <Stack direction="column" width={{ xs: "65%", sm: "67%" }}>
              {fields.slice(2, 3).map(({ name, label, type, disabled }, index) => (
                <TextField
                  key={index}
                  disabled={disabled}
                  type={type}
                  name={name}
                  placeholder={label}
                  value={formik.values[name]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors[name] && !!formik.touched?.[name] && formik.errors[name]}
                />
              ))}
            </Stack>
          </Stack>

          {fields.slice(3, 6).map(({ name, label, type, disabled }, index) => (
            <Stack
              sx={{
                ...classes.nameFieldContainer,
              }}
              key={index}
            >
              <Typography variant="h6" sx={classes.fieldLabel}>
                {label}
              </Typography>
              <Stack direction="column" width={{ xs: "65%", sm: "67%" }}>
                <TextField
                  disabled={disabled}
                  type={type}
                  name={name}
                  placeholder={label}
                  value={formik.values[name]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors[name] && !!formik.touched?.[name] && formik.errors[name]}
                />
              </Stack>
            </Stack>
          ))}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "24px 24px 24px",
          }}
        >
          <Button
            variant="outlined"
            sx={{ mr: "5px", height: "42px", width: "134px" }}
            onClick={handleCloseSettings}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formik.dirty || !formik.isValid}
          >
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
