import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd'
import { VaribleDragLayer } from './varibleDragLayer';

import {
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';

import { DragableVarible } from './dragableVarible';

const VariblesContainer = styled(Box)(
  () => ({
    background: '#F1F5F9',
    border: '1px solid #EAEDF2',
    borderRadius: '12px',
    padding: '16px 0',
    width: '222px',
    maxHeight: '450px',
  })
)

const ListContainer = styled(Box)(
  () => ({
    overflowY: 'auto',
    maxHeight: '400px',
    marginTop: '5px',
    padding: '0 16px'
  })
)

export function VaribleList({selected, setSelected}) {
  const [varibles, setVaribles] = useState([]);

  const variblesData = useSelector(state => state.datasetsReducer.datasetVarTypes)

  const handleDrop = useCallback((item) => {
    item.deleteVarible(item.varible)
  }, [])

  const [, drop] = useDrop({
    accept: 'varible',
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  useEffect(() => {
    if(!variblesData) return
    setVaribles(Object.keys(variblesData))
  }, [variblesData])

  const handleItemSelection = (varible) => {
    let _selected = [...selected]
    if(_selected.find(item => item.variable_name === varible.variable_name)) {
      const index = _selected.findIndex(item => item.variable_name === varible.variable_name);
      _selected.splice(index, 1)
    } else {
      _selected.push(varible)
    }
    setSelected(_selected)
  };

  return (
    <VariblesContainer ref={drop}>
      <Typography marginLeft={'16px'} fontSize={15} color={'#474551'} fontWeight={600}>Varible{selected.length ?` selected: ${selected.length}`: ''}</Typography>
      <ListContainer >
        {varibles.map((varible, index) => (
          <DragableVarible key={'varible'+index} selected={selected} handleItemSelection={handleItemSelection} varible={variblesData[varible]} />
        ))}
      </ListContainer>
      <VaribleDragLayer selected={selected}/>
    </VariblesContainer>
  )
}