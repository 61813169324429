import { createDatasetId } from "../services/datasets";
import { uploadDatasetFile } from "../services/datasets-s3";
import {
  getProjects,
  createProject,
  getProject,
  deleteProject,
} from "../services/projects";

export const SET_PROJECTS = "PROJECTS/SET_PROJECTS";
export const CHANGE_FILTERS = "PROJECTS/CHANGE_FILTERS";
export const SET_SELECTED = "PROJECT/SET_SELECTED";
export const SET_ERRORS = "PROJECT/SET_ERRORS";

export const fetchProjects = () => {
  return (dispatch, getState) => {
    getProjects(getState().projectsReducer.filters)
      .then((res) => {
        dispatch({
          type: SET_PROJECTS,
          list: res.data.records || [],
          count: res.data.count,
        });
      })
      .catch((error) => {});
  };
};

export const fetchProject = (id) => {
  return (dispatch) => {
    getProject(id).then((res) => {
      dispatch(selectProject(res.data));
    });
  };
};

export const changeFilters = (filters) => {
  return (dispatch) => {
    Promise.resolve(dispatch({ type: CHANGE_FILTERS, filters })).then(() =>
      dispatch(fetchProjects())
    );
  };
};

export const removeProject = (id) => {
  return (dispatch) => {
    deleteProject(id).then(() => {
      dispatch(fetchProjects());
    });
  };
};

export const selectProject = (project) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED, project: project });
  };
};

// export const saveProject = (data, callback) => {
//   const projectData = {project_name: data.projectName, project_description: data.projectDescription}
//   return (dispatch) => {
//     createProject(projectData)
//       .then(res => {
//         callback();
//         if(data.datasetName && data.datasetFile) {
//           const datasetData = { id: res.data.id, datasetName: data.datasetName, datasetFile: data.datasetFile }
//           dispatch(uploadDatasets(datasetData));
//         }
//         dispatch(fetchProjects());
//       })
//       .catch(error => {
//         if(error.response) dispatch({ type: SET_ERRORS, errors: error.response.data.detail })
//       })
//   }
// }

export const saveProject = (uploadData, callback, setDatasetId) => {
  const projectData = {
    project_name: uploadData.projectName,
    project_description: uploadData.projectDescription,
  };
  return (dispatch, getState) => {
    const { id } = getState().userReducer.data;

    createProject(projectData)
      .then((res) => {
        callback();
        if (uploadData.dataset_name && uploadData.datasetFile) {
          const filters = {
            dataset_name: uploadData.dataset_name,
            project_id: res.data.id,
          };

          createDatasetId(filters).then(({ data }) => {
            setDatasetId(data.dataset_id);
            uploadDatasetFile(
              uploadData.datasetFile,
              data.dataset_id,
              res.data.id,
              id
            );
          });
        }
        dispatch(fetchProjects());
      })
      .catch((error) => {
        if (error.response)
          dispatch({ type: SET_ERRORS, errors: error.response.data.detail });
      });
  };
};

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({ type: SET_ERRORS, errors: [] });
  };
};
