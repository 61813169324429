import { SvgIcon } from "@mui/material";
import React from "react";

export const StatisticIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "none" }}
      viewBox="0 0 300 300"
      xmlSpace="preserve"
    >
      <path
        d="M16.3 208.2s40.6 5.7 55.7-41 18-77.4 31.5-77.4 17.2 21 25 53.7c9 37.7 32.4 63.1 54.9 64.9"
        style={{
          fill: "none",
          stroke: "#5467ee",
          strokeWidth: 8.9396,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M127.1 208.2s30.1 5.7 45.3-41 18-77.4 31.5-77.4 17.2 21 25 53.7c9 37.7 32.4 63.1 54.9 64.9"
        style={{
          fill: "none",
          stroke: "#639aef",
          strokeWidth: 8.9396,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        style={{
          fill: "none",
          stroke: "#639aef",
          strokeWidth: 8.9396,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "13.9263,17.4079",
        }}
        d="M103.9 210.2v-93"
      />
      <path
        style={{
          fill: "none",
          stroke: "#5467ee",
          strokeWidth: 8.9396,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "13.9263,17.4079",
        }}
        d="M204.9 210.2v-93"
      />
    </SvgIcon>
  );
};
