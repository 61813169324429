import { useState } from 'react';
import { GenieIcon } from '../../components/genie-icon';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from '../../components/pagination';
import { DeleteDialog } from './deleteDialog';
import { changeFilters, removeAnalyses } from '../../actions/analyses';
import moment from 'moment';

export const AnalysesTable = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(0);
  const analyses = useSelector(state => state.analysesReducer.list);
  const filters = useSelector(state => state.analysesReducer.filters);
  const count = useSelector(state => state.analysesReducer.count);

  return (
    <>
      <TableContainer component={Paper} sx={{height: 'calc(100vh - 510px)'}}>
        <Table stickyHeader sx={{ minWidth: 900 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Analysis Name</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Dataset Name</TableCell>
              <TableCell>Parent Project</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analyses.map(analyse => (
              <TableRow
                key={analyse.id}
                onClick={() => navigate(`/dashboard/analyse/${analyse.id}#table`)}
              >
                <TableCell component="th" scope="row">{analyse.name}</TableCell>
                <TableCell align="left">{moment(analyse.created_at).format('MM/DD/yy, h:mm')}</TableCell>
                <TableCell align="left">{analyse.dataset_name}</TableCell>
                <TableCell align="left">{analyse.project_name}</TableCell>
                <TableCell align="left">
                  <GenieIcon
                    callback={(e) => {e.stopPropagation(); setOpenDelete(analyse.id)}}
                    type="trash"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        filters={filters}
        changeFilters={filters => dispatch(changeFilters(filters))}
        count={count}
      />
      <DeleteDialog
        open={openDelete}
        handleClose={() => setOpenDelete(0)}
        handleDelete={(id) => dispatch(removeAnalyses(id))}
        text={`analysis`}
      />
    </>
  );
};
