import { SvgIcon } from "@mui/material";
import React from "react";

export const CheckIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "28px", height: "28px", fill: "none" }}
      viewBox="0 0 28 28"
    >
      <path
        d="m8.75 14 3.5 3.5 7-7m6.417 3.5c0 6.443-5.224 11.667-11.667 11.667S2.333 20.443 2.333 14 7.557 2.333 14 2.333 25.667 7.557 25.667 14"
        stroke="#7F56D9"
        strokeWidth={2.333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
