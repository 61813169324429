import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { styled } from '@mui/system';

const ToggleButtonSmall = styled(ToggleButton)(
  () => ({
    height: '27px',
    background: '#E6EAEE',
    border: 'none',
    color: '#292D32 !important',
    '&.Mui-selected': {
      border: 0,
      background: '#3C475D',
      color: '#fff !important',
      
    },
    '& .MuiSvgIcon': {
      color: '#fff'
    }
  })
)

export function EditGraphAlignSelect({value, onChange, name}) {

  const handleChange = (event, newAligment) => {
    const e = {
      target: {
        name: name,
        value: newAligment !== null ? newAligment : value
      }
    }
    onChange(e)
  }

  return (
    <ToggleButtonGroup
      value={value}
      name={name}
      onChange={handleChange}
      exclusive
      fullWidth
      sx={{color: '#292D32'}}
    >
      <ToggleButtonSmall  value={0}>
        <FormatAlignLeftIcon fontSize='12px' />
      </ToggleButtonSmall>
      <ToggleButtonSmall value={0.5}>
        <FormatAlignCenterIcon fontSize='12px' />
      </ToggleButtonSmall>
      <ToggleButtonSmall value={1}>
        <FormatAlignRightIcon fontSize='12px' />
      </ToggleButtonSmall>
    </ToggleButtonGroup>
  );
}