import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { SuggestionItem } from "./SuggestionItem";
import { gaClickCallback } from "helpers/common";

export const SUGGESTIONS_TYPES = ["compare", "describe", "link"];

export const SuggestionsListScreen = ({ name, suggestionsList, sendMessage }) => {
  const SUGGESTIONS_LIST = [
    { title: suggestionsList.compare, type: SUGGESTIONS_TYPES[0] },
    { title: suggestionsList.describe, type: SUGGESTIONS_TYPES[1] },
    { title: suggestionsList.link, type: SUGGESTIONS_TYPES[2] },
  ];

  return (
    <Stack height="100%" alignItems="center" justifyContent="center" gap="60px" p="40px">
      <Typography variant="h2" fontWeight={700} fontSize="40px" lineHeight="40px">
        “{name}” dataset was selected
      </Typography>

      <Grid
        container
        gap="48px"
        sx={{ flexDirection: "row", justifyContent: "center", flexWrap: "nowrap" }}
        //sx={{
        //  display: "grid",
        //  gridTemplateColumns: "repeat(auto-fill, minmax(352px, 1fr))",
        //}}
      >
        {SUGGESTIONS_LIST.map(({ title, type }, index) => (
          <SuggestionItem
            key={index}
            {...{
              title,
              type,
              onClick: () =>
                gaClickCallback({
                  label: "suggestion item",
                  callback: () => sendMessage(title),
                }),
            }}
          />
        ))}
      </Grid>
    </Stack>
  );
};
