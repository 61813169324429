import { useState } from "react";

import { AnalyseTable } from "../AnalyseTable";
import { CorelationAccordion } from "../Accordions/CorelationAccordion";

import { AccordionWrapper } from "../AnalyseItem/styled";

import { Tab, Tabs } from "@mui/material";
import { GraphBlock } from "../AnalyseItem/components/GraphBlock";

export const AnalyseTabs = ({ tabs, currentAnalyse, callback, selectedShowTab }) => {
  const [value, setValue] = useState(!!tabs.tabs.length ? tabs.tabs[0] : "");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleGetComponent = (data) => {
    switch (currentAnalyse.analysis_type) {
      case "dependent":
      case "compare_groups":
      case "multivariate_single":
        return <AnalyseTable data={data} />;
      case "correlations":
        return <CorelationAccordion data={data} />;
      default:
        break;
    }
  };

  const tableContent = {
    Table: (
      <AccordionWrapper>{handleGetComponent(value ? tabs.content[value] : tabs.content)}</AccordionWrapper>
    ),
    Graph: <GraphBlock {...{ currentAnalyse, independent: false, graphProps: { currentTab: value } }} />,
  };

  return (
    <>
      {tabs?.tabs?.length > 1 && (
        <Tabs
          scrollButtons={false}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            "& button": {
              fontSize: "16px",
              color: "#1E1D23 !important",
            },
          }}
        >
          {tabs.tabs?.map((item, index) => (
            <Tab value={item} label={item} key={index} />
          ))}
        </Tabs>
      )}

      {!!tabs.content && !!tableContent[selectedShowTab] && tableContent[selectedShowTab]}
    </>
  );
};
