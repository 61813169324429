import { SvgIcon } from "@mui/material";
import * as React from "react";
export const DownloadIcon = (props) => (
  <SvgIcon
    style={{ width: "48px", height: "48px", fill: "none" }}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <rect x={4} y={2} width={40} height={40} rx={20} fill="#fff" />
      <path
        d="m24 22.44 3.182 3.181-1.06 1.061-1.372-1.37V29.5h-1.5v-4.19l-1.37 1.372-1.062-1.061zm0-7.94a5.25 5.25 0 0 1 5.216 4.645 4.125 4.125 0 0 1-.715 8.089v-1.511a2.624 2.624 0 1 0-.834-5.182 3.75 3.75 0 1 0-7.333 0 2.625 2.625 0 1 0-.969 5.16l.135.022v1.51a4.125 4.125 0 0 1-.715-8.088A5.25 5.25 0 0 1 24 14.5"
        fill="#525866"
      />
    </g>
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={48}
        height={48}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.113725 0 0 0 0.04 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2895_116" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_2895_116" result="shape" />
      </filter>
    </defs>
  </SvgIcon>
);
