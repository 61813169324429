import { useCallback, useState } from "react";
import { addChat, addMessage, editChat, removeChat } from "../../../../actions/chats";
import { DeleteIcon } from "../../../../Icons/DeleteIcon";
import { PencilIcon } from "../../../../Icons/PencilIcon";
import { ZoomIcon } from "../../../../Icons/ZoomIcon";
import { useDispatch, useSelector } from "react-redux";
import { DatasetTable } from "./components/DatasetTable";
import { deleteDatasetById, fetchDatasetNames } from "../../../../actions/datasets";
import { useMemo } from "react";
import moment from "moment";
import { gaClickCallback } from "helpers/common";

const DATASET_STATE = { id: null, name: "" };

export const useChat = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popUpElement, setPopUpElement] = useState(null);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [datasetConfirm, setDatasetConfirm] = useState(false);
  const [newChatConfirm, setNewChatConfirm] = useState(false);
  const [confirmDeleting, setConfirmDeleting] = useState(false);
  const [openDatasetRename, setOpenDatasetRename] = useState(false);
  const [activeDataset, setActiveDataset] = useState(DATASET_STATE);

  const open = Boolean(anchorEl);

  const chats = useSelector((state) => state.chatsReducer);
  const datasets = useSelector((state) => state.datasetsReducer.namesList);

  const handleOpenMenu = (event, datasetId, datasetName) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setActiveDataset({ id: datasetId, name: datasetName });
  };

  const handleShowDataset = () => {
    setShowFullScreen(true);
    setPopUpElement(<DatasetTable id={activeDataset.id} name={activeDataset.name} />);
  };

  const handleDeleteDataset = () => {
    dispatch(deleteDatasetById(activeDataset.id, () => dispatch(fetchDatasetNames())));
  };

  const handleCloseShowDataset = () => {
    setShowFullScreen(false);
    setPopUpElement(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActiveLink = (linkId) => {
    const { id } = chats.activeChat;

    if (!id) return false;
    return id && id === linkId;
  };

  const handleUpdateChat = (id, name) => {
    dispatch(editChat(id, { name }));
  };

  const handleDeleteChat = () => {
    dispatch(removeChat(chats.activeChat.id));
    setConfirmDeleting(false);
  };

  const handleDatasetClick = () => {
    const { id } = chats.activeChat;
    if (id) return setDatasetConfirm(true);
    return setNewChatConfirm(true);
  };

  const submitDatasetConfirm = () => {
    const { id, name } = chats.activeChat;

    const data = {
      file_configuration: {
        width: 1200,
        height: 1200,
        resolution: 200,
      },
      data: {
        name,
        chat_id: id,
        user_text: `load dataset ${activeDataset.name}`,
        dataset_id: activeDataset.id,
      },
    };

    dispatch(addMessage(data));
    setDatasetConfirm(false);

    setActiveDataset(DATASET_STATE);
  };

  const submitNewChatConfirm = () => {
    setNewChatConfirm(false);
    setActiveDataset(DATASET_STATE);

    const data = {
      file_configuration: {
        width: 1200,
        height: 1200,
        resolution: 200,
      },
      data: {
        name: `load dataset ${activeDataset.name}`,
        chat_id: null,
        user_text: `load dataset ${activeDataset.name}`,
        dataset_id: activeDataset.id,
      },
    };

    dispatch(addChat({ name: `load dataset ${activeDataset.name}`, message: data }));
  };

  const onCloseRenameDataset = () => setOpenDatasetRename(false);

  const MENU_ITEMS = [
    {
      title: "View data",
      onClick: () =>
        gaClickCallback({
          label: "view data",
          callback: () => handleShowDataset(),
        }),
      type: "show",
      icon: <ZoomIcon />,
    },
    {
      title: "Rename dataset",
      onClick: () => setOpenDatasetRename(true),
      type: "show",
      icon: <PencilIcon />,
    },
    {
      title: "Remove",
      onClick: handleDeleteDataset,
      type: "delete",
      icon: <DeleteIcon />,
    },
  ];

  const categorizeChats = useCallback(() => {
    const today = moment().startOf("day");
    const todaysChats = [];
    const prevDaysChats = [];

    if (!chats?.list?.length) return;

    chats?.list?.forEach((chat) => {
      const modifiedAt = moment(chat.modified_at);
      if (modifiedAt.isSame(today, "day")) {
        todaysChats.push(chat);
      } else {
        prevDaysChats.push(chat);
      }
    });

    return { todays: todaysChats, prevDays: prevDaysChats };
  }, [chats]);

  const categorizedChats = useMemo(() => categorizeChats(), [categorizeChats]);

  return {
    MENU_ITEMS,
    anchorEl,
    open,
    handleOpenMenu,
    handleClose,
    handleActiveLink,
    handleUpdateChat,
    handleDeleteChat,
    handleDatasetClick,
    submitDatasetConfirm,
    submitNewChatConfirm,
    newChatConfirm,
    datasetConfirm,
    confirmDeleting,
    activeDataset,
    popUpElement,
    showFullScreen,
    handleCloseShowDataset,
    datasets,
    setConfirmDeleting,
    chats,
    setDatasetConfirm,
    setNewChatConfirm,
    categorizedChats,
    openDatasetRename,
    onCloseRenameDataset,
  };
};
