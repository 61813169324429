import { SvgIcon } from "@mui/material";
import React from "react";

export const ArrowDown = ({ width = "16px", height = "16px" }) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" style={{ width, height, fill: "none" }} viewBox="0 0 16 16">
      <path
        d="m13.28 5.967-4.347 4.346a1.324 1.324 0 0 1-1.867 0L2.72 5.967"
        stroke="#344054"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
