import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import "../index.css";

const DashboardLayoutRoot = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

const DashboardLayoutContent = styled("div")(() => ({
  maxWidth: "1440px",
  width: "100%",
}));

export const GptLayout = () => {
  return (
    <DashboardLayoutRoot>
      <DashboardLayoutContent sx={{ maxWidth: "100%" }}>
        <Outlet />
      </DashboardLayoutContent>
    </DashboardLayoutRoot>
  );
};
