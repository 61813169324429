import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PageTitle } from "../../components/pageTitle";
import { DatasetTabs } from "./datasetTabs";
import { DataTable } from "./dataTable";
import { FrequencyTable } from "./frequencyTable";
import { SummaryTable } from "./summaryTable";
import { ChartList } from "./graphs/chartsList";

import { fetchDataset, fetchSummary } from "../../actions/datasets";

const tabs = [
  {
    name: "Data",
    component: <DataTable />,
  },
  {
    name: "Frequency",
    component: <FrequencyTable />,
  },
  {
    name: "Summary",
    component: <SummaryTable />,
  },
  {
    name: "Graphs",
    component: <ChartList />,
  },
];

export default function Dataset() {
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataset(id, { limit: 25, offset: 0 }, true));
    dispatch(fetchSummary(id));
  }, [dispatch, id]);

  return (
    <>
      <PageTitle title="Dataset" />
      <DatasetTabs tabs={tabs} />
    </>
  );
}
