import { useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Collapse
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { analyses } from './constants';

const AnalyseCard = styled(Box)(
  ({backgroundImage}) => ({
    width: '112px',
    height: '60px',
    padding: '7px',
    borderRadius: '5px',
    position: 'relative',
    color: '#fff',
    background: backgroundImage,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  })
)

const AnalyseSelectedIndicator = styled(Box)(
  ({selected}) => ({
    width: selected ? '10px' : '13px',
    height: selected ? '10px' : '13px',
    borderRadius: '100%',
    border: selected ? '3px solid #2984FF' : '1px solid #fff',
    background: selected ? '#fff' : 'border-box',
    position: 'absolute',
    top: 7,
    right: 7,
    filter: 'drop-shadow(1px 4px 4px rgba(77, 77, 77, 0.11))'
  })
)

const AnalyseDescription = styled(Collapse)(
  ({open}) => ({
    fontSize: '12px',
    color: '#fff',
    position: open ? 'initial' : 'relative',
    "&::after": {
      content: "''",
      position: 'absolute',
      height: '45px',
      display: open ? 'none' : 'block',
      background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(79,154,255,1) 100%);',
      width: '100%',
      bottom: 0,
      left: 0
    }
  })
)

export function SelectAnalyses({
  open,
  handleClose,
  selected,
  handleChangeSelected,
  nextClick
}) {
  const [openText, setOpenText] = useState(false);

  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{sx: {background: 'linear-gradient(90deg, rgba(255,255,255,1) 55%, rgba(79,154,255,1) 55%)'}}}
    >
      <DialogTitle>
        Select Analyses 
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Box sx={{width: '55.5%', overflowY: 'auto', maxHeight: '400px'}}>
            {analyses.map(analyse_category => (
              <Box key={analyse_category.type} sx={{marginBottom: '15px'}}>
                <Typography color={'#3B3946'} fontWeight={400} variant='h5' sx={{marginBottom: '15px'}}>{analyse_category.type}</Typography>
                <Grid container spacing={2}>
                  {analyse_category.analyses.map(analyse => (
                    <Grid key={analyse.name} item>
                      <AnalyseCard backgroundImage={analyse.image} onClick={() => handleChangeSelected(analyse)}>
                        <Typography fontSize={12} fontWeight={600} lineHeight={'18px'} textAlign={'center'}>{analyse.name}</Typography>
                        <AnalyseSelectedIndicator selected={analyse.name === selected.name} />
                      </AnalyseCard>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
          <Box sx={{width: 'calc(44.5% - 34px)', maxHeight: '400px', padding: '0 0 0 34px'}}>
            <Typography variant="h5" color="#fff">{selected.name}</Typography>
            <AnalyseDescription in={openText} collapsedSize={100} open={openText}>
              {selected.description}
            </AnalyseDescription>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-10px'}}>
              <ExpandMoreIcon 
                onClick={() => setOpenText(!openText)} 
                sx={{color: '#fff', cursor: 'pointer', transform: openText ? 'rotate(.5turn)' : 'rotate(0turn)', margin: 'auto'}}
              />
            </div>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color='info' onClick={handleClose} >Cancel</Button>
        <Button variant="contained" color="secondary" onClick={nextClick} >Next</Button>
      </DialogActions>
    </Dialog>
  )
}