import { useState } from "react";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { CreateButton } from "./buttons/create-btn";
import { OpenCloseDrawer } from "./buttons/openCloseDrawer";
import { GenieIcon } from "./genie-icon";

import GenieLogo from "../images/logo-genie.png";

const items = [
  {
    href: "/dashboard",
    icon: "overview",
    label: "Overview",
  },
  {
    href: "/dashboard/projects",
    icon: "project",
    label: "Projects",
  },
  {
    href: "/dashboard/datasets",
    icon: "dataset",
    label: "Dataset",
  },
  {
    href: "/dashboard/analysis",
    icon: "analyses",
    label: "Analysis",
  },
  {
    href: "/dashboard/settings",
    icon: "settings",
    label: "Settings",
  },
  {
    href: "/dashboard/collection",
    icon: "",
    label: "Collection",
  },
  {
    href: "/genie-gpt",
    icon: "Collection",
    label: "statGPT",
  },
];

const openedMixin = (theme) => ({
  width: 200,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "auto",
  width: 55,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 200,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Sidebar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const getIconName = (iconName, isActive) => {
    switch (iconName) {
      case "overview":
        return isActive ? "overview-active" : "overview-inactive";
      case "dataset":
        return isActive ? "dataset-active" : "dataset-inactive";
      case "project":
        return isActive ? "project-active" : "project-inactive";
      case "analyses":
        return isActive ? "analyses-active" : "analyses-inactive";
      case "settings":
        return isActive ? "settings-active" : "settings-inactive";
      default:
        return isActive ? "project-active" : "project-inactive";
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <OpenCloseDrawer open={open} onClick={() => handleChangeOpen()} />
      <Drawer
        open={open}
        sx={{ zIndex: 1000 }}
        variant="permanent"
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: "none",
            p: 1,
            top: 0,
            width: 200,
            overflow: "auto",
          },
        }}
      >
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: open ? "130px !important" : "50px",
            padding: "10px 0px !important",
          }}
        >
          <img
            alt="Logo"
            src={GenieLogo}
            style={
              open
                ? {
                    width: "115px",
                    margin: "20px 0 30px 20px",
                  }
                : { width: "50px", margin: "20px 0 70px 0" }
            }
          />
        </Box>

        <CreateButton open={open} buttonName="New Project" />
        <List sx={{ width: "100%" }}>
          {items.map(({ href, icon, label }) => {
            const active = matchPath({ path: href }, location.pathname);
            return (
              <ListItem
                disablePadding
                component={RouterLink}
                key={href}
                to={href}
                sx={{
                  height: "56px",
                  flexDirection: "row",
                  color: active ? "#2984FF" : "text.secondary",
                  px: 2,
                  py: 1.5,
                  "&:hover": {
                    color: "#2984FF",
                  },
                }}
              >
                <GenieIcon type={getIconName(icon, active)} />
                {open && (
                  <ListItemText
                    primary={label}
                    primaryTypographyProps={{
                      sx: {
                        pb: 0,
                        pt: 0,
                        mt: 0,
                        mb: 0,
                        pl: "10px",
                      },
                      variant: "caption",
                    }}
                  />
                )}
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </div>
  );
};
