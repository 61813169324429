import styled from "@emotion/styled";
import { Stack } from "@mui/material";

export const SuggestionWrapper = styled(Stack)(() => ({
  padding: "43px 20px",
  boxShadow: "0px 6px 40px rgba(52, 64, 84, 0.07)",
  borderRadius: "20px",
  overflow: "hidden",
  height: "100%",
  minWidth: "352px",
  maxWidth: "410px",
  border: "1px #D6BBFB solid",
  gap: "16px",
  boxSizing: "border-box",
  cursor: "pointer",

  "& svg": {
    transform: "scale(2.9)",
  },
}));
