import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const ActiveChatWrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  height: "calc(100svh - 100px)",
  overflow: "hidden",
  padding: "0px 0px 40px",
  gap: "4px",
  backgroundColor: "#F9FAFB",
}));
