import { IconButton, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledInput = styled(InputBase)(() => ({
  background: "#E6EAEE",
  height: "54px",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  textAlign: "center",
  fontWeight: 400,
  borderRadius: "8px",
  backgroundImage: "linear-gradient(white, white), linear-gradient(140deg, #0cecc7 0%, #18b8dc 100%)",
  backgroundOrigin: "border-box",
  backgroundClip: "padding-box, border-box",
  boxSizing: "border-box",
  color: "#24242C",
  padding: "15px 26px",
  boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.04)",
  border: "1px #EAECF0 solid",

  "& .MuiInputBase-input": {
    padding: 0,
    marginTop: "3px",

    "&::placeholder": {
      opacity: 1,
      color: "#98A2B3",
    },
  },

  "& svg": {
    cursor: "pointer",
  },
}));

export const InputIconButton = styled(IconButton)(() => ({
  padding: "14px",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px #7F56D9 solid",

  "&.Mui-disabled": {
    background: "#7f56d996",
    border: "1px #7f56d996 solid",

    "& svg": {
      opacity: "0.5",
    },
  },
}));
