import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { DeleteDialog } from "./deleteDialog";
import { GenieIcon } from "../../components/genie-icon";
import { Pagination } from "../../components/pagination";

import {
  changeFilters,
  selectProject,
  removeProject,
} from "../../actions/projects";
import { openCreateDialog } from "../../actions/datasets";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

export const ProjectsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(0);
  const projects = useSelector((state) => state.projectsReducer.list);
  const filters = useSelector((state) => state.projectsReducer.filters);
  const count = useSelector((state) => state.projectsReducer.count);

  const onClickEdit = (project) => {
    dispatch(selectProject(project));
    dispatch(openCreateDialog(true));
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ height: "calc(100vh - 510px)" }}>
        <Table stickyHeader sx={{ minWidth: 900 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Dataset</TableCell>
              <TableCell>Analyses</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow
                key={project.id}
                onClick={() => navigate(`/dashboard/project/${project.id}`)}
              >
                <TableCell component="th" scope="row">
                  {project.project_name}
                </TableCell>
                <TableCell align="left">
                  {moment(project.created_at).format("MM/DD/yy, h:mm")}
                </TableCell>
                <TableCell align="left">{project.datasets_count}</TableCell>
                <TableCell align="left">{project.analyses_count}</TableCell>
                <TableCell align="left">
                  <Grid
                    container
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Button
                      callback={(e) => {
                        e.stopPropagation();
                        onClickEdit(project);
                      }}
                      variant="contained"
                      color="secondary"
                      sx={{ minWidth: "20px", padding: "4px" }}
                    >
                      <GenieIcon
                        iconWidth="14px"
                        iconHeight="14px"
                        type="plus"
                      />
                    </Button>
                    <GenieIcon
                      callback={(e) => {
                        e.stopPropagation();
                        setOpenDelete(project.id);
                      }}
                      type="trash"
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        filters={filters}
        changeFilters={(filters) => dispatch(changeFilters(filters))}
        count={count}
      />
      <DeleteDialog
        open={openDelete}
        handleClose={() => setOpenDelete(0)}
        handleDelete={(id) => dispatch(removeProject(id))}
        text={`project`}
      />
    </>
  );
};
